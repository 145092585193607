import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, from, of } from 'rxjs';
import { map, switchMap, catchError, filter, concatMap, withLatestFrom, timeout } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import {
  executeOperation,
  loadAppData,
  markOperationExecuted,
  markOperationExecutedWithError,
  syncData,
} from '../actions/offline.action';
import { ConnectionState } from '../reducers/offline.reducer';
import { StorageSyncActions, fetchState } from '../storage-sync.module';
import { LOAD_FAMILY_MEMBER, loadFamilyMember } from '../actions/family-members.action';
import { LOAD_FARM, loadFarm } from '../actions/farm.action';
import { LOAD_PLOTS, loadPlots } from '../actions/plots.action';
import { LOAD_AGRI_PRODUCT, LOAD_SELECTED_AGRI_PRODUCT, loadAgriProduct, loadSelectedAgriProduct } from '../actions/agri-product.action';
import { LOAD_ANIMAL, LOAD_SELECTED_ANIMAL, loadAnimal, loadSelectedAnimal } from '../actions/animals.action';
import { LOAD_PARTNER, loadPartner, loadPartnerType } from '../actions/partner.action';
import { LOAD_PROGRAMS } from '../actions/programas.action';
import { GET_DASHBOARD_DATA, LOAD_TOTAL_ACCOUNTING_CATEGORIES, getDashboardData, loadTotalAccountingCategories } from '../actions/dashboard.action';
import { PAGINATE_CASHFLOW, paginateCashflow } from '../actions/cashflow.action';
import { loadUnit } from '../actions/units-action';

@Injectable()
export class OfflineEffect {


  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<{
      connection: ConnectionState;
    }>
  ) {
    // setInterval(() => {
    //   this.store.dispatch({ type: '[Connection] RETRY_FAILED_OPERATIONS' });
    // }, 60000); // Check every minute
  }
  // Effect to hydrate the state from storage
  hydrate$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(StorageSyncActions.NGRX_EFFECT_INIT),
        switchMap(() =>
          from(fetchState()).pipe(
            map((state) => ({
              type: StorageSyncActions.HYDRATED,
              payload: state,
            })),
            catchError((e) => {
              console.warn(
                `error fetching data from store for hydration: ${e}`
              );
              return of({
                type: StorageSyncActions.HYDRATED,
                payload: {},
              });
            })
          )
        )
      )
  );

  // Effect to execute operations with retry logic and batch processing
  executeOperations$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(syncData),
        switchMap(() =>
          this.store.select('connection').pipe(
            map((connection) => {
              const shouldSync = connection.isOnline &&
                (connection.connectionQuality === 'good' || !connection.connectionQuality);
              return shouldSync
                ? Array.from(Object.values(connection.entities))
                : [];
            }),
            filter((operations) => operations?.length > 0),
            // Sort by priority first, then by ID
            map((data) => data.sort((a, b) => {
              // Priority order: high > normal > low
              const priorityOrder = { high: 0, normal: 1, low: 2 };
              const aPriority = priorityOrder[a.priority || 'normal'];
              const bPriority = priorityOrder[b.priority || 'normal'];

              if (aPriority !== bPriority) {
                return aPriority - bPriority;
              }
              // If same priority, sort by ID (timestamp)
              return a.id < b.id ? -1 : 1;
            })),
            switchMap((operations: any) => {
              return of(executeOperation({ operation: operations[0] }));
            })
          )
        )
      )
  );

  // Effect to send HTTP request with improved error handling
  sendRequest$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(executeOperation),
        switchMap((value: any) => {
          const operation = value.operation;
          const { headers, responseType } = operation || {};
          var body = new FormData();
          for (const key in operation.formDataObject) {
            body.append(key, operation.formDataObject[key]);
          }
          let request$: Observable<any>;

          switch (operation.method.toUpperCase()) {
            case 'POST':
              request$ = this.http.post(operation.url, body);
              break;
            case 'PUT':
              request$ = this.http.put(operation.url, body);
              break;
            case 'DELETE':
              request$ = this.http.delete(operation.url);
              break;
            default:
              // Handle unsupported methods
              return of(markOperationExecutedWithError({
                id: operation?.id ?? null,
                error: operation.error,
                data: {},
              }));
          }

          return request$.pipe(
            // Add timeout to prevent hanging requests
            timeout(30000),
            map((response) => {
              console.log(`Operation ${operation.id} executed successfully`);
              return markOperationExecuted({
                id: operation?.id ?? null,
                error: null,
                data: response,
              });
            }),
            catchError((error) => {
              console.error(`Operation ${operation.id} failed:`, error);
              // Check if error is due to network issues
              const isNetworkError = !navigator.onLine ||
                error.name === 'TimeoutError' ||
                error.status === 0;

              if (isNetworkError) {
                // If network error, don't remove the operation, just stop syncing for now
                return of({ type: '[Connection] SYNC_PAUSED_DUE_TO_NETWORK' });
              }

              return of(
                markOperationExecutedWithError({
                  id: operation?.id ?? null,
                  error: error.error || error,
                  data: {},
                })
              );
            })
          );
        })
      )
  );
  loadAppData$ = createEffect((): Observable<Action> =>
    this.actions$.pipe(
      ofType(loadAppData),
      concatMap(() => {
        const startDate = new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          1
        ).toISOString();
        const endDate = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          0 + 1
        ).toISOString();

        const date = { startDate: startDate, endDate: endDate };
        let dashboardData = {
          startDate: new Date(
            new Date().getFullYear(),
            1,
            1
          ).toISOString(),
          endDate: new Date(
            new Date().getFullYear(),
            11,
            31
          ).toISOString(),
          cropId: 1,
          groupBy: 'm-Y',
        };
        return [
          loadFarm(),
          loadTotalAccountingCategories({ data: date }),
          loadFamilyMember(),
          loadAgriProduct(),
          loadAnimal(),
          loadSelectedAgriProduct(),
          loadSelectedAnimal(),
          loadPlots(),
          loadPartnerType(),
          loadPartner(),
          loadUnit(),
          getDashboardData({ data: dashboardData })
        ]
      }), catchError((error) => {
        console.error(error);
                return of({ type: '[Connection] SYNC_PAUSED_DUE_TO_NETWORK' });
      })
    )
  );
  // // Effect to send HTTP request
  // sendRequest$: Observable<Action> = createEffect(
  //   (): Observable<Action> =>
  //     this.actions$.pipe(
  //       ofType(executeOperation),
  //       switchMap((value: any) => {
  //         const operation = value.operation;
  //         const { headers, responseType } = operation || {};
  //         var body = new FormData();
  //         for (const key in operation.formDataObject) {
  //           body.append(key, operation.formDataObject[key]);
  //         }
  //         let request$: Observable<any>;

  //         switch (operation.method.toUpperCase()) {
  //           case 'POST':
  //             request$ = this.http.post(operation.url, body);
  //             break;
  //           case 'PUT':
  //             request$ = this.http.put(operation.url, body);
  //             break;
  //           case 'DELETE':
  //             request$ = this.http.delete(operation.url);
  //             break;
  //         }

  //         return request$.pipe(
  //           map((response) => {
  //             return markOperationExecuted({
  //               id: operation?.id ?? null,
  //               error: null,
  //               data: response,
  //             });
  //           }),
  //           catchError((error) => {
  //             return of(
  //               markOperationExecutedWithError({
  //                 id: operation?.id ?? null,
  //                 error: error.error,
  //                 data: {},
  //               })
  //             );
  //           })
  //         );
  //       })
  //     )
  // );
  // Add a new effect to retry failed operations periodically
  retryFailedOperations$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType('[Connection] RETRY_FAILED_OPERATIONS'),
        withLatestFrom(this.store.select('connection')),
        filter(([_, connection]) => connection.isOnline && connection.connectionQuality === 'good'),
        map(([_, connection]) => {
          if (connection.failedSyncOperations.length > 0) {
            // Implement your retry logic here
            // This could involve re-adding failed operations to the queue
            console.log('Retrying failed operations:', connection.failedSyncOperations);
            return syncData();
          }
          return { type: '[Connection] NO_FAILED_OPERATIONS_TO_RETRY' };
        })
      )
  );


}
