import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { CustomTranslateLoader } from '../utils/Service/CustomTranslateLoader';
import { GetResult, Storage } from '@capacitor/storage';
import { from, lastValueFrom, switchMap } from 'rxjs';

// Import the dev object correctly
import { dev } from '../config/offlines_keys.json';

// Factory function for APP_INITIALIZER
export function initLangage(translateService: TranslateService) {
  const availableLanguages = ['en', 'es', 'fr'];
  translateService.addLangs(availableLanguages);

  return () =>
    new Promise<string>((resolve) => {
      from(Storage.get({ key: dev.LANGAGE_KEY }))
        .pipe(
          switchMap(async (value: GetResult) => {
            let currentLang = 'en';

            if (value?.value) {
              currentLang = value.value;
            } else {
              await Storage.set({ key: dev.LANGAGE_KEY, value: currentLang });
              localStorage.setItem('lang', currentLang);
            }

            translateService.setDefaultLang('en');
            return currentLang;
          }),
          switchMap(lang => {
            return translateService.use(lang);
          })
        )
        .subscribe({
          next: () => resolve(translateService.currentLang),
          error: () => {
            // Fallback to default language on error
            translateService.use('en');
            resolve('en');
          }
        });
    });
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false,
      defaultLanguage: 'en'
    })
  ],
  providers: [
  ],
  exports: [
    TranslateModule
  ]
})
export class TranslationModule { }