import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { IonGrid, IonNav } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import { StatisticsAPI } from 'src/app/apis/statistics.service';
import { DateFormatPipe } from 'src/app/utils/pipes-utils/date_formatter';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { WorkdayAnalyseFitlerComponent } from '../filters/workday-analyse-fitler/workday-analyse-fitler.component';
import { Store } from '@ngrx/store';
import { loadWorkForceStats } from 'src/app/utils/states-management/actions/dashboard.action';
import {
  getTotalsAccountingCategoriesResult,
  getWorkForceStats,
} from 'src/app/utils/states-management/selector/dashboard.selector';
import { lastValueFrom, take } from 'rxjs';
import { AccountingEntryCategorie } from 'src/app/models/accounting/accounting_entries_categories';
import { cloneDeep } from 'lodash';
import {
  GRAPH_COLORS,
  PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY,
} from 'src/app/utils/consts';
import { CurrencyPipe } from '@angular/common';

export interface FilterForm {
  coffee_checked: boolean;
  animals_checked: boolean;
  other_agricol_products_checked: boolean;
  administration_checked: boolean;
  temporal_labor_expenses_checked: boolean;
  permanent_labor_expenses_checked: boolean;
  familiar_labor_expenses_checked: boolean;
  temporal_labor_administrative_checked: boolean;
  permanent_labor_administrative_checked: boolean;
  permanent_labor_monthly_checked: boolean;
  familiar_labor_administrative_checked: boolean;
  familiar_labor_monthly_checked: boolean;
  per_activity_checked: boolean;
  per_product_checked: boolean;
  per_animal_checked: boolean;
  per_mano_obra_administrativa: boolean;
  per_monthly_salary: boolean;
  familial_filter: boolean;
  permanent_filter: boolean;
  temporal_filter: boolean;
  no_paid_stats: boolean;
  paid_per_day_stats: boolean;
  paid_in_spicie_stats: boolean;
  paid_for_harvested_quantity: boolean;
  per_salario_mensuales: boolean;
}
export interface StatRequestParams {
  start_date: string;
  end_date: string;
  accountingEntries: Array<number>;
  cashflowCategories: Array<number>;
  cashflowExpenses: Array<number>;
  cashflowActivities: Array<number>;
  per_activity: boolean;
  per_product_checked: boolean;
  per_animal_checked: boolean;
  per_cashflow_categories: boolean;
  per_monthly_salary: boolean;
  sort_by: string;
  monthly_yearly: string;
}
interface ChartStaticLabels {
  familial_workforce: string;
  permanent_workforce: string;
  temporal_workforce: string;
  workforce_stats: string;
  no_paid_stats: string;
  paid_per_day_stats: string;
  paid_monthly: string;
  paid_in_spicie_stats: string;
  paid_for_harvested_quantity: string;
}
@Component({
  selector: 'app-analysis-workforce',
  templateUrl: './analysis-workforce.component.html',
  styleUrls: ['./analysis-workforce.component.scss'],
})
export class AnalysisWorkforceComponent implements OnInit, AfterViewInit {
  @Input() nav: IonNav;
  @ViewChild('lineChart') lineChartCanvas: ElementRef;
  lineChart;
  accountingsEntries: Array<AccountingEntryCategorie> = [];
  params: StatRequestParams = {
    start_date: new Date(new Date().getFullYear(), 0, 1).toISOString(),
    end_date: new Date().toISOString(),
    accountingEntries: [1, 2, 3, 4, 10],
    cashflowCategories: [149, 150, 285, 286, 287],
    cashflowExpenses: [64, 65, 66],
    cashflowActivities: [],
    per_activity: false,
    per_product_checked: false,
    per_animal_checked: false,
    per_cashflow_categories: false,
    per_monthly_salary: false,
    sort_by: null,
    monthly_yearly: null,
  };
  formFilter: FilterForm = {
    coffee_checked: true,
    animals_checked: true,
    other_agricol_products_checked: true,
    administration_checked: true,
    temporal_labor_expenses_checked: true,
    permanent_labor_expenses_checked: true,
    familiar_labor_expenses_checked: true,
    temporal_labor_administrative_checked: true,
    permanent_labor_administrative_checked: true,
    familiar_labor_administrative_checked: true,
    permanent_labor_monthly_checked: true,
    familiar_labor_monthly_checked: true,
    per_activity_checked: false,
    per_product_checked: false,
    per_animal_checked: false,
    per_mano_obra_administrativa: false,
    per_monthly_salary: true,
    familial_filter: true,
    permanent_filter: true,
    temporal_filter: true,
    no_paid_stats: true,
    paid_per_day_stats: true,
    paid_in_spicie_stats: true,
    paid_for_harvested_quantity: true,
    per_salario_mensuales: true,
  };

  chartsLabels: ChartStaticLabels = {
    familial_workforce: '',
    permanent_workforce: '',
    temporal_workforce: '',
    workforce_stats: '',
    no_paid_stats: '',
    paid_per_day_stats: '',
    paid_monthly: '',
    paid_in_spicie_stats: '',
    paid_for_harvested_quantity: '',
  };
  selected_date;
  total_work_days = 0;
  total_workdays_familial = 0;
  total_workday_permanent = 0;
  total_workday_temporal = 0;
  total_workday = 0;
  isFiltred = false;
  accountingDetails = [];
  @ViewChild('pieCanvas') pieCanvas: ElementRef;

  filterAccordion = {
    general: true,
    selection: true,
    category: true,
    accounting: true,
  };
  owner;
  profile_info;
  constructor(
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private datePipe: DateFormatPipe,
    private store: Store<{
      accountingEntries: Array<AccountingEntryCategorie>;
      owner;
    }>
  ) {}
  HandelDateChange(value) {
    console.log('HandelDateChange');

    this.params.start_date = value.start_date;
    this.params.end_date = value.end_date;
    this.prepareParamsAndFetchData(this.params);
  }
  handelFilterClicked(ev) {
    this.filterAccordion = {
      general: ev,
      selection: ev,
      category: ev,
      accounting: ev,
    };
  }
  accountingIds;
  async ngOnInit() {
    this.owner = cloneDeep(
      await lastValueFrom(this.store.select('owner').pipe(take(1)))
    );

    if (this.owner?.profile_info)
      this.profile_info = JSON.parse(this.owner?.profile_info);

    let allAccountings = await lastValueFrom(
      this.store.select(getTotalsAccountingCategoriesResult).pipe(take(1))
    );
    var totalsAccountingCategories = [];
    cloneDeep(allAccountings.data)
      .filter(
        (element) =>
          element.is_farm_expenses &&
          element.id != PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY
      )
      .forEach((element) => {
        if (element?.morphable_type != null && element?.id != null) {
          let cropExist = this.profile_info.cropConfig.find(
            (item) =>
              item.id == element.morphable_id &&
              element.morphable_type.toLowerCase().includes(item.entity) &&
              element?.morphable
          );
          if (cropExist) {
            totalsAccountingCategories.push(element);
          }
        } else {
          totalsAccountingCategories.push(element);
        }
      });

    this.accountingsEntries = totalsAccountingCategories;

    this.accountingsEntries.forEach((val) => (val['isChecked'] = true));
    this.prepareParamsAndFetchData(this.params);

    this.tranlate();
    this.initDate(this.params.start_date, this.params.end_date);

    this.translateService.onLangChange.subscribe(async (value) => {
      this.initDate(this.params.start_date, this.params.end_date);
      await this.tranlate();
    });
  }

  onFilterClick(filter) {
    this.filterAccordion[filter] = !this.filterAccordion[filter];
  }

  Check(item) {
    // this.formFilter[item] = !this.formFilter[item];
    item.isChecked = !item.isChecked;

    // Object.preventExtensions(this.params);
    // let acc = this.params.accountingEntries;
    // this.params.accountingEntries.includes(item)
    //   ? (acc = this.params.accountingEntries.filter(
    //       (val) => val != item
    //     ))
    //   : acc.push(item);

    // this.params.accountingEntries = acc;
    // Object.preventExtensions(this.params);
    this.params.accountingEntries = this.accountingsEntries
      .filter((val) => val.isChecked)
      .map((obj) => obj.id);
    console.log('Check');

    this.prepareParamsAndFetchData(this.params);
  }

  initDate(startDate, endDate) {
    this.selected_date =
      this.datePipe.transform(startDate, this.translateService.currentLang) +
      ' - ' +
      this.datePipe.transform(endDate, this.translateService.currentLang);
  }

  async tranlate() {
    this.chartsLabels.familial_workforce = this.translateService.instant(
      'label.familial_workforce'
    );

    this.chartsLabels.permanent_workforce = this.translateService.instant(
      'label.permanent_workforce'
    );

    this.chartsLabels.temporal_workforce = this.translateService.instant(
      'label.temporal_workforce'
    );

    this.chartsLabels.workforce_stats = this.translateService.instant(
      'label.workforce_stats'
    );

    this.chartsLabels.no_paid_stats =
      this.translateService.instant('paid_type_1');

    this.chartsLabels.paid_per_day_stats =
      this.translateService.instant('paid_type_5');

    this.chartsLabels.paid_in_spicie_stats =
      this.translateService.instant('paid_type_2');

    this.chartsLabels.paid_for_harvested_quantity =
      this.translateService.instant('paid_type_4');

    this.chartsLabels.paid_monthly =
      this.translateService.instant('paid_type_6');
  }

  async ngAfterViewInit(): Promise<void> {
    // if (this.pieChart) {
    //   this.pieChart?.destroy();
    //   this.pieChart = null; // Ensure it is reset
    // }
    // this.pieChart = new Chart(this.pieCanvas.nativeElement, null);
    // this.prepareParamsAndFetchData(this.params);
    this.store
    .select(getWorkForceStats)
    // .pipe(take(1))
    .subscribe(async (value: any) => {
      console.log(value?.state_result);

      this.drawChart(value?.state_result);
      await this.pieChartBrowser(value);
      if (this.pieChart?.canvas?.parentNode) {
        this.pieChart.canvas.parentNode.style.height = '40vh';
        this.pieChart.canvas.parentNode.style.width = '40vh';
      }
      if (this.pieChart?.canvas?.parentNode) {
        this.pieChart.canvas.parentNode.style.display = 'flex';
        this.pieChart.canvas.parentNode.style['justify-content'] = 'center';
      }
      this.total_workday = value.total_work_days.total;

      this.total_workdays_familial = parseFloat(
        value.total_work_days.total_family_work_days
      );
      this.total_workday_permanent = parseFloat(
        value.total_work_days.total_permanent_work_days
      );
      this.total_workday_temporal = parseFloat(
        value.total_work_days.total_temporal_work_days
      );
    });
  }

  checkFilter(filter) {
    this.formFilter[filter] = !this.formFilter[filter];
    console.log('checkFilter');

    this.prepareParamsAndFetchData(this.params);
  }

  async drawChart(value) {
    if (this.lineChart) this.lineChart.destroy();
    const labels = new Array<string>();

    let noPaidStatFamilial = new Array<number>();
    let paidPerDayStatFamilial = new Array<number>();
    let paidInKindFamilial = new Array<number>();
    let paidForHarvestedQuantityFamilial = new Array<number>();
    let mensualeFamilial = new Array<number>();

    let paidPerDayStatPermanent = new Array<number>();
    let paidInKindPermanent = new Array<number>();
    let paidForHarvestedQuantityPermanent = new Array<number>();
    let mensualePermanent = new Array<number>();

    let paidInKindTemporal = new Array<number>();
    let paidForHarvestedQuantityTemporal = new Array<number>();
    let perDayTemporal = new Array<number>();
    let noPaidTemporal = new Array<number>();
    this.total_work_days = 0;
    var keys = Object.keys(value);
    keys.forEach((signleMonthEntries) => {
      labels.push(this.translateService.instant(signleMonthEntries));
      // permanentDataSet.push(value[signleMonthEntries].familial.permanent);
      // temporalDataSet.push(value[signleMonthEntries].familial.temporal);
      // noPaidStatFamilial.push(value[signleMonthEntries].familial.no_pago);
      if (this.formFilter.familial_filter) {
        if (
          this.formFilter.no_paid_stats &&
          !isNaN(value[signleMonthEntries].familial.noPago)
        ) {
          this.total_work_days += value[signleMonthEntries].familial.noPago;
        }
        if (
          this.formFilter.paid_for_harvested_quantity &&
          !isNaN(value[signleMonthEntries].familial.pagoQuantity)
        ) {
          this.total_work_days +=
            value[signleMonthEntries].familial.pagoQuantity;
        }
        if (
          this.formFilter.paid_per_day_stats &&
          !isNaN(value[signleMonthEntries].familial.paymentDay)
        ) {
          this.total_work_days += value[signleMonthEntries].familial.paymentDay;
        }
        if (
          this.formFilter.per_monthly_salary &&
          !isNaN(value[signleMonthEntries].familial.mensuel)
        ) {
          this.total_work_days += value[signleMonthEntries].familial.mensuel;
        }
        if (
          this.formFilter.paid_in_spicie_stats &&
          !isNaN(value[signleMonthEntries].familial.pagoSpecie)
        ) {
          this.total_work_days += value[signleMonthEntries].familial.pagoSpecie;
        }
      }

      if (this.formFilter.permanent_filter) {
        if (
          this.formFilter.paid_for_harvested_quantity &&
          !isNaN(value[signleMonthEntries].permanent.pagoQuantity)
        ) {
          this.total_work_days +=
            value[signleMonthEntries].permanent.pagoQuantity;
        }
        if (
          this.formFilter.paid_per_day_stats &&
          !isNaN(value[signleMonthEntries].permanent.paymentDay)
        ) {
          this.total_work_days +=
            value[signleMonthEntries].permanent.paymentDay;
        }
        if (
          this.formFilter.per_monthly_salary &&
          !isNaN(value[signleMonthEntries].permanent.mensuel)
        ) {
          this.total_work_days += value[signleMonthEntries].permanent.mensuel;
        }
        if (
          this.formFilter.paid_in_spicie_stats &&
          !isNaN(value[signleMonthEntries].permanent.pagoSpecie)
        ) {
          this.total_work_days +=
            value[signleMonthEntries].permanent.pagoSpecie;
        }
      }

      if (this.formFilter.temporal_filter) {
        if (
          this.formFilter.no_paid_stats &&
          !isNaN(value[signleMonthEntries].temporal.noPago)
        ) {
          this.total_work_days += value[signleMonthEntries].temporal.noPago;
        }
        if (
          this.formFilter.paid_for_harvested_quantity &&
          !isNaN(value[signleMonthEntries].temporal.pagoQuantity)
        ) {
          this.total_work_days +=
            value[signleMonthEntries].temporal.pagoQuantity;
        }
        if (
          this.formFilter.paid_per_day_stats &&
          !isNaN(value[signleMonthEntries].temporal.paymentDay)
        ) {
          this.total_work_days += value[signleMonthEntries].temporal.paymentDay;
        }
        if (
          this.formFilter.per_salario_mensuales &&
          !isNaN(value[signleMonthEntries].temporal.mensuel)
        ) {
          this.total_work_days += value[signleMonthEntries].temporal.mensuel;
        }
        if (
          this.formFilter.paid_in_spicie_stats &&
          !isNaN(value[signleMonthEntries].temporal.pagoSpecie)
        ) {
          this.total_work_days += value[signleMonthEntries].temporal.pagoSpecie;
        }
      }
      paidPerDayStatFamilial.push(
        value[signleMonthEntries].familial.paymentDay
      );
      paidInKindFamilial.push(value[signleMonthEntries].familial.pagoSpecie);
      paidForHarvestedQuantityFamilial.push(
        value[signleMonthEntries].familial.pagoQuantity
      );
      noPaidStatFamilial.push(value[signleMonthEntries].familial.noPago);
      mensualeFamilial.push(value[signleMonthEntries].familial.mensuel);

      paidPerDayStatPermanent.push(
        value[signleMonthEntries].permanent.paymentDay
      );
      paidInKindPermanent.push(value[signleMonthEntries].permanent.pagoSpecie);
      paidForHarvestedQuantityPermanent.push(
        value[signleMonthEntries].permanent.pagoQuantity
      );
      mensualePermanent.push(value[signleMonthEntries].permanent.mensuel);

      paidInKindTemporal.push(value[signleMonthEntries].temporal.pagoSpecie);
      paidForHarvestedQuantityTemporal.push(
        value[signleMonthEntries].temporal.pagoQuantity
      );
      perDayTemporal.push(value[signleMonthEntries].temporal.paymentDay);
      noPaidTemporal.push(value[signleMonthEntries].temporal.noPago);
    });

    this.translateService.onLangChange;
    const data = {
      labels: labels,
      datasets: [
        {
          label:
            this.chartsLabels.no_paid_stats +
            ' ' +
            this.translateService.instant('titles.family'),
          data: noPaidStatFamilial,
          backgroundColor: ['#16ADDA'],
          borderColor: ['#33b968'],
          borderWidth: 3,
          stack: '1',
          hidden: !(
            this.formFilter.no_paid_stats && this.formFilter.familial_filter
          ),
        },

        {
          label:
            this.chartsLabels.paid_per_day_stats +
            ' ' +
            this.translateService.instant('titles.family'),
          data: paidPerDayStatFamilial,
          backgroundColor: ['#DA8416'],
          borderColor: ['#33b968'],
          borderWidth: 3,
          stack: '1',
          hidden: !(
            this.formFilter.paid_per_day_stats &&
            this.formFilter.familial_filter
          ),
        },

        {
          label:
            this.chartsLabels.paid_in_spicie_stats +
            ' ' +
            this.translateService.instant('titles.family'),
          data: paidInKindFamilial,
          backgroundColor: ['#6F02FF'],
          borderColor: ['#33b968'],
          borderWidth: 3,
          stack: '1',
          hidden: !(
            this.formFilter.paid_in_spicie_stats &&
            this.formFilter.familial_filter
          ),
        },
        {
          label:
            this.chartsLabels.paid_for_harvested_quantity +
            ' ' +
            this.translateService.instant('titles.family'),
          data: paidForHarvestedQuantityFamilial,
          backgroundColor: ['#146551'],
          borderColor: ['#33b968'],
          borderWidth: 3,
          stack: '1',
          hidden: !(
            this.formFilter.paid_for_harvested_quantity &&
            this.formFilter.familial_filter
          ),
        },
        {
          label:
            this.chartsLabels.paid_monthly +
            ' ' +
            this.translateService.instant('titles.family'),
          data: mensualeFamilial,
          backgroundColor: ['#F4E2DE'],
          borderColor: ['#33b968'],
          borderWidth: 3,
          stack: '1',
          hidden: !(
            this.formFilter.per_monthly_salary &&
            this.formFilter.familial_filter
          ),
        },
        //---- permanant

        {
          label:
            this.chartsLabels.paid_per_day_stats +
            ' ' +
            this.translateService.instant('titles.permanent'),
          data: paidPerDayStatPermanent,
          backgroundColor: ['#DA8416'],
          borderColor: ['#3880ff'],
          borderWidth: 3,
          stack: '2',
          hidden: !(
            this.formFilter.paid_per_day_stats &&
            this.formFilter.permanent_filter
          ),
        },
        {
          label:
            this.chartsLabels.paid_in_spicie_stats +
            ' ' +
            this.translateService.instant('titles.permanent'),
          data: paidInKindPermanent,
          backgroundColor: ['#6F02FF'],
          borderColor: ['#3880ff'],
          borderWidth: 3,
          stack: '2',
          hidden: !(
            this.formFilter.paid_in_spicie_stats &&
            this.formFilter.permanent_filter
          ),
        },
        {
          label:
            this.chartsLabels.paid_for_harvested_quantity +
            ' ' +
            this.translateService.instant('titles.permanent'),
          data: paidForHarvestedQuantityPermanent,
          backgroundColor: ['#146551'],
          borderColor: ['#3880ff'],
          borderWidth: 3,
          stack: '2',
          hidden: !(
            this.formFilter.paid_for_harvested_quantity &&
            this.formFilter.permanent_filter
          ),
        },
        {
          label:
            this.chartsLabels.paid_monthly +
            ' ' +
            this.translateService.instant('titles.permanent'),
          data: mensualePermanent,
          backgroundColor: ['#F4E2DE'],
          borderColor: ['#3880ff'],
          borderWidth: 3,
          stack: '2',
          hidden: !(
            this.formFilter.per_monthly_salary &&
            this.formFilter.permanent_filter
          ),
        },
        //---- temporal
        {
          label:
            this.chartsLabels.no_paid_stats +
            ' ' +
            this.translateService.instant('titles.temporal'),
          data: noPaidTemporal,
          backgroundColor: ['#16ADDA'],
          borderColor: ['#33b968'],
          borderWidth: 3,
          stack: '3',
          hidden: !(
            this.formFilter.no_paid_stats && this.formFilter.temporal_filter
          ),
        },
        {
          label:
            this.chartsLabels.paid_in_spicie_stats +
            ' ' +
            this.translateService.instant('titles.temporal'),
          data: paidInKindTemporal,
          backgroundColor: ['#6F02FF'],
          borderColor: ['#bb6bd9'],
          borderWidth: 3,
          stack: '3',
          hidden: !(
            this.formFilter.paid_in_spicie_stats &&
            this.formFilter.temporal_filter
          ),
        },
        {
          label:
            this.chartsLabels.paid_for_harvested_quantity +
            ' ' +
            this.translateService.instant('titles.temporal'),
          data: paidForHarvestedQuantityTemporal,
          backgroundColor: ['#146551'],
          borderColor: ['#bb6bd9'],
          borderWidth: 3,
          stack: '3',
          hidden: !(
            this.formFilter.paid_for_harvested_quantity &&
            this.formFilter.temporal_filter
          ),
        },
        {
          label:
            this.chartsLabels.paid_per_day_stats +
            ' ' +
            this.translateService.instant('titles.temporal'),
          data: perDayTemporal,
          backgroundColor: ['#DA8416'],
          borderColor: ['#bb6bd9'],
          borderWidth: 3,
          stack: '3',
          hidden: !(
            this.formFilter.paid_per_day_stats &&
            this.formFilter.temporal_filter
          ),
        },
      ],
    };

    if (this.lineChart?.id) {
      this.lineChart?.destroy();
      this.lineChart = null; // Ensure it is reset
    }
    this.lineChart = new Chart(this.lineChartCanvas.nativeElement, {
      type: 'bar',
      data: data,
      options: {
        plugins: {
          title: {
            display: false,
            text: this.translateService?.instant(
              this.chartsLabels?.workforce_stats
            ),
          },
          legend: {
            display: false,
          },
        },
        responsive: true,
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            title: {
              color: '#bdbdbd',
              text: this.translateService.instant('label.time'),
              display: true,
              align: 'end',
              font: {
                size: 14,
                family: "'Poppins', ui-sans-serif, system-ui, '-apple-system'",
              },
            },
            stacked: true,
          },
          y: {
            position: 'right',

            title: {
              color: '#bdbdbd',
              text: this.translateService.instant('label.work_days_title'),
              display: true,
              align: 'end',
              font: {
                size: 14,
                family: "'Poppins', ui-sans-serif, system-ui, '-apple-system'",
              },
            },

            stacked: true,
            beginAtZero: true,
          },
        },
      },
    });
  }
  pluck = (arr, key) => arr?.map((i) => i[key]);

  prepareParamsAndFetchData(params) {
    console.log('call from prepareParamsAndFetchData');
    let data = {
      start_date: params.start_date,
      end_date: params.end_date,
      accountingEntries: this.pluck(
        this.accountingsEntries.filter((val) => val?.isChecked),
        'id'
      ),
      cashflowCategories: params.cashflowCategories,
      cashflowExpenses: params.cashflowExpenses,
      familial_filter: this.formFilter.familial_filter ? 1 : 0,
      permanent_filter: this.formFilter.permanent_filter ? 1 : 0,
      temporal_filter: this.formFilter.temporal_filter ? 1 : 0,
      paid_per_day_stats: this.formFilter.paid_per_day_stats ? 1 : 0,
      paid_for_harvested_quantity: this.formFilter.paid_for_harvested_quantity
        ? 1
        : 0,
      per_monthly_salary: this.formFilter.per_monthly_salary ? 1 : 0,
      paid_in_spicie_stats: this.formFilter.paid_in_spicie_stats ? 1 : 0,
      no_paid_stats: this.formFilter.no_paid_stats ? 1 : 0,
    };
    this.store.dispatch(loadWorkForceStats({ data: data }));


  }

  formatResponse(valueResponse: any) {
    const formatedValue = new Array();
    for (const [key, value] of Object.entries(valueResponse)) {
      formatedValue.push({
        id: key,
        amount: value['amount'],
        percent: parseFloat(value['percent_value']).toFixed(2),
        langKey: value['langKey'],
        totalValues: value['totalValues'],
      });
    }
    return formatedValue;
  }
  pieChart;

  async pieChartBrowser(datas): Promise<void> {
    let colors = [];
    let data = [];
    let labels = [];
    let sum = 0;

    let dataToPie = null;
    dataToPie = this.formatResponse(datas.details);
    sum = datas['total_work_days']['total'];
    this.accountingDetails = dataToPie;

    if (this.pieChart) this.pieChart.destroy();
    dataToPie.forEach(async (element, index) => {
      labels.push(
        this.translateService.instant(element?.langKey) +
          ' :  ' +
          element.percent +
          '% ' +
          ' ; ' +
          element.amount +
          ' ' +
          this.translateService.instant('label.day')
      );
      // this.currency

      data.push(parseFloat(element.amount));
      colors.push(this.getRandomColor(index));
    });
    labels.reverse();
    data.reverse();
    if (this.pieChart) {
      this.pieChart?.destroy();
      this.pieChart = null; // Ensure it is reset
    }
    this.pieChart = new Chart(this.pieCanvas.nativeElement, {
      type: 'pie',
      data: {
        labels: labels,
        datasets: [
          {
            backgroundColor: colors,
            data: data,
            hoverBackgroundColor: colors,
            hoverBorderWidth: 4,
            hoverBorderColor: ['black'],
          },
        ],
      },
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                if (tooltipItem.dataIndex % 2) return [tooltipItem.label];
                else return tooltipItem.label;
              },
            },
          },
          legend: {
            display: false,
            position: 'bottom',
          },
        },

        aspectRatio: 1,
      },
    });
  }
  handelTab() {
    if (this.pieChart?.canvas?.parentNode) {
      this.pieChart.canvas.parentNode.style.height = '40vh';
      this.pieChart.canvas.parentNode.style.width = '40vh';
    }
    if (this.pieChart?.canvas?.parentNode.parentNode) {
      this.pieChart.canvas.parentNode.parentNode.style.display = 'flex';
      this.pieChart.canvas.parentNode.parentNode.style['justify-content'] =
        'center';
    }
  }
  async filterModal() {
    let initFilter = this.params;
    const modal = await this.toastUtils.modalBuilder(
      WorkdayAnalyseFitlerComponent,
      {
        params: initFilter,
      }
    );
    modal.present();
    const { data } = await modal.onWillDismiss();
    console.log('filterModal');
    if (data?.filterParams) {
      this.params = data.filterParams;

      this.selected_date =
        new Date(this.params.start_date).toLocaleDateString('en-US') +
        ' - ' +
        new Date(this.params.end_date).toLocaleDateString('en-US');

      this.prepareParamsAndFetchData(this.params);
      this.isFiltred = true;
    } else {
      this.params.start_date = new Date(
        new Date().getFullYear(),
        0,
        1
      ).toISOString();
      this.params.end_date = new Date().toISOString();
      this.prepareParamsAndFetchData(this.params);

      this.isFiltred = false;

      this.selected_date =
        new Date(new Date(new Date().getFullYear(), 0, 1)).toLocaleDateString(
          'en-US'
        ) +
        ' - ' +
        new Date().toLocaleDateString('en-US');
    }
  }
  getRandomColor(i) {
    const color = GRAPH_COLORS[i];
    return color;
  }
  showCategory(id) {
    return this.params.accountingEntries.find((val) => val == id);
  }

  clearFilter() {
    this.formFilter = {
      coffee_checked: true,
      animals_checked: true,
      other_agricol_products_checked: true,
      administration_checked: true,
      temporal_labor_expenses_checked: true,
      permanent_labor_expenses_checked: true,
      familiar_labor_expenses_checked: true,
      temporal_labor_administrative_checked: true,
      permanent_labor_administrative_checked: true,
      familiar_labor_administrative_checked: true,
      permanent_labor_monthly_checked: true,
      familiar_labor_monthly_checked: true,
      per_activity_checked: false,
      per_product_checked: false,
      per_animal_checked: false,
      per_mano_obra_administrativa: false,
      per_monthly_salary: true,
      familial_filter: true,
      permanent_filter: true,
      temporal_filter: true,
      no_paid_stats: true,
      paid_per_day_stats: true,
      paid_in_spicie_stats: true,
      paid_for_harvested_quantity: true,
      per_salario_mensuales: true,
    };
    this.params = {
      start_date: new Date(new Date().getFullYear(), 0, 1).toISOString(),
      end_date: new Date().toISOString(),
      accountingEntries: [1, 2, 3, 4, 10],
      cashflowCategories: [149, 150, 285, 286, 287],
      cashflowExpenses: [64, 65, 66],
      cashflowActivities: [],
      per_activity: false,
      per_product_checked: false,
      per_animal_checked: false,
      per_cashflow_categories: false,
      per_monthly_salary: false,
      sort_by: null,
      monthly_yearly: null,
    };
    this.isFiltred = false;

    this.prepareParamsAndFetchData(this.params);
  }
}
