import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Activity } from 'src/app/models/Activity';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import {
  mapLangeTitleObjects,
  orderAlphabaticallyUnit,
} from 'src/app/utils/array-filtrer';
import {
  CAFE_ACCOUNTING_ENTRY_CATEGORY,
  COCOA_ACCOUNTING_ENTRY_CATEGORY,
  HARVEST_ACTIVITY_IDS,
  MANO_OBRA_EXPENSES,
} from 'src/app/utils/consts';
import { HARVEST_ACTIVITY } from 'src/app/utils/consts';
import {
  ConvertValueToMetric,
  GetRealGoodTransactionValues,
} from 'src/app/utils/unit-convert';
import { Store } from '@ngrx/store';
import { Unit } from 'src/app/models/Unit';
import {
  selectAllUnits,
  selectUnitsByType,
} from 'src/app/utils/states-management/reducers/units.reducer';
import { lastValueFrom, take } from 'rxjs';
import { cloneDeep } from 'lodash';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
interface FormConfig {
  is_harvest_activity: boolean;
  qunatity_harvest: number;
}
@Component({
  selector: 'app-activities-selector',
  templateUrl: './activities-selector.component.html',
  styleUrls: ['./activities-selector.component.scss'],
})
export class ActivitiesSelectorComponent implements OnInit, OnChanges {
  @Input() allActivities;
  @Input() model_required_object;
  @Input() cashflow: CashFlow;
  @Output() activityChange: EventEmitter<CashFlow> = new EventEmitter();
  activitiesFirstTiers: Activity[];
  activitiesSecondTiers: Activity[];
  activitiesThirdTiers: Activity[];
  selectedActivityFirstTiers: Activity;
  selecttedActivityThirdTiers: Activity;
  selecttedActivitySecondTiers: Activity;
  MANO_OBRA_EXPENSES = MANO_OBRA_EXPENSES;
  HARVEST_ACTIVITY = HARVEST_ACTIVITY;
  activityDisabled = false;
  unitSystem: Array<Unit> = [];
  formConfig: FormConfig = {
    is_harvest_activity: false,
    qunatity_harvest: 0,
  };
  countryUnit;
  country;
  constructor(
    public translate: TranslateService,
    public translateService: TranslateService,
    private store: Store,
    public getRealGoodTransactionValues: GetRealGoodTransactionValues,
    public convertValueToMetric: ConvertValueToMetric
  ) {}
  async ngOnInit() {
    this.country = await lastValueFrom(
      this.store.select(getCountry).pipe(take(1))
    );
    this.store
      .select(
        selectUnitsByType(this.model_required_object, this.translateService)
      )
      .pipe(take(1))
      .subscribe(async (value) => {
        if (Array.isArray(value) && value.length > 0) {
          this.unitSystem = cloneDeep(value);
          this.unitSystem.forEach((element) => {
            element[
              this.translateService.currentLang == 'es'
                ? 'title'
                : 'title_' + this.translateService.currentLang
            ] = this.translateService.instant(element.value);
          });
          this.unitSystem = await orderAlphabaticallyUnit(
            this.unitSystem,
            this.translateService.currentLang
          );
        }
      });

    let activitiesFirstTiers = cloneDeep(
      this.allActivities.filter((activity: Activity) => !activity.activity_id)
    );
    activitiesFirstTiers.forEach((element) => {
      Object.defineProperties(element, {
        title: { writable: true },
      });
      element.title = this.translateService.instant('activity.' + element.id);
    });
    this.activitiesFirstTiers = mapLangeTitleObjects(
      activitiesFirstTiers,
      'title',
      this.translateService
    );
    this.checkDisabledActivities();
    this.loadActivities();
    console.log(this.activitiesFirstTiers);
    console.log(this.activitiesSecondTiers);
    console.log(this.activitiesThirdTiers);
  }

  checkDisabledActivities() {
    if (
      this.cashflow &&
      this.cashflow?.morphable_type == 'App\\Models\\Salarie' &&
      this.cashflow?.salary &&
      this.cashflow?.salary?.payment_type == 'PAYMENT_PER_HARVESTED_QUANTITY'
    ) {
      this.selectedActivityFirstTiers = this.allActivities.find(
        (element) => element.id == 42
      );
      this.activitiesSecondTiers = this.allActivities.filter(
        (activity: Activity) => activity.activity_id === 42
      );

      this.selecttedActivitySecondTiers = this.allActivities.find(
        (activity: Activity) => activity.id === 43
      );

      switch (true) {
        case this.cashflow.accounting_entries_categories ==
          CAFE_ACCOUNTING_ENTRY_CATEGORY:
          this.cashflow.activity_id = 78;
          break;
        case this.cashflow.accounting_entries_categories ==
          COCOA_ACCOUNTING_ENTRY_CATEGORY:
          this.cashflow.activity_id = 77;
          break;

        default:
          this.cashflow.activity_id = 43;

          break;
      }

      this.activityDisabled = true;
    } else {
      if (!this.cashflow.id) {
        this.activityDisabled = false;
        this.selectedActivityFirstTiers = null;
        this.selecttedActivitySecondTiers = null;
        this.cashflow.activity_id = null;
      }
    }
    this.activityChange.emit(this.cashflow);

    this.checkActivityQty();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    if (this.cashflow.id) {
      // this.selectedActivityFirstTiers = this.allActivities.find(
      //   (element) => element.id == this.cashflow.activity_id
      // );
      this.loadActivities();

      this.formConfig.qunatity_harvest = this.cashflow.quantity;
    }
    this.checkActivityQty();

    this.checkDisabledActivities();
  }
  loadActivities() {
    let selectedActivity = cloneDeep(
      this.allActivities.find(
        (element) => element.id == this.cashflow.activity_id
      )
    );
    if (selectedActivity?.main_activity?.main_activity) {
      this.onFirstTiersChange(
        selectedActivity?.main_activity?.main_activity.id
      );
      this.onSecondTiersChange(selectedActivity?.main_activity.id);

      this.onThirdTiersChange(selectedActivity.id);

      this.selectedActivityFirstTiers =
        selectedActivity?.main_activity?.main_activity;
      this.selecttedActivitySecondTiers = selectedActivity?.main_activity;

      this.selecttedActivityThirdTiers = selectedActivity;
    } else if (selectedActivity?.main_activity) {
      // this.onFirstTiersChange(selectedActivity?.main_activity.id);

      this.onSecondTiersChange(selectedActivity?.id);
      this.selectedActivityFirstTiers = selectedActivity?.main_activity;
      this.selecttedActivitySecondTiers = selectedActivity;
    } else if (selectedActivity && !selectedActivity?.main_activity) {
      this.onFirstTiersChange(selectedActivity?.id);
      this.selectedActivityFirstTiers = selectedActivity;
    }
  }

  checkActivityQty() {
    this.formConfig.is_harvest_activity =
      (this.cashflow?.id &&
        HARVEST_ACTIVITY_IDS.includes(this.cashflow?.activity_id)) ||
      (HARVEST_ACTIVITY_IDS.includes(this.cashflow?.activity_id) &&
        ((this.cashflow.salary &&
          !this.cashflow.salary.quantity_product &&
          this.cashflow.salary.payment_type !=
            'PAYMENT_PER_HARVESTED_QUANTITY' &&
          !this.cashflow.salary.unit) ||
          (this.cashflow.morphable &&
            !this.cashflow.morphable.quantity_product &&
            (!this.cashflow.morphable.unit ||
              this.cashflow.morphable.unit == 'undefined'))));
  }
  compareWith(e1, e2) {
    return e1.id == e2.id;
  }
  onFirstTiersChange(id) {
    this.cashflow.activity_id = null;
    if (!this.cashflow.id) {
      this.selecttedActivityThirdTiers = null;
      this.selecttedActivitySecondTiers = null;
    }
    let activitiesSecondTiers = cloneDeep(
      this.allActivities?.filter(
        (activity: Activity) => activity.activity_id === id
      )
    );
    activitiesSecondTiers?.forEach((element) => {
      Object.defineProperties(element, {
        title: { writable: true },
      });
      element['title'] = this.translateService.instant(
        'activity.' + element?.id
      );
    });
    this.activitiesSecondTiers = mapLangeTitleObjects(
      activitiesSecondTiers,
      'title',
      this.translateService
    );
    this.activitiesThirdTiers = null;
    console.log('---onFirstTiersChange----');
    this.checkActivityQty();
    if (this.activitiesSecondTiers?.length == 1) {
      this.selecttedActivitySecondTiers = this.activitiesSecondTiers[0];
      this.cashflow.activity_id = this.activitiesSecondTiers[0].id;
      this.activityChange.emit(this.cashflow);
    } else if (
      this.activitiesSecondTiers?.length == 0 ||
      !this.activitiesSecondTiers
    ) {
      this.cashflow.activity_id = id;
      this.activityChange.emit(this.cashflow);
    }
  }

  onSecondTiersChange(id) {
    this.cashflow.activity_id = null;
    if (!this.cashflow.id) {
      this.selecttedActivityThirdTiers = null;
    }
    let activitiesThirdTiers = cloneDeep(
      this.allActivities.filter(
        (activity: Activity) => activity.activity_id === id
      )
    );
    activitiesThirdTiers?.forEach((element) => {
      Object.defineProperties(element, {
        title: { writable: true },
      });
      element['title'] = this.translateService.instant(
        'activity.' + element.id
      );
    });
    this.activitiesThirdTiers = mapLangeTitleObjects(
      activitiesThirdTiers,
      'title',
      this.translateService
    );
    ('---onSecondTiersChange----');

    if (this.activitiesThirdTiers?.length == 1) {
      this.cashflow.activity_id = this.activitiesThirdTiers[0].id;
      this.activityChange.emit(this.cashflow);
    } else if (
      this.activitiesThirdTiers?.length == 0 ||
      !this.activitiesThirdTiers
    ) {
      this.cashflow.activity_id = id;
      this.activityChange.emit(this.cashflow);
    }
    this.checkActivityQty();

    this.activityChange.emit(this.cashflow);
  }

  onThirdTiersChange(id) {
    this.cashflow.activity_id = null;
    ('---onThirdTiersChange----');

    if (!this.cashflow.id) this.cashflow.activity_id = id;
    this.activityChange.emit(this.cashflow);
  }

  quantityChanged() {
    this.cashflow.quantity = this.formConfig.qunatity_harvest;
    this.activityChange.emit(this.cashflow);
  }

  unitChanged(event) {
    this.cashflow.unity = event;
    this.activityChange.emit(this.cashflow);
  }
}
