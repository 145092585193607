import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { OfflineOperation } from 'src/app/models/offlineOperation.model';

export const UPDATE_CONNECTION_STATUS = 'UPDATE_CONNECTION_STATUS';
export const SYNC_OFFLINE_OPERATION = 'SYNC_OFFLINE_OPERATION';
export const SYNC_OFFLINE_OPERATION_SUCCESS = 'SYNC_OFFLINE_OPERATION_SUCCESS';
export const ADD_OFFLINE_OPERATION = 'ADD_OFFLINE_OPERATION';
export const RESET_OFFLINE_OPERATION = 'RESET_OFFLINE_OPERATION';
export const REMOVE_ONE_OFFLINE_OPERATION = 'REMOVE_ONE_OFFLINE_OPERATION';
export const MARK_OPERATION_EXECUTED = 'MARK_OPERATION_EXECUTED';
export const MARK_OPERATION_EXECUTED_WITH_ERROR =
  'MARK_OPERATION_EXECUTED_WITH_ERROR';
export const EXECUTE_OPERATION = 'EXECUTE_OPERATION';
export const SYNC_DATA_DONE = 'SYNC_DATA_DONE';
export const UPDATE_SYNC = 'UPDATE_SYNC';
export const LOAD_APP_DATA = 'LOAD_APP_DATA';

export const updateConnectionStatus = createAction(
  UPDATE_CONNECTION_STATUS,
  props<{ isOnline: boolean; connectionQuality: 'good' | 'poor' | 'offline' }>()
);

export const loadAppData = createAction(LOAD_APP_DATA);

export const syncData = createAction(SYNC_OFFLINE_OPERATION);
export const syncDataSuccess = createAction(SYNC_OFFLINE_OPERATION_SUCCESS);
export const addOfflineOperation = createAction(
  ADD_OFFLINE_OPERATION,

  props<{ operation: OfflineOperation }>()
);

export const resetOfflineOperation = createAction(RESET_OFFLINE_OPERATION);
export const executeOperation = createAction(
  EXECUTE_OPERATION,
  props<{ operation: OfflineOperation }>()
);
export const SyncDataDone = createAction(SYNC_DATA_DONE);

export const markOperationExecuted = createAction(
  MARK_OPERATION_EXECUTED,
  props<{ id: number; error: HttpErrorResponse; data: any }>()
);
export const markOperationExecutedWithError = createAction(
  MARK_OPERATION_EXECUTED_WITH_ERROR,
  props<{ id: number; error: HttpErrorResponse; data: any }>()
);
export const removeOneOfflineOperation = createAction(
  REMOVE_ONE_OFFLINE_OPERATION,
  props<{ operation: OfflineOperation }>()
);
export const UpdateSync = createAction(
  UPDATE_SYNC,
  props<{ data: any; offlineId: string; stateName: string }>()
);
