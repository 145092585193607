import { Platform, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource, PermissionStatus } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { ToastUtils } from '../utils/toast_builder';
import { lastValueFrom, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConnectionState } from '../utils/states-management/reducers/offline.reducer';

@Injectable({
  providedIn: 'root',
})
export class PhotoService {
  constructor(
    public translateService: TranslateService,
    private platform: Platform,
    private alertController: AlertController,
    private toastUtils: ToastUtils,
    public store: Store<{ connection: ConnectionState }>,
  ) { }

  public photoTaken: PhotoTaken = {
    webviewPath: '../../assets/empty_avatar.png',
    filepath: '',
    url: '',
  };

  async savePicture(): Promise<PhotoTaken> {
    try {
      const base64Data = await this.readAsBase64();
      const fileName = new Date().getTime() + '.jpeg';
      await Filesystem.writeFile({
        path: fileName,
        data: base64Data,
        directory: Directory.Data,
      });

      return {
        filepath: fileName,
        webviewPath: this.photoTaken.webviewPath,
        url: this.photoTaken.url,
      };
    } catch (error) {
      console.error('Failed to save picture:', error);
      throw error;
    }
  }

  async savePictureLocally(): Promise<string> {
    try {
      const base64Data = await this.readAsBase64();
      const fileName = new Date().getTime() + '.jpeg';
      await Filesystem.writeFile({
        path: fileName,
        data: base64Data,
        directory: Directory.Data,
      });
      return fileName;
    } catch (error) {
      console.error('Failed to save picture locally:', error);
      throw error;
    }
  }

  async getCameraPermission(): Promise<boolean> {
    try {
      const permissions = await Camera.checkPermissions();
      console.log('Initial permissions:', permissions);

      if (permissions.camera === 'granted' && permissions.photos === 'granted') {
        return true;
      }

      // First request camera permission
      const cameraStatus = await Camera.requestPermissions({
        permissions: ['camera'],
      });

      // Then request photos permission separately
      const photosStatus = await Camera.requestPermissions({
        permissions: ['photos'],
      });

      if (cameraStatus.camera !== 'granted' || photosStatus.photos !== 'granted') {
        // Handle limited permissions
        if (photosStatus.photos === 'limited') {
          // iOS limited photo access - we can work with this
          try {
            const limitedPhotos = await Camera.getLimitedLibraryPhotos();
            if (limitedPhotos && limitedPhotos.photos.length === 0) {
              await Camera.pickLimitedLibraryPhotos();
            }
          } catch (err) {
            console.error('Error handling limited photos:', err);
          }
          return true;
        }

        await this.showSettingsAlert();
        return false;
      }

      return true;
    } catch (error) {
      console.error('Error checking permissions:', error);
      await this.showSettingsAlert();
      return false;
    }
  }

  async addNewToGallery(): Promise<PhotoTaken | null> {

    let isOnline = false;
    this.store.select('connection').pipe(take(1)).subscribe(connection => {
      isOnline = connection.isOnline && connection.connectionQuality === 'good';
    });
    let connection = await lastValueFrom(this.store.select('connection').pipe(take(1)));
    if (!connection.isOnline
      // && connection.connectionQuality !== 'good'
    ) {
      // Show toast message when offline
      this.toastUtils.toastBuilder(
        await this.translateService.instant('errors.camera_offline_message'),
        'bottom',
        '',
        'dark',
        2000
      );
      return;
    }

    try {
      if (this.platform.is('ios') || this.platform.is('android')) {
        const hasPermission = await this.getCameraPermission();
        if (!hasPermission) {
          throw new Error('Camera or gallery permissions not granted');
        }
      } else {
        // Check for limited permissions on Web
        const permissions = await Camera.checkPermissions();
        const limitedAccess = permissions.photos === 'limited' && this.platform.is('ios');
        if (limitedAccess) {
          // Handle limited access by allowing the user to manage their selection
          await this.manageLimitedPhotoLibrary();
        }
      }




      // Always use Prompt source to give user the choice between camera and gallery
      const capturedPhoto = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Prompt, // Always show prompt regardless of permission level
        promptLabelHeader: this.translateService.instant('select_photo'),
        promptLabelPhoto: this.translateService.instant('from_gallery'),
        promptLabelPicture: this.translateService.instant('from_camera'),
        quality: 50,
        saveToGallery: true,
        allowEditing: false
      });

      if (!capturedPhoto) {
        return null;
      }

      // Validate image size (2MB limit)
      const isValidSize = await this.validateImageSize(capturedPhoto.webPath, 2);
      if (!isValidSize) {
        await this.showSizeExceededAlert();
        return null;
      }

      this.photoTaken = {
        filepath: capturedPhoto.base64String || '',
        webviewPath: capturedPhoto.webPath || '',
        url: capturedPhoto.dataUrl || '',
      };

      return this.photoTaken;
    } catch (error) {
      console.error('Error capturing photo:', error);

      // Handle specific error cases
      if (error.message) {
        if (error.message.includes('limited')) {
          await this.showLimitedPermissionToast();
        } else if (error.message.includes('User cancelled photos app')) {
          // User cancelled the photo picker, handle gracefully
          return null;
        }
      }

      throw error;
    }
  }

  private async showSettingsAlert() {
    // Replace alert with toast
    this.toastUtils.toastBuilder(
      await this.translateService.instant('errors.permission_required'),
      'bottom',
      '',
      'dark',
      3000
    );


  }

  private async openSettings() {
    const url = this.platform.is('ios')
      ? 'app-settings:'
      : this.platform.is('android')
        ? 'package:' + this.getPackageName()
        : '';

    if (url) {
      await App.exitApp();
    }
  }

  private getPackageName(): string {
    return 'com.burrusdevelopmentgroup.fynka.app';
  }

  /**
   * Validates if the image size is within the specified limit
   * @param imagePath Path to the image
   * @param maxSizeMB Maximum size in MB
   * @returns Promise<boolean> True if size is valid
   */
  private async validateImageSize(imagePath: string, maxSizeMB: number): Promise<boolean> {
    try {
      // Fetch the image as a blob
      const response = await fetch(imagePath);
      const blob = await response.blob();

      // Check if size exceeds limit (convert MB to bytes)
      const maxSizeBytes = maxSizeMB * 1024 * 1024;
      return blob.size <= maxSizeBytes;
    } catch (error) {
      console.error('Error validating image size:', error);
      return false;
    }
  }

  private async showSizeExceededAlert() {
    this.toastUtils.toastBuilder(
      await this.translateService.instant('errors.img_size_limit'),
      'bottom',
      '',
      'dark',
      3000
    );
  }

  private async showLimitedPermissionToast() {
    this.toastUtils.toastBuilder(
      await this.translateService.instant('errors.limited_photo_access'),
      'bottom',
      '',
      'dark',
      3000
    );
  }
  private async readAsBase64(): Promise<string> {
    try {
      const response = await fetch(this.photoTaken.webviewPath);
      const blob = await response.blob();
      return await this.convertBlobToBase64(blob);
    } catch (error) {
      console.error('Error converting image to base64:', error);
      throw error;
    }
  }

  private convertBlobToBase64 = (blob: Blob): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(blob);
    });

  /**
   * Allows users to manage their limited photo library selection (iOS only)
   * This can be called from a settings page or when users want to change their selection
   */
  async manageLimitedPhotoLibrary(): Promise<void> {
    if (this.platform.is('ios')) {
      try {
        const permissions = await Camera.checkPermissions();
        if (permissions.photos === 'limited') {
          // First show a toast to inform the user what they're about to do
          this.toastUtils.toastBuilder(
            await this.translateService.instant('errors.select_photos_access'),
            'bottom',
            '',
            'dark',
            2000
          );

          // Then open the photo picker
          await Camera.pickLimitedLibraryPhotos();

          // Confirm selection was updated
          this.toastUtils.toastBuilder(
            await this.translateService.instant('errors.limited_selection_updated'),
            'bottom',
            '',
            'dark',
            3000
          );
        } else {
          await this.showSettingsAlert();
        }
      } catch (error) {
        console.error('Error managing limited photo library:', error);
      }
    } else {
      // Not applicable for other platforms
      console.log('Limited photo library management is only available on iOS');
    }
  }
}

export interface PhotoTaken {
  filepath: String;
  webviewPath: string;
  url: String;
}
