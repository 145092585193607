import { Good } from '../good.model';

export class ProductionsPerfarm {
  id: number;
  morphable: any;
  morphable_type: string;
  morphable_id: number;
  trees_numbers: number;
  farm_id: number;
  // goods: Array<Good>;
  production_per_farm_id;
  goods: Array<any>;
  comment: string;
  is_animal: number;
  is_checked: boolean;
  cash_flow_categories: any;
  is_crop: any;
  file;
  category_id;
}

export interface ListableProduction {
  name: string;
  name_en: string;
  morphable_id: number;
  id: number;
  morphable_type: string;
  is_checked: boolean;
  comment: string;
  lot_id: number;
  percent_in_lot: number;
  used_surface: number;
  is_animal: number;
  cash_flow_categories: any;
  morphable: any;
  is_crop: boolean;
  production_per_farm_id: number;
}
