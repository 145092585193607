import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  imports: [
    ServiceWorkerModule.register('sw.js', {
      enabled: true,
    })
  ],
  exports: [
    ServiceWorkerModule
  ]
})
export class AppServiceWorkerModule {}