import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { lastValueFrom, take } from 'rxjs';
import { AccountingEntryCategorie } from 'src/app/models/accounting/accounting_entries_categories';
import { FilterChoice } from 'src/app/modules/dashboard/cashflow/filters/analyses-filter/analyses-filter.component';
import { orderAlphabaticallyByAttribute } from 'src/app/utils/array-filtrer';
import { PickerOptionFormater } from 'src/app/utils/pipes-utils/date_formatter';

@Component({
  selector: 'app-filter-widget',
  templateUrl: './filter-widget.component.html',
  styleUrls: ['./filter-widget.component.scss'],
})
export class FilterWidgetComponent implements OnInit {
  @Input('filter') filter: FilterChoice = {
    sort_by: 'cashflowcategories',
    start_date: new Date(new Date().getFullYear(), 0, 1).toISOString(),
    end_date: new Date(new Date().getFullYear(), 11, 31).toISOString(),
    monthly_yearly: 'm-Y',
  };
  @Input('accountingCategories')
  // accountingCategories: Array<AccountingEntryCategorie>;
  accountingCategories: Array<any>;
  @Input('cropConfig') cropConfig: Array<any>;
  @Input() modalController: ModalController;

  filterCategory = [];
  handelChange(event) {
    // this.handelFilterChange.emit({
    //   filterCategory: this.filterCategory,
    //   startDate: this.filter.start_date,
    //   endDate: this.filter.end_date,
    // });
  }
  onClose() {
    this.modalController.dismiss();
  }
  confirm() {
    this.modalController.dismiss({
      filterCategory: this.filterCategory,
      startDate: this.filter.start_date,
      endDate: this.filter.end_date,
    });
  }
  reset() {
    this.filterCategory = [];
    this.filter.start_date = new Date(
      new Date().getFullYear(),
      0,
      1
    ).toISOString();
    this.filter.end_date = new Date(
      new Date().getFullYear(),
      11,
      31
    ).toISOString();
  }
  constructor(
    private pickerOption: PickerOptionFormater,
    private store: Store<{
      owner;
    }>,
    public translateService: TranslateService

  ) { }

  listOptions = [];
  async ngOnInit() {
    let owner = await lastValueFrom(this.store.select('owner').pipe(take(1)));
    let profile_infoConfig;
    if (owner?.profile_info)
      profile_infoConfig = JSON.parse(owner?.profile_info)?.cropConfig;

    if (this.cropConfig) {
      this.cropConfig.forEach(async (val) => {
        if (val?.products?.length > 0) {
          this.listOptions = this.listOptions.concat(val?.products);
        }
      });
      this.listOptions = this.listOptions?.filter((val) => {
        return profile_infoConfig.some((crop) => crop.id == val.crop_id);
      });
    } else if (this.accountingCategories?.length > 0) {
      this.accountingCategories = cloneDeep(this.accountingCategories)
      this.accountingCategories.map((element) =>
        element.title = this.translateService.instant('accounting_entries.' + element.id)
      );
      this.accountingCategories = orderAlphabaticallyByAttribute(
        this.accountingCategories,
        'title',
        this.translateService.currentLang
      );

      console.log(this.accountingCategories)
      this.accountingCategories?.forEach((val) =>
        this.filterCategory.push(val.id)
      );
    }
  }
}
