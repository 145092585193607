import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { DatePicker } from '@ionic-native/date-picker/ngx';
import { SmsRetriever } from '@ionic-native/sms-retriever/ngx';
import { PopoverController } from '@ionic/angular';

// Services
import { UserInfo } from '../apis/users-info.service';
import { AccountingCategoriesAPI } from '../apis/accounting_categories.service';
import { CashflowAPI } from '../apis/cashflow.service';
import { ClustersAPI } from '../apis/clusters.service';
import { FarmAPI } from '../apis/farm.service';
import { GoodsAPI } from '../apis/goods.service';
import { LoginService } from '../apis/login.service';
import { LotAPI } from '../apis/lot.service';
import { MaterialProductAPI } from '../apis/material_product.service';
import { MoneyReportingsAPI } from '../apis/money_reportings.service';
import { ObjectiveApi } from '../apis/objectives.service';
import { OperationsAPI } from '../apis/operations.service';
import { OwnerAPI } from '../apis/owners.service';
import { MyPartnersAPI } from '../apis/partners.service';
import { ProductAPI } from '../apis/product.service';
import { ProgramasAPI } from '../apis/programas.service';
import { StatisticsAPI } from '../apis/statistics.service';
import { EmployeesAPI } from '../apis/employees.service';
import { Family_membersAPI } from '../apis/family_members.service';
import { GoogleMapsService } from '../apis/GoogleMaps.service';

// Utils
import { QuestionControlService } from '../utils/dynamic-forms/QuestionControlService';
import { CashflowPriorApiTreatment } from '../utils/business-rules/cashflow-saver';
import { SalaryBusinessRules } from '../utils/business-rules/salary-related-cashflow';
import { PickerOptionFormater, DateFormatPipe } from '../utils/pipes-utils/date_formatter';
import { OperationRelatedToCashflowBusinessRules } from '../utils/business-rules/operations_related_to_cashflow_business_rules';
import { AnimationUtils } from '../utils/animations';
import { ToastUtils } from '../utils/toast_builder';
import { GetRealGoodTransactionValues } from '../utils/unit-convert';

export const AppProviders = [
  SplashScreen,
  DatePicker,
  SmsRetriever,
  PopoverController,
  
  // Services
  QuestionControlService,
  CashflowPriorApiTreatment,
  SalaryBusinessRules,
  PickerOptionFormater,
  DateFormatPipe,
  AnimationUtils,
  ToastUtils,
  GetRealGoodTransactionValues,
  
  // APIs
  UserInfo,
  AccountingCategoriesAPI,
  CashflowAPI,
  ClustersAPI,
  FarmAPI,
  GoodsAPI,
  LoginService,
  LotAPI,
  MaterialProductAPI,
  MoneyReportingsAPI,
  ObjectiveApi,
  OperationsAPI,
  OwnerAPI,
  OperationRelatedToCashflowBusinessRules,
  MyPartnersAPI,
  ProductAPI,
  ProgramasAPI,
  StatisticsAPI,
  EmployeesAPI,
  Family_membersAPI,
  GoogleMapsService
];