import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import { HARVEST_ACTIVITY_IDS } from '../consts';

@Pipe({ name: 'getFirstActivityLevel' })
export class GetFirstActivityLevelPipe implements PipeTransform {
  constructor(public translateService: TranslateService) {}

  public transform(entry: CashFlow) {
    let mainCategory = entry.activity;
    if (mainCategory?.main_activity) {
      mainCategory = mainCategory?.main_activity;
      if (mainCategory?.main_activity) {
        mainCategory = mainCategory?.main_activity;
        if (mainCategory?.main_activity) {
          mainCategory = mainCategory?.main_activity;
        }
      }
    }
    return this.translateService.instant('activity.'+mainCategory?.id);
  }
}


@Pipe({ name: 'isHarvestingActivity' })
export class IsHarvestingActivity implements PipeTransform {
  constructor(public translateService: TranslateService) {}

  public transform(entry: CashFlow) {
    return entry.morphable && entry?.morphable_type== "App\\Models\\Salarie" && (entry?.morphable?.quantity_product || entry?.quantity)
  }
  checkActivity(mainCategory):boolean{
    return HARVEST_ACTIVITY_IDS.includes(mainCategory.id)
  }
}