import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { catchError } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { Network } from '@capacitor/network';

@Injectable({
  providedIn: 'root',
})
export class VersionCheckService {
  private lastCheckTime: number = 0;
  private checkInterval: number = 1000 * 60 * 1; // 1 min in milliseconds
  private updateAvailable$ = new BehaviorSubject<boolean>(false);
  
  constructor(private http: HttpClient) {
    // Initialize last check time from storage
    const storedTime = localStorage.getItem('lastVersionCheck');
    if (storedTime) {
      this.lastCheckTime = parseInt(storedTime, 10);
    }
  }

  // Public observable for components to subscribe to
  get updateAvailable(): Observable<boolean> {
    return this.updateAvailable$.asObservable();
  }

  // Check if it's time to check for updates
  shouldCheckForUpdates(): boolean {
    const now = Date.now();
    return (now - this.lastCheckTime) > this.checkInterval;
  }

  // Main version check method with optimized timing
  async checkVersion(force: boolean = false): Promise<any> {
    try {
      // Skip check if not forced and not time yet
      if (!force && !this.shouldCheckForUpdates()) {
        console.log('Skipping version check - checked recently');
        return of({ updateAvailable: this.updateAvailable$.value });
      }

      // Skip check if offline
      const networkStatus = await Network.getStatus();
      if (!networkStatus.connected) {
        console.log('Skipping version check - device offline');
        return of({ updateAvailable: false, offline: true });
      }

      const appInfo = await this.getAppInfo();
      const platform = Capacitor.getPlatform();
      
      // Store current version in localStorage for service worker access
      localStorage.setItem('appVersion', appInfo.version);
      
      console.log(`Checking for updates: ${platform} version ${appInfo.version}`);
      
      // Update last check time
      this.lastCheckTime = Date.now();
      localStorage.setItem('lastVersionCheck', this.lastCheckTime.toString());
      
      return this.http.post(`${environment.appRoot}/api/check-version`, {
        platform: platform,
        version: appInfo.version,
        build: appInfo.build || '100',
        timestamp: new Date().toISOString()
      }).pipe(
        catchError(error => {
          console.error('Version check failed:', error);
          return of({ updateAvailable: false, error: true });
        })
      );
    } catch (error) {
      console.error('Error getting app info:', error);
      return of({ updateAvailable: false, error: true });
    }
  }

  // Method to call at optimal times (app start, resume from background)
  async InstantcheckForUpdates(): Promise<void> {
    const result = await this.checkVersion();
    if (result && result.updateAvailable) {
      this.updateAvailable$.next(true);
    }
  }

  async getWebAppInfo() {
    const response = await fetch('manifest.webmanifest');
    const manifest = await response.json();
    return {
      name: manifest.name,
      version: localStorage.getItem('appVersion') || '1.0.0',
      build: manifest.build || '100',
      platform: 'web',
    };
  }

  async getAppInfo() {
    if (Capacitor.getPlatform() === 'web') {
      return this.getWebAppInfo();
    } else {
      const info = await App.getInfo();
      return info;
    }
  }
}
