import { dev } from 'src/app/config/offlines_keys.json';
import { PopoverImgComponent } from 'src/app/widgets/popover-img/popover-img.component';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { AnimationController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UserInfo } from '../apis/users-info.service';
import routes from '../config/routing.json';

import { IonicSlides } from '@ionic/angular';
import { ToastUtils } from '../utils/toast_builder';

interface FormConfig {
  is_show_close_button: boolean;
}

@Component({
  selector: 'app-moda-select',
  templateUrl: './moda-select.component.html',
  styleUrls: ['./moda-select.component.scss'],
})
export class ModaSelectComponent implements OnInit, AfterViewInit, OnDestroy {
  list: any = [
    {
      name: 'Español',
      name_en: 'Español',
      key: 'es',
      file: {
        path: '/storage/images/flags/es.png',
      },
    },
    {
      name: 'English',
      name_en: 'English',
      key: 'en',
      file: {
        path: '/storage/images/flags/uk.png',
      },
    },
    {
      name: 'Français',
      name_en: 'Français',
      key: 'fr',
      file: {
        path: '/storage/images/flags/fra.png',
      },
    },
  ];
  isDashboardView: boolean;
  indic: boolean;
  select_langage;
  item: [];
  formConfig: FormConfig = {
    is_show_close_button: false,
  };
  selectedLang;
  LangName;
  constructor(
    private router: Router,
    public modalController: PopoverController,
    public translateService: TranslateService,
    private userInfo: UserInfo,
    private animationController: AnimationController,
    private toastUtils: ToastUtils
  ) { }
  swiperModules = [IonicSlides];
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    console.log('destroy ! ');
  }
  @ViewChild('swiper')
  swiperRef: ElementRef | undefined;
  step = 0;
  ionViewWillEnter() {
    this.resetSwiper();
  }
  async ngOnInit() {
    this.resetSwiper();

    this.isDashboardView = this.router.url.includes(routes.dashboard.home);
    this.select_langage = this.translateService.currentLang;
    this.formConfig.is_show_close_button = this.router.url !== '/';

  }

  ngAfterViewInit(): void {
    this.swiperRef.nativeElement.addEventListener('slidechange', (e) => {
      this.step = this.swiperRef.nativeElement.swiper.activeIndex;
    });

    setTimeout(() => {
      this.animationController
        .create()
        .addElement(document.querySelector('.welcome-animation'))
        .duration(1200)
        .fromTo('opacity', '0', '1')
        .afterStyles({ 'animation-timing-function': 'ease-in-out' })
        .beforeStyles({ 'animation-timing-function': 'ease-in-out' })
        .play();
    }, 1000);
  }
  resetSwiper() {
    // Use setTimeout to ensure the swiper is fully rendered
    setTimeout(() => {
      if (this.swiperRef?.nativeElement?.swiper) {
        this.swiperRef.nativeElement.swiper.slideTo(0,200);
      }
    }, 100);
  }
  onSwiperInit(event: any) {
    // You can access the swiper instance through event.detail[0]
    const swiperInstance = event.detail[0];

    // Reset to first slide
    swiperInstance.slideTo(0);

    // Additional initialization logic can go here
    console.log('Swiper initialized', swiperInstance);
  }
  navigate(e) {
    if (this.swiperRef.nativeElement.swiper.slides.length == e) {
      this.router.navigate(['/login']);
    }
    // if (e == this.swiperRef.nativeElement.swiper.length) {
    //   this.router.navigate(['/country']);
    //   return;
    // }
    this.swiperRef.nativeElement.swiper.slideTo(e, 500);
  }
  swipeNext() {
    this.swiperRef.nativeElement.swiper.slideNext();
  }

  // handelChangeLang() {
  //     this.translateService.use(this.select_langage);
  //     this.handelLangage();

  //   if (!this.isDashboardView) this.router.navigateByUrl(routes.auth.login);
  //   else this.modalController.dismiss();
  // }
  dismiss() {
    if (this.isDashboardView) this.modalController.dismiss();
  }

  async check() {
    this.router.navigate(['/country']);
  }
  async handelChangeCountrieName(ev: any) {
    // const popover = await this.modalController.create({
    //   component: PopoverImgComponent,
    //   event: ev,
    //   translucent: true,
    //   componentProps: { data: this.list, component: 'lang' },
    // });
    let componentProps = { data: this.list, component: 'lang' };

    const popover = await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverImgComponent,
      'pop-over-style'
    );

    popover.present();
    const { data } = await popover.onDidDismiss();
    if (data) {
      this.LangName = data.data.name;
      this.select_langage = data.data.key;
      this.translateService.use(this.select_langage);
      this.handelLangage();

      if (!this.isDashboardView) this.router.navigateByUrl(routes.auth.login);
      else this.modalController.dismiss();
    } else {
      this.LangName = '';
    }
  }
  async handelLangage() {
    await this.userInfo.setSelectedLangage(this.select_langage);
  }
}
