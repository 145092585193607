import {
  addOfflineOperation,
} from './../utils/states-management/actions/offline.action';
import { ConnectionState } from './../utils/states-management/reducers/offline.reducer';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { EMPTY, Observable, Subscription, throwError, timer } from 'rxjs';
import { tap, catchError, finalize, distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LOGOUT } from '../utils/states-management/actions/owner.action';
import { ConnectionService } from '../services/connection.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private pendingRequests = 0;
  private consecutiveEmptyChecks = 0;
  private slowRequestTimer: Subscription | null = null;
  private isOnline = false;
  private lastConnectionState: 'good' | 'poor' | 'offline' = 'good';
  ConnectionStateChecker: 'good' | 'poor' | 'offline' = 'good'
  constructor(
    public router: Router,
    private store: Store<{ connection: ConnectionState }>,
    private tokenExtractor: HttpXsrfTokenExtractor,
    private connectionService: ConnectionService
  ) {
    this.store.select('connection').pipe(
      distinctUntilChanged((a, b) =>
        a.isOnline === b.isOnline &&
        a.connectionQuality === b.connectionQuality
      )
    ).subscribe(({ isOnline, connectionQuality }) => {
      this.isOnline = isOnline;
      this.lastConnectionState = connectionQuality; // Track quality state
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.shouldSkipInterceptor(req.url)) {
      return next.handle(req);
    }

    // Handle offline operations first
    // if (!this.isOnline || this.lastConnectionState == 'poor' && this.isModificationMethod(req.method)) {
    if (!this.isOnline) {
      if (this.isModificationMethod(req.method)) {
        console.log(`Offline operation queued: ${req.method} ${req.url}`);
        this.addOfflineOperation(req);
      }
      return EMPTY;
    }

    // Track pending requests
    this.pendingRequests++;
    this.consecutiveEmptyChecks = 0;

    // Capture request start time
    const requestStart = Date.now();

    // Clone request with security tokens
    const authReq = this.cloneRequestWithTokens(req);

    // Setup monitoring timers
    this.setupRequestTimers(authReq);

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => this.handleError(error)),
      finalize(() => {
        this.cleanupRequest();

        // New connection check logic for fast responses after poor connectivity
        const requestDuration = Date.now() - requestStart;
        if (requestDuration < 2000 &&
          (this.lastConnectionState === 'offline' || this.lastConnectionState === 'poor')) {
          console.log('[Connection] Fast response after poor connectivity - verifying');
          this.connectionService.requestConnectionCheck();
        }
      })
    );
  }

  private shouldSkipInterceptor(url: string): boolean {
    const skipUrls = [
      'https://us1.locationiq.com/v1/reverse',
      'api/get_language_content/',
      '/api/test-connection',
      'speed.cloudflare'
    ];
    return skipUrls.some(skipUrl => url.includes(skipUrl));
  }

  private isModificationMethod(method: string): boolean {
    return ['POST', 'DELETE', 'PUT'].includes(method);
  }

  // addOfflineOperation(request: HttpRequest<any>) {
  //   // Simplify offline operation validation
  //   if (request.method === 'DELETE' && request.url.includes('offline-')) return;

  //   const formData = this.parseFormData(request.body);
  //   if (formData?.id?.includes('offline-')) return;

  //   this.store.dispatch(addOfflineOperation({ operation: request }));
  // }

  addOfflineOperation(request) {
    if (
      request.method === 'DELETE' &&
      (request.url.includes('offline-') || request.url.includes('undefined'))
    )
      return;

    let formDataObject = null;

    if (request?.body instanceof FormData) {
      formDataObject = {};
      for (const [key, value] of request?.body?.entries()) {
        formDataObject[key] = value;
      }
    }

    if (formDataObject) {
      request.formDataObject = formDataObject;
      if (
        (request.method === 'POST' || request.method === 'PUT') &&
        formDataObject?.id?.includes('offline-')
      )
        return;
    }

    this.store.dispatch(addOfflineOperation({ operation: request }));
  }


  // private parseFormData(body: any): Record<string, any> | null {
  //   if (!(body instanceof FormData)) return null;

  //   const result: Record<string, any> = {};
  //   for (const [key, value] of body.entries()) {
  //     result[key] = value;
  //   }
  //   return result;
  // }

  private cloneRequestWithTokens(req: HttpRequest<any>): HttpRequest<any> {
    const token = this.tokenExtractor.getToken();
    return token ? req.clone({
      headers: req.headers.set('X-XSRF-TOKEN', token),
      withCredentials: true
    }) : req.clone({ withCredentials: true });
  }

  private setupRequestTimers(req) {
    this.slowRequestTimer?.unsubscribe();
    this.slowRequestTimer = timer(2000).subscribe(() => {
      // if (this.lastConnectionState === 'good') {
      if (this.lastConnectionState != this.ConnectionStateChecker) {
        this.connectionService.requestConnectionCheck();
        this.ConnectionStateChecker = this.lastConnectionState;
        this.slowRequestTimer = null;
      }

    });

    // Handle image upload timeout separately
    if (this.isImageUpload(req)) {
      this.handleImageUploadTimeout();
    }
  }

  private isImageUpload(req: HttpRequest<any>): boolean {
    return req.body instanceof FormData &&
      Array.from(req.body.entries()).some(([_, value]) =>
        value instanceof Blob || (typeof value === 'string' && value.startsWith('data:image'))
      );
  }

  private handleImageUploadTimeout() {
    const imageUploadTimer = timer(30000).subscribe(() => {
      this.connectionService.requestConnectionCheck();
    });
    return imageUploadTimer;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 401) {
      this.store.dispatch({ type: LOGOUT, redirection_path: '/' });
    }

    if (this.isNetworkError(error)) {
      this.connectionService.requestConnectionCheck();
    }

    return throwError(() => error);
  }

  private isNetworkError(error: HttpErrorResponse): boolean {
    return error.status === 0 || error.status >= 500;
  }

  private cleanupRequest() {
    this.pendingRequests = Math.max(0, this.pendingRequests - 1);
    this.slowRequestTimer?.unsubscribe();
    this.slowRequestTimer = null;

    if (this.pendingRequests === 0) {
      this.handleIdleState();
    }
  }

  private handleIdleState() {
    this.consecutiveEmptyChecks++;

    if (this.consecutiveEmptyChecks >= 3) {
      this.connectionService.stopConnectionQualityMonitoring();
      this.consecutiveEmptyChecks = 0; // Reset counter after pausing
    }
  }
}
