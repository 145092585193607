import { DateFormatPipe } from './../../../../utils/pipes-utils/date_formatter';
import { environment } from 'src/environments/environment';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import { StatisticsAPI } from 'src/app/apis/statistics.service';
import { BookCashflowListFilter, BookComponent } from '../book/book.component';
import { SummaryComponent } from '../../summary/summary.component';
import { lastValueFrom, Subscription, take } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { Store } from '@ngrx/store';
import {
  getHarvestSummary,
  getTotalsAccountingCategoriesResult,
} from 'src/app/utils/states-management/selector/dashboard.selector';
import { loadHarvestSummary } from 'src/app/utils/states-management/actions/dashboard.action';
import { AccountingEntryCategory } from 'src/app/models/cash-flow/cashflow_categories';
import { clone, cloneDeep } from 'lodash';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import { UnifiedCashflowInputComponentComponent } from 'src/app/modules/cashflow/unified-cashflow-input-component/unified-cashflow-input-component.component';
import Owner from 'src/app/models/owner.model';
import { connectionStatus } from 'src/app/utils/states-management/reducers/offline.reducer';

interface StatRequestParams {
  start_date: string;
  end_date: string;
  sort_by: string;
  monthly_yearly: string;
}
@Component({
  selector: 'app-analysis-harvest',
  templateUrl: './analysis-harvest.component.html',
  styleUrls: ['./analysis-harvest.component.scss'],
})
export class AnalysisHarvestComponent implements OnInit, OnDestroy {
  @Input() nav: IonNav;
  lineChart;
  user;
  cropProductAccounting = [];
  params: StatRequestParams = {
    start_date: new Date(new Date().getFullYear(), 0, 1).toISOString(),
    end_date: new Date().toISOString(),
    sort_by: null,
    monthly_yearly: null,
  };
  isFiltred = false;
  @ViewChild('lineChart') lineChartCanvas: ElementRef;
  base_url = environment.appRoot;
  selected_year;
  profile_info;

  sum = 0;
  $ownerSubscription: Subscription;
  constructor(
    public translateService: TranslateService,
    private statisticsApi: StatisticsAPI,
    private modalController: ModalController,
    private datePipe: DateFormatPipe,
    private store: Store<{
      accountingEntries: Array<AccountingEntryCategory>;
      owner: Owner;
    }>
  ) {
    // this.$ownerSubscription = this.store.select('owner').subscribe((value) => {
    //   this.user = cloneDeep(value);
    //   if (this.user && typeof this.user?.profile_info == 'string')
    //     this.profile_info = JSON.parse(this.user?.profile_info);
    // });

  
  }

  ngOnDestroy(): void {
   // this.$ownerSubscription.unsubscribe();
  }
  Check(crop) {
    crop.checked = !crop.checked;
    this.drawLineChart(this.harvestingData);
  }
  onFilterClick(filter) {
    this.filterAccordion[filter] = !this.filterAccordion[filter];
  }

  handelFilterClicked(ev) {
    this.filterAccordion = {
      general: ev,
      selection: ev,
      category: ev,
    };
  }
  harvestingData: any;
  async ngOnInit() {
    this.user = cloneDeep(
      await lastValueFrom(this.store.select('owner').pipe(take(1)))
    );
    if (this.user?.profile_info)
      this.profile_info = JSON.parse(this.user?.profile_info);
    let { data } = await lastValueFrom(
      this.store.select(getTotalsAccountingCategoriesResult).pipe(take(1))
    );

    var totalsAccountingCategories = [];
    cloneDeep(data).forEach((element) => {
      if (element?.morphable_type != null && element?.id != null) {
        let cropExist = this.profile_info.cropConfig.find(
          (item) =>
            item.id == element.morphable_id &&
            element.morphable_type.toLowerCase().includes(item.entity) &&
            element?.morphable
        );
        if (cropExist) {
          totalsAccountingCategories.push(element);
        }
      }
      // else {
      //   totalsAccountingCategories.push(element);
      // }
    });

    this.cropProductAccounting = totalsAccountingCategories;
    this.cropProductAccounting.forEach((element) => {
      element['checked'] = true;
    });
    this.fetchAndDrawData();
    
    this.store.select(getHarvestSummary).subscribe((value) => {
      this.harvestingData = cloneDeep(value);
      // this.formatData(this.harvestingData)
      this.drawLineChart(this.harvestingData);
      this.isFiltred = true;
    });
  }
  filterAccordion = {
    general: true,
    selection: true,
    category: true,
  };
  redirectToBook() {
    let bookFilter: BookCashflowListFilter = {
      startDate: this.params.start_date,
      endDate: this.params.end_date,
      filterCategory: [1],
      filterType: [0],
      categorieIsFiltred: true,
      cashflowTypeIsFiltred: true,
      is_loading: true,
      isFiltred: false,
      page: 1,
      page_size: 10,
      isHarvestingFilter: true,
    };
    this.nav.setRoot(BookComponent, {
      nav: this.nav,
      bookCashflowListFilter: bookFilter,
      rootStack: SummaryComponent,
    });
  }

  initDate(startDate, endDate) {
    this.selected_year =
      this.datePipe.transform(startDate, this.translateService.currentLang) +
      ' - ' +
      this.datePipe.transform(endDate, this.translateService.currentLang);
  }

  HandelDateChange(value) {
    this.params.start_date = value.start_date;
    this.params.end_date = value.end_date;
    this.initDate(this.params.start_date, this.params.end_date);

    this.fetchAndDrawData();
  }

  formatData(data) {
    const groupedByDate = data?.reduce((acc, curr) => {
      if (!acc[curr.date]) {
        acc[curr.date] = { date: curr.date, sum: 0 };
      }
      acc[curr.date].sum += curr.sum;

      return acc;
    }, {} as { [key: string]: { date: string; sum: number } });

    return Object.values(groupedByDate);
  }
  async drawLineChart(values) {
    this.sum = 0;
    let filtredData = values?.filter((element) => {
      if (
        this.cropProductAccounting.some(
          (val) => val.id == element.accounting_id && val.checked
        )
      ) {
        this.sum += element?.sum;
        return element;
      }
    });

    if (this.lineChart) this.lineChart.destroy();
    var dataHarvested = {
      label: this.translateService.instant('activity.43'),
      data: new Array(),
      lineTension: 0,
      fill: false,
      borderColor: 'green',
      backgroundColor: 'green',
      pointBorderColor: 'green',
      pointBackgroundColor: 'green',
    };
    const labels = new Array();
    this.formatData(filtredData)?.map((element: any) => {
      const keys = Object.keys(element);
      // if (keys[0]) {
      //   labels.push(keys[0]);
      //   dataHarvested.data.push(element[keys[0]]);
      // }
      if (element?.date) {
        labels.push(element?.date);
        dataHarvested.data.push(element?.sum);
      }
    });
    var speedData = {
      labels: labels,
      datasets: [dataHarvested],
    };
    if (this.lineChart) {
      await this.lineChart?.destroy();
    }
    this.lineChart = new Chart(this.lineChartCanvas.nativeElement, {
      type: 'line',
      data: speedData,
      options: {
        scales: {
          y: {
            position: 'right',
            title: {
              color: '#bdbdbd',
              text: 'KG',
              display: false,
              align: 'end',
              font: {
                size: 14,
                family: "'Poppins', ui-sans-serif, system-ui, '-apple-system'",
              },
            },
            beginAtZero: true,
          },
          x: {
            title: {
              color: '#bdbdbd',
              text: await this.translateService.instant('label.time'),
              display: false,
              align: 'end',
              font: {
                size: 14,
                family: "'Poppins', ui-sans-serif, system-ui, '-apple-system'",
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }

  fetchAndDrawData() {
    let data = {
      startDate: this.params.start_date,
      endDate: this.params.end_date,
      cropId: this.cropProductAccounting[0].id,
    };
    this.store.dispatch(loadHarvestSummary({ data: data }));
  }
  async openHarvestingCashflow() {
    let cashflow = new CashFlow();
    cashflow.date = new Date().toISOString();
    cashflow.farm_id = this.user?.owner?.farm_id;
    cashflow.accounting_entry_categories = this.cropProductAccounting[0].id;
    cashflow.is_income = false;
    if (this.cropProductAccounting[0].id == 10) {
      cashflow.cash_flow_expense_id = 63;
      cashflow.cashflow_expenses_categorie =
        this.cropProductAccounting[0]?.cashflow_category
          .find((value) => value.id == 299)
          ?.cash_flow_expenses.find((value) => value.id == 63);

      cashflow.cashflow_categories =
        this.cropProductAccounting[0]?.cashflow_category.find(
          (value) => value.id == 299
        );
      cashflow.cash_flow_categorie_id = 299;
    } else {
      cashflow.cash_flow_categorie_id = 148;
      cashflow.cash_flow_expense_id = 63;
      cashflow.cashflow_expenses_categorie =
        this.cropProductAccounting[0]?.cashflow_category
          .find((value) => value.id == 292)
          ?.cash_flow_expenses.find((value) => value.id == 63);
      cashflow.cashflow_categories =
        this.cropProductAccounting[0]?.cashflow_category.find(
          (value) => value.id == 292
        );
      cashflow.cash_flow_categorie_id = 292;
    }
    const modal = await this.modalController.create({
      component: UnifiedCashflowInputComponentComponent,
      cssClass: ['my-custom-modal-css ', 'modal-wrapper'],
      componentProps: {
        // cashFlowEntrieName: sell.accounting_entries_categories,
        accountingEntryCategories: this.cropProductAccounting[0],
        modalController: this.modalController,
        cashflow: cashflow,
        is_update: false,
        popOverContent: this.translateService.instant('select_harvesting'),
      },
    });
    modal.present();
    await modal.onWillDismiss();
    this.fetchAndDrawData();
    // this.setupotherProdList();
  }
  isOffline(): boolean {
    let isOffline: boolean;
    this.store.select(connectionStatus).subscribe((isOnline) => {
      isOffline = !isOnline;
    });
    return isOffline;
  }
}
