import { Component, OnInit, Input, ViewChild } from '@angular/core';
import Owner from '../../models/owner.model';
import { OwnerAPI } from '../../apis/owners.service';
import {
  // formatDate,
  PickerOptionFormater,
  formatDate,
} from '../../utils/pipes-utils/date_formatter';
import { Storage } from '@capacitor/storage';
import { dev } from '../../config/offlines_keys.json';
import { Router } from '@angular/router';
import { ToastUtils } from 'src/app/utils/toast_builder';
import routes from '../../config/routing.json';
import { IonNav, ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FarmOwnershipComponent } from 'src/app/modules/farm/farm-details/farm-ownership/farm-ownership.component';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { map, switchMap, take } from 'rxjs/operators';
import { from, lastValueFrom, of } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  LOGOUT,
  updateOwnerData,
} from 'src/app/utils/states-management/actions/owner.action';
import { clone, cloneDeep } from 'lodash';
import { PhotoService, PhotoTaken } from 'src/app/services/photo.service';
import { PhotoEditorComponent } from './profile-photo/photo-editor/photo-editor.component';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { UpdateAccessWidgetComponent } from 'src/app/widgets/update-access-widget/update-access-widget.component';
import { loadAgriProduct } from 'src/app/utils/states-management/actions/agri-product.action';
import { Geolocation } from '@capacitor/geolocation';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss'],
  providers: [],
})
export class PersonalDataComponent implements OnInit {
  customPickerOptions: any;
  image_path;

  selectedLangage;
  genders = [];
  owner: Owner;
  disabled_submit = false;
  currentYear;
  dateString;
  isDashboardView;
  formConfig;
  todayDate;
  base_url = environment.appRoot;

  @Input() nav: IonNav;
  @ViewChild(FarmOwnershipComponent) farmOwnership: FarmOwnershipComponent;
  @ViewChild(UpdateAccessWidgetComponent)
  updateAccessWidget: UpdateAccessWidgetComponent;
  constructor(
    private store: Store<{ owner: Owner }>,
    public router: Router,
    private ownerAPI: OwnerAPI,
    public translateService: TranslateService,
    private customPicker: PickerOptionFormater,
    private toastUtils: ToastUtils,
    private routingConfig: RoutingConfig,
    public photoService: PhotoService,
    private modalController: ModalController,
    private sanitizer: DomSanitizer,
    private platform: Platform
  ) {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    // this.todayDate = `${year}-${month}-${day}`;
    // this.birth_date = this.todayDate;
    // this.todayDate = this.birth_date
    // this.todayDate = new Date().toISOString();
    this.owner = new Owner();

    this.owner.first_name = '';
    this.owner.last_name = '';
    this.owner.sex = '';
  }
  user;
  file;
  ownerSubscribe;
  birth_date: string = new Date().toISOString();
  is_homeComponent;
  async ngOnInit() {
    this.user = await lastValueFrom(this.store.select('owner').pipe(take(1)));
    if (this.user.file?.path) {
      this.image_path = this.base_url + this.user.file.path;
    } else {
      this.image_path = 'assets/photo-placeholder.svg';
    }
    this.isDashboardView = this.router.url === '/' + routes.dashboard.profile;
    let ClassName = 'PersonalDataComponent';
    this.formConfig = (
      await this.routingConfig.getPageFormConfig(ClassName)
    )?.fields;

    this.currentYear = new Date();
    this.dateString = new Date(
      this.currentYear.getTime() - this.currentYear.getTimezoneOffset() * 60000
    );
    this.dateString = this.dateString.toISOString().split('T')[0];
    await this.translate();
    this.translateService.onLangChange.subscribe(async (lang) => {
      await this.translate();
    });

    this.ownerSubscribe = this.store
      .select('owner')
      .subscribe((value: Owner) => {
        if (value && value?.id) {
          this.owner = cloneDeep(value);
          this.birth_date = this.owner.birth_date;
          this.todayDate =
            this.birth_date[0] != '0'
              ? new Date(this.birth_date).toISOString()
              : new Date().toISOString();
        }
      });
    this.is_homeComponent = (await this.nav?.getActive())?.nav ? true : false;
  }
  dateSelected(event: any) {
    this.birth_date = event.detail.value;
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    // this.ownerSubscribe.unsubscribe();
  }
  webviewPath;
  async checkPermissions() {
    try {
      const permission = await Camera.checkPermissions();
      console.log(permission)
      if (permission.camera === 'granted' && permission.photos === 'granted') {
        return true;
      }

      const request = await Camera.requestPermissions({
        permissions: ['camera', 'photos'],
      });
      console.log(request)

      return request.camera === 'granted' && request.photos === 'granted';
    } catch (error) {
      console.error('Permission check error:', error);
      return false;
    }
  }

  async addPhotoToGallery() {
    await this.photoService
      .addNewToGallery()
      .then(async (value: PhotoTaken) => {
        const blob = await fetch(value?.webviewPath).then((response) =>
          response.blob()
        );
        if (value.webviewPath) {
          await this.showModificationModal(
            this.sanitizer.bypassSecurityTrustResourceUrl(value?.webviewPath)
          );
        }
      });
    // try {
    //   const hasPermission = await this.checkPermissions();

    //   if (!hasPermission) {
    //     // You might want to show an alert to the user
    //     console.error('Camera permissions not granted');
    //     return;
    //   }

    //   const image = await Camera.getPhoto({
    //     quality: 90,
    //     allowEditing: true,
    //     resultType: CameraResultType.Base64,
    //     source: CameraSource.Prompt,
    //     width: 500,
    //     height: 500,
    //     correctOrientation: true
    //   });

    //   if (image.base64String) {
    //     this.image_path = `data:image/${image.format};base64,${image.base64String}`;
    //   }
    // } catch (error) {
    //   console.error('Error taking photo:', error);
    // }
  }
  async translate() {
    this.selectedLangage = this.translateService.currentLang;
    this.genders = [
      {
        label: await this.translateService.get('masculin').toPromise(),
        value: 'male',
      },
      {
        label: await this.translateService.get('femnin').toPromise(),
        value: 'female',
      },
      {
        label: await this.translateService.get('not_endicated').toPromise(),
        value: 'other',
      },
    ];
  }

  async submitForm() {
    this.owner = cloneDeep(this.owner);
    if (this.owner?.hasSelectedPhoto) {
      this.owner.blobFile = await fetch(this.image_path).then((result) =>
        result.blob()
      );
    }
    this.owner.file = this.owner.blobFile;
    console.log(this.owner)

    this.owner = cloneDeep(this.owner);
    if (
      !this.owner ||
      // this.birth_date == '0000-00-00' ||
      !this.owner.sex ||
      !this.owner.first_name ||
      !this.owner.last_name
    ) {
      this.toastUtils.toastBuilder(
        this.translateService.instant('label.required_input_no_html'),
        '',
        '',
        'danger'
      );

      return;
    }

    // if(this.owner.country_id != this)
    // this.updateAccessWidget.updateNumber();
    this.owner.birth_date = formatDate(new Date(this.birth_date));
    // if (!this.owner?.id) {
    //   this.ownerAPI.storeOwnerDate(this.owner).subscribe(
    //     async (value: any) => {
    //       this.owner.id = value.id;
    //       await Storage.set({
    //         key: dev.USER_KEY,
    //         value: JSON.stringify(this.owner),
    //       });
    //       await this.updateUserAuth();
    //       if (!this.isDashboardView)
    //         this.router.navigateByUrl(await this.routingConfig.getNextUrl());
    //       else
    //         this.nav.setRoot(SummaryComponent, {
    //           nav: this.nav,
    //           rootStack: PriorDashboardComponent,
    //         });
    //     },
    //     (errors: HttpErrorResponse) => {
    //       this.toastController
    //         .toastBuilder('', 'bottom', 'internal server error', 'danger')
    //         .then((value) => value?.present());
    //     }
    //   );
    // }
    //  else {

    if (
      this.owner?.mobile_number != this.updateAccessWidget?.owner?.mobile_number
    ) {
      this.updateAccessWidget?.updateNumber();
    }

    this.owner.preferred_language = await (
      await Storage.get({ key: dev.LANGAGE_KEY })
    ).value;
    this.store.dispatch(updateOwnerData({ owner: this.owner }));

    this.ownerSubscribe.unsubscribe();

    if (!this.isDashboardView) {
      // if (this.platform.is('ios') || this.platform.is('android')) {
      //   await this.checkMapPermissions();
      // }
      this.router.navigateByUrl(await this.routingConfig.getNextUrl());
    } else {
      await this.toastUtils.toastBuilder(
        this.translateService.instant('change_done'),
        null,
        null,
        'success'
      );
    }
  }
  async checkMapPermissions() {
    const permission = await Geolocation.checkPermissions();

    if (permission.location == 'granted') {
      console.log('granteed');
    } else {
      const request = await Geolocation.requestPermissions();
      if (request.location == 'granted') {
        console.log('granteed');
      } else {
        console.log('not granteed');
      }
    }
  }
  async updateUserAuth() {
    const user_auth_data = JSON.parse(
      (await Storage.get({ key: dev.USER_DATA })).value
    );
    user_auth_data.owner_id = this.owner?.id;
    await Storage.set({
      key: dev.USER_DATA,
      value: JSON.stringify(user_auth_data),
    });
  }

  async goBack() {
    //TODO verify this line
    // this.store.dispatch(setUserCountry({country:country}))

    this.store.dispatch({ type: LOGOUT, redirection_path: '/' });

    this.router.navigateByUrl(await this.routingConfig.getBackUrl());
  }
  async showModificationModal(image_path) {
    let componentProps = {
      image_path: image_path,
      modalController: this.modalController,
      cancel: await this.translateService.get('cancel').toPromise(),
      confirm: await this.translateService.get('confirm').toPromise(),
    };
    let modal = await this.toastUtils.modalBuilder(
      PhotoEditorComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper']
    );

    const { data } = await modal.onWillDismiss();
    if (data?.cancel) this.cancelar();
    if (data?.image && data?.img64String) {
      if (data.img64String.length * 2 > 10 * 2 ** 40) {
        await this.toastUtils.toastBuilder(
          '',
          'bottom',
          await this.translateService.instant('errors.img_size'),
          'danger'
        );
      } else {
        let props = {
          hasSelectedPhoto: this.owner.hasSelectedPhoto,
        };
        this.owner.hasSelectedPhoto = true
        this.image_path = data?.image;
      }
    } else {
      await modal.dismiss();
      this.owner.hasSelectedPhoto = false

    }
  }
  hasSelectedPhoto;
  async cancelar() {
    this.image_path = await Storage.get({ key: dev.FILE_KEY }).then((value) => {
      const savedPath = JSON.parse(value.value);
      if (savedPath?.path) {
        return environment.appRoot + JSON.parse(value.value).path;
      } else return 'assets/photo-placeholder.svg';
    });
    this.hasSelectedPhoto = false;
  }
}
