import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { LOCALE_ID } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { Router, NavigationEnd } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';

// Core modules
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// Modular configuration files
import { CoreModule } from './modules-config/core.module';
import { SharedModule } from './modules-config/shared.module';
import { AppServiceWorkerModule } from './modules-config/service-worker.module';
import { StateModule } from './modules-config/state.module';
import { FeatureModules } from './modules-config/feature-modules';

// Providers and declarations
import { AppProviders } from './modules-config/app.providers';
import { AppDeclarations } from './modules-config/app.declarations';

// Interceptors
import { TokenInterceptor } from './apis/token.interceptor';
import { initLangage, TranslationModule } from './modules-config/translation.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomTranslateLoader } from './utils/Service/CustomTranslateLoader';
import { ConnectionService } from './services/connection.service';
import { VersionCheckService } from './apis/version-check.service';
import { Store } from '@ngrx/store';
import { ConnectionState } from './utils/states-management/reducers/offline.reducer';

@NgModule({
  declarations: [
    AppComponent,
    ...AppDeclarations
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    AppServiceWorkerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false,
      defaultLanguage: 'en'
    }),
    // TranslationModule,
    StateModule,
    ...FeatureModules
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initLangage,
      multi: true,
      deps: [TranslateService],
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    ...AppProviders,
    ConnectionService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  private isOnline = false;
  private lastConnectionState: 'good' | 'poor' | 'offline' = 'good';

  constructor(
    private router: Router,
    connectionService: ConnectionService,
    private store: Store<{ connection: ConnectionState }>,

  ) {
    this.store.select('connection').pipe(
      distinctUntilChanged((a, b) =>
        a.isOnline === b.isOnline &&
        a.connectionQuality === b.connectionQuality
      )
    ).subscribe(({ isOnline, connectionQuality }) => {
      this.isOnline = isOnline;
      this.lastConnectionState = connectionQuality; // Track quality state
    });
    // Check for updates after major navigation events
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // Only check on main/important routes
      if (event.url.includes('/home') && this.lastConnectionState == 'poor') {
        // this.versionCheckService.InstantcheckForUpdates();
        connectionService.requestConnectionCheck();
        // connectionService.restartMonitoring();

      }



    });
  }
}