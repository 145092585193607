import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { CropperComponent } from 'angular-cropperjs';
import routes from '../../../../config/routing.json';
@Component({
  selector: 'app-photo-editor',
  templateUrl: './photo-editor.component.html',
  styleUrls: ['./photo-editor.component.scss'],
})
export class PhotoEditorComponent implements OnInit, AfterViewInit {
  @Input() image_path;
  @Input() modalController: ModalController;
  @ViewChild('angularCropper') public angularCropper: CropperComponent;
  @Input() confirm;
  @Input() cancel;
  cropperOptions = {
    dragMode: 'crop',
    aspectRatio: '1',
    autoCrop: true,
    movable: true,
    zoomable: true,
    scalable: true,
    autoCropArea: 0.8,
  };
  croppedImage = null;
  scaleValX = 1;
  scaleValY = 1;
  isDashboardView;
  constructor(private router: Router) {
    this.cropperOptions = {
      dragMode: 'crop',
      aspectRatio: '1',
      autoCrop: true,
      movable: true,
      zoomable: true,
      scalable: true,
      autoCropArea: 0.8,
    };
  }
  ngAfterViewInit(): void {
 }

  async ngOnInit() {
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
  }

  reset() {
    this.angularCropper.cropper.reset();
  }

  clear() {
    this.angularCropper.cropper.clear();
  }

  rotate() {
    this.angularCropper.cropper.rotate(90);
  }

  zoom(zoomIn: boolean) {
    let factor = zoomIn ? 0.1 : -0.1;
    this.angularCropper.cropper.zoom(factor);
  }

  scaleX() {
    this.scaleValX = this.scaleValX * -1;
    this.angularCropper.cropper.scaleX(this.scaleValX);
  }

  scaleY() {
    this.scaleValY = this.scaleValY * -1;
    this.angularCropper.cropper.scaleY(this.scaleValY);
  }

  move(x, y) {
    this.angularCropper.cropper.move(x, y);
  }

  save() {
    let croppedImgB64String: string = this.angularCropper.cropper
      .getCroppedCanvas()
      .toDataURL('image/jpeg', 100 / 100);
    this.croppedImage = croppedImgB64String;

    this.modalController.dismiss({ image: this.croppedImage , img64String : this.croppedImage });
  }

  dismiss() {
    this.modalController.dismiss({ cancel: true });
  }
}
