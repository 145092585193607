import { filter } from 'rxjs/operators';
import { DateFormatPipe } from './../../../../utils/pipes-utils/date_formatter';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { Storage } from '@capacitor/storage';
import { IonNav, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Chart, ChartData, ChartDataset } from 'chart.js';
import { StatisticsAPI } from 'src/app/apis/statistics.service';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { PopoverComponent } from 'src/app/widgets/popover/popover.component';
import { dev } from '../../../../config/offlines_keys.json';
import { CategoriesSelectionPopoverComponent } from '../categories-selection-popover/categories-selection-popover.component';
import { CurrencyPipe } from '@angular/common';
import { lastValueFrom, take } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { Store } from '@ngrx/store';
import {
  getStatisticsResult,
  getTotalsAccountingCategoriesResult,
} from 'src/app/utils/states-management/selector/dashboard.selector';
import { cloneDeep } from 'lodash';
import {
  ANIMALES_ACCOUNTING_ENTRY_CATEGORY,
  CAFE_ACCOUNTING_ENTRY_CATEGORY,
  COCOA_ACCOUNTING_ENTRY_CATEGORY,
  GRAPH_COLORS,
  OTROS_ACCOUNTING_ENTRY_CATEGORY,
} from 'src/app/utils/consts';
import { MatTabGroup } from '@angular/material/tabs';
import { AccountingCategoriesAPI } from 'src/app/apis/accounting_categories.service';
import {
  getDashboardData,
  loadTotalAccountingCategories,
} from 'src/app/utils/states-management/actions/dashboard.action';
import { RoutingConfig } from 'src/app/utils/routing-config';

@Component({
  selector: 'app-line-chart-expenses-chart',
  templateUrl: './line-chart-expenses-chart.component.html',
  styleUrls: ['./line-chart-expenses-chart.component.scss'],
})
export class LineChartExpensesChartComponent implements OnInit, AfterViewInit {
  @ViewChild('lineChart') lineChartCanvas: ElementRef;
  @ViewChild('pieCanvas') pieCanvas: ElementRef;

  @Output() handelFilterChange: EventEmitter<any> = new EventEmitter();
  @Output() handelDateChange: EventEmitter<any> = new EventEmitter();
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  currency;
  @Input() accountingDetails = null;
  @Input() nav: IonNav;

  @Input() startingDate: string = new Date(
    new Date().getFullYear(),
    0,
    1
  ).toISOString();
  @Input() endingDate: string = new Date(
    new Date().getFullYear(),
    11,
    31
  ).toISOString();
  selected_year: string = new Date().getFullYear().toString();
  lineChart: Chart;
  accounting_categories = [];
  @Input() selectedAccounting = [];
  selected_accounting_categorie: Array<number>;
  isFiltred = false;
  groupByMonthYear = 'm-Y';
  country;
  selectedChartLine;
  selected_filter = [];
  pieChart;
  filterAccordion = {
    general: true,
    selection: true,
    category: true,
  };
  hiddenChartLineList = {
    revenues: true,
    expenses: true,
    margin: false,
    cumulated: false,
    savings_accumulated: false,
    savings: false,
  };
  constructor(
    public translateService: TranslateService,
    private statisticsAPI: StatisticsAPI,
    private toastUtils: ToastUtils,
    public popoverController: PopoverController,
    private datePipe: DateFormatPipe,
    private store: Store,
    private accountingCategoriesAPI: AccountingCategoriesAPI,
    private currencyPipe: CurrencyPipe,
    private statisticsController: StatisticsAPI,
    private changeDetectorRef: ChangeDetectorRef,
    public routingConfig: RoutingConfig
  ) {
  }
  handelFilterClicked(ev) {
    this.filterAccordion = {
      general: ev,
      selection: ev,
      category: ev,
    };
  }
  crop;

  async ngOnInit() {
    this.country = await lastValueFrom(
      this.store.select(getCountry).pipe(take(1))
    );
    this.crop = await this.routingConfig.getCropFormConfig();
    this.fetchData();

    this.store.select(getStatisticsResult).subscribe((value) => {
      if (!this.accountingDetails) {
        this.accounting_categories = cloneDeep(value).filter((val) =>
          this.selectedAccounting.some((accounting) => accounting.id == val.id)
        );
      } else {
        this.accounting_categories = cloneDeep(value)
      }
      this.accounting_categories.forEach((element) => {
        element.isItemChecked = true;
      });
      if (this.accountingDetails && this.accountingDetails.id) {
        this.accountingDetails = this.accounting_categories.find(
          (val) => val.id == this.accountingDetails.id
        );
      }

      this.data();
    });
    // if(this.accountingDetails &&
    //             (this.accountingDetails?.id == CAFE_ACCOUNTING_ENTRY_CATEGORY ||
    //             this.accountingDetails.id == COCOA_ACCOUNTING_ENTRY_CATEGORY))
    //         {
    //           this.fetchByCashflowExpenses(this.startingDate, this.endingDate);
    //         }
    this.currency = this.country.currency;

    if (this.accountingDetails) {
      if (this.accountingDetails?.id == 9) {
        this.hiddenChartLineList['revenues'] = false;
        this.hiddenChartLineList['expenses'] = true;
      }
      if (this.accountingDetails?.id == 5) {
        this.hiddenChartLineList['revenues'] = false;
        this.hiddenChartLineList['expenses'] = false;
        this.hiddenChartLineList['savings'] = true;
      }
    }

    this.translateService.onLangChange.subscribe((val) => {
      this.initDate();
    });
    this.initDate();
  }
  onFilterClick(filter) {
    this.filterAccordion[filter] = !this.filterAccordion[filter];
  }
  initDate() {
    this.selected_year =
      this.datePipe.transform(
        this.startingDate,
        this.translateService.currentLang
      ) +
      ' - ' +
      this.datePipe.transform(
        this.endingDate,
        this.translateService.currentLang
      );
  }

  async ngAfterViewInit(): Promise<void> {
    // this.accounting_categories = cloneDeep(
    //   await lastValueFrom(
    //     this.store.select(getTotalsAccountingCategoriesResult).pipe(take(1))
    //   )
    // );

    // if (!this.accountingDetails) {
    //   this.setCashflowCategories();
    // } else if (this.accountingDetails) {
    //   this.fetchData();
    // }
    this.changeDetectorRef.detectChanges();
  }

  orderAccountings() {
    this.selected_filter.sort((a, b) => {
      return this.translateService
        .instant('accounting_entries.' + a.id)
        .normalize('NFD')
        .toLowerCase() ===
        this.translateService
          .instant('accounting_entries.' + b.id)
          .normalize('NFD')
          .toLowerCase()
        ? 0
        : this.translateService
          .instant('accounting_entries.' + a.id)
          .normalize('NFD')
          .toLowerCase() <
          this.translateService
            .instant('accounting_entries.' + b.id)
            .normalize('NFD')
            .toLowerCase()
          ? -1
          : 1;
    });
  }
  fetchOtrosCashflowSummary(from, to) {
    this.statisticsController
      .getMaterialProductStats(
        from,
        to,
        this.accountingDetails.id,
        this.hiddenChartLineList['revenues'] ? 1 : 0
      )
      .subscribe((value: any) => {
        this.categoryDetails = this.formatResponse(value.details);
        this.sumAccounting = value?.total;
        this.pieChartBrowser(value);
      });
  }
  fetchByCashflowExpenses(from, to) {
    this.statisticsController
      .getCashflowByExpenses(
        this.accountingDetails.id,
        from,
        to,
        this.translateService.currentLang
      )
      .subscribe((value: any) => {
        this.categoryDetails = this.formatResponse(value.details);
        this.sumAccounting = value?.total;
        this.pieChartBrowser(value);
      });
  }

  fetchData() {
    let data = {
      startDate: this.startingDate,
      endDate: this.endingDate,
      cropId: 1,
      groupBy: this.groupByMonthYear,
    };
    this.store.dispatch(getDashboardData({ data: data }));
  }

  async Check(item = null) {
    if (item != null) {
      this.accounting_categories.forEach((element) => {
        if (item.id == element.id) {
          element.isItemChecked = !element.isItemChecked;
        }
      });
    }
    this.sumAccounting = 0;

    this.accounting_categories.map((element) => {
      if (element.isItemChecked) {
        this.sumAccounting += this.hiddenChartLineList['revenues']
          ? element.income
          : element.id != 5
            ? element.expenses
            : 0;
      }
    });
    await this.data();
  }

  minusOneYear() {
    if (this.selected_year.includes('-')) {
      this.selected_year = new Date().getFullYear().toString();
    }
    this.startingDate = new Date(
      parseInt(this.selected_year) - 1,
      0,
      1
    ).toISOString();
    this.endingDate = new Date(
      parseInt(this.selected_year) - 1,
      11,
      31
    ).toISOString();
    this.selected_year = (parseInt(this.selected_year) - 1).toString();
    // this.fetchData();
  }

  plusOneYear() {
    if (this.selected_year.includes('-')) {
      this.selected_year = new Date().getFullYear().toString();
    }
    this.startingDate = new Date(
      parseInt(this.selected_year) + 1,
      0,
      1
    ).toISOString();
    this.endingDate = new Date(
      parseInt(this.selected_year) + 1,
      11,
      31
    ).toISOString();
    this.selected_year = (parseInt(this.selected_year) + 1).toString();
    // this.fetchData();
  }

  async data() {
    this.sumAccounting = 0;
    this.accounting_categories?.map((element) => {
      if (element?.isItemChecked) {
        this.sumAccounting += this.hiddenChartLineList['revenues']
          ? element?.income
          : element?.id != 5
            ? element?.expenses
            : 0;
      }
    });
    this.orderAccountings();

    this.selected_accounting_categorie = [];
    let globalDataMap = new Map();

    let accountingToFetch = [];
    if (this.accountingDetails) {
      accountingToFetch.push(this.accountingDetails);
    } else {
      accountingToFetch = this.accounting_categories.filter(
        (val) => val.isItemChecked && val.id != 5
      );
    }
    accountingToFetch.forEach((accounting) => {
      this.selected_accounting_categorie.push(accounting.id);

      accounting?.statistic_details?.forEach((detail) => {
        let monthNames;
        if (this.groupByMonthYear == 'm-Y') {
          monthNames = detail.month_names;
        } else {
          monthNames = detail.month_names.split('-')[1];
        }

        const isNewMonth = !globalDataMap.has(monthNames);
        const data = isNewMonth
          ? {
            accumulate: 0,
            expenses: 0,
            income: 0,
            margin: 0,
            month_names: monthNames,
            savings: 0,
            savings_accumulated: 0,
          }
          : globalDataMap.get(monthNames);

        if (accounting?.id !== 5) {
          data.accumulate += detail.accumulate;
        }

        data.expenses += detail.expenses;
        data.income += accounting?.id !== 9 ? detail.income : 0;
        data.margin += detail.margin;
        data.savings += detail.savings;
        data.savings_accumulated += detail.savings_accumulated;
        // globalAccumulatedMargin = data.accumulate;

        globalDataMap.set(monthNames, data);
      });
    });

    let globalData = Array.from(globalDataMap.values());
    if (this.groupByMonthYear == 'm-Y') {
      globalData.sort((a, b) => {
        const [monthA, yearA] = a.month_names.split('-');
        const [monthB, yearB] = b.month_names.split('-');

        if (yearA !== yearB) {
          return parseInt(yearB) - parseInt(yearA);
        }
        return parseInt(monthB) - parseInt(monthA);
      });
    } else {
      globalData.sort((a, b) => {
        return b.month_names - a.month_names;
      });
    }

    if (this.accountingDetails) {
      this.drawLineChart(globalData.reverse());
      this.pieChartBrowser(this.accountingDetails.statistic_accounting_details);
    } else {
      this.drawLineChart(globalData.reverse());
      this.pieChartBrowser(
        this.accounting_categories.filter((value) => value.isItemChecked)
      );
    }

    // this.statisticsAPI
    //   .fetchGlobalOverviewPerAccountingEntryCategories(
    //     this.selected_accounting_categorie,
    //     this.startingDate,
    //     this.endingDate,
    //     this.groupByMonthYear
    //   )
    //   .subscribe((value) => {
    //     console.log('drawLineChart');
    //     console.log(value);
    //     this.drawLineChart(value);
    //   });
  }
  // setCashflowCategories() {
  //   // let data = { startDate: startDate, endDate: endDate };
  //   // this.store.dispatch(
  //   //   loadTotalAccountingCategories({ data :data })
  //   // );
  //   this.store
  //     .select(getTotalsAccountingCategoriesResult)
  //     .subscribe((value) => {
  //       const allAccountings = cloneDeep(value);
  //    //       if (Array.isArray(allAccountings))
  //         this.accounting_categories = allAccountings.map((element) => {
  //           element['isItemChecked'] = true;
  //           return element;
  //         });
  //       if (!this.accounting_categories) return;
  //       this.selected_filter = [];

  //       this.sumAccounting = 0;
  //       this.accounting_categories.map((element) => {
  //         if (element.isItemChecked) {
  //           this.sumAccounting += this.hiddenChartLineList['revenues']
  //             ? element.income
  //             : element.expenses;
  //         }
  //       });
  //       this.orderAccountings();

  //       this.fetchData();
  //     });
  // }

  categoryDetails = null;
  // async fetchData() {
  //   this.selected_accounting_categorie = [];
  //   if (!this.accountingDetails && this.accounting_categories) {
  //     this.accounting_categories.forEach((item) => {
  //       if (item.isItemChecked)
  //         this.selected_accounting_categorie.push(item.id);
  //     });
  //   } else {
  //     this.selected_accounting_categorie.push(this.accountingDetails?.id);
  //   }

  //   if (this.accountingDetails) {
  //     console.log('accountingDetails = ', this.accountingDetails);
  //     this.drawLineChart(this.accountingDetails.statistic_details);
  //   }

  //   if (this.selected_accounting_categorie) {
  //     // this.statisticsAPI
  //     //   .fetchGlobalOverviewPerAccountingEntryCategories(
  //     //     this.selected_accounting_categorie,
  //     //     this.startingDate,
  //     //     this.endingDate,
  //     //     this.groupByMonthYear
  //     //   )
  //     //   .subscribe((value) => {
  //     //     console.log(value)
  //     //     this.drawLineChart(value);
  //     //   });

  //     if (!this.accountingDetails) {
  //       console.log('!this.accountingDetails');
  //       this.accountingCategoriesAPI
  //         .getTotalsAccountingCategories(this.startingDate, this.endingDate)
  //         .subscribe((values: any) => {
  //           // this.categoryDetails = values;
  //           // this.sumAccounting = value?.total;
  //           // if (this.accounting_categories || this.accountingDetails) {
  //           values.forEach((element, key) => {
  //             element.isItemChecked =
  //               this.accounting_categories[key]?.isItemChecked;
  //           });
  //           this.accounting_categories = values;
  //           this.sumAccounting = 0;
  //           this.accounting_categories.map((element) => {
  //             if (element.isItemChecked) {
  //               this.sumAccounting += this.hiddenChartLineList['revenues']
  //                 ? element.income
  //                 : element.expenses;
  //             }
  //           });
  //           this.pieChartBrowser(values.filter((value) => value.isItemChecked));
  //           // }
  //         });
  //     } else {
  //       if (

  //         (this.accountingDetails &&
  //           this.accountingDetails?.id == OTROS_ACCOUNTING_ENTRY_CATEGORY) ||
  //         this.accountingDetails?.id == ANIMALES_ACCOUNTING_ENTRY_CATEGORY
  //       ) {
  //         this.fetchOtrosCashflowSummary(this.startingDate, this.endingDate);

  //       } else if (
  //         !this.hiddenChartLineList['revenues'] &&
  //         ((this.accountingDetails &&
  //           this.accountingDetails?.id == CAFE_ACCOUNTING_ENTRY_CATEGORY) ||
  //           this.accountingDetails.id == COCOA_ACCOUNTING_ENTRY_CATEGORY)
  //       ) {
  //         this.fetchByCashflowExpenses(this.startingDate, this.endingDate);
  //       } else {

  //         let { result } =
  //           this.accountingDetails['statistic_accounting_details'][
  //             this.hiddenChartLineList['revenues'] &&
  //             this.accountingDetails?.id != 5 &&
  //             this.accountingDetails?.id != 9
  //               ? 1
  //               : 0
  //           ];
  //         this.categoryDetails = this.formatResponse(result?.details);
  //         this.sumAccounting = result?.total;
  //         this.pieChartBrowser(result);
  //       }
  //     }
  //   }
  // }

  async showPopover() {
    let message = await this.translateService
      .get('label.cumulated_formula')
      .toPromise();
    this.toastUtils.popOverBuilder(
      {
        key1: message,
      },
      PopoverComponent,
      'popover-custom'
    );
  }
  sumAccounting = 0;
  oldIndex = 0;
  handelTabChange() {
    if (this.tabGroup.selectedIndex != 0 && this.oldIndex == 0) {
      this.hiddenChartLineList['revenues'] = true;
      this.hiddenChartLineList['expenses'] = false;
      // this.isFirstTab = 1
    } else if (this.tabGroup.selectedIndex == 0) {
      this.hiddenChartLineList['revenues'] = true;
      this.hiddenChartLineList['expenses'] = true;

      // this.isFirstTab = 1
    }

    if (
      (this.accountingDetails && this.accountingDetails?.id == 5) ||
      this.accountingDetails?.id == 9
    ) {
      this.hiddenChartLineList['revenues'] = false;
      this.hiddenChartLineList['expenses'] = true;
    }

    if (this.pieChart?.canvas?.parentNode) {
      this.pieChart.canvas.parentNode.style.height = '40vh';
      this.pieChart.canvas.parentNode.style.width = '40vh';
    }
    if (this.pieChart?.canvas?.parentNode?.parentNode) {
      this.pieChart.canvas.parentNode.parentNode.style.display = 'flex';
      this.pieChart.canvas.parentNode.parentNode.style['justify-content'] =
        'center';
    }
    this.oldIndex = this.tabGroup.selectedIndex;

    this.data();
  }
  async changeLine(line) {
    // this.selectedChartLine = line;
    this.hiddenChartLineList[line] = !this.hiddenChartLineList[line];
    this.sumAccounting = 0;

    if (this.tabGroup.selectedIndex != 0) {
      if (line == 'revenues') {
        this.hiddenChartLineList['expenses'] =
          !this.hiddenChartLineList['revenues'];
      } else if (line == 'expenses') {
        this.hiddenChartLineList['revenues'] =
          !this.hiddenChartLineList['expenses'];
      }
    }

    if (this.accounting_categories) {
      this.accounting_categories.map((element) => {
        if (element.isItemChecked) {
          this.sumAccounting += this.hiddenChartLineList['revenues']
            ? element.income
            : element.expenses;
        }
      });
    }
    this.data();
  }

  selectUnselectAllOptions() {
    this.selected_accounting_categorie = [];
  }

  HandelDateChange(value) {
    this.startingDate = value.start_date;
    this.endingDate = value.end_date;
    let data = {
      startDate: this.startingDate,
      endDate: this.endingDate,
      cropId: 1,
      groupBy: this.groupByMonthYear,
    };
    this.handelDateChange.emit(data);
    this.store.dispatch(getDashboardData({ data: data }));
    this.data();
  }

  getRandomColor(i) {
    const color = GRAPH_COLORS[i];
    return color;
  }
  formatResponse(valueResponse: any) {
    const formatedValue = new Array();
    for (const [key, value] of Object.entries(valueResponse)) {
      formatedValue.push({
        id: key,
        amount: value['amount'],
        percent: parseFloat(value['percent_value']).toFixed(2),
        langKey: value['langKey'],
      });
    }
    return formatedValue;
  }

  getAmount(element, sum) {
    return !this.accountingDetails
      ? this.currencyPipe.transform(
        (
          ((this.hiddenChartLineList['revenues']
            ? element.income
            : element.expenses) *
            100) /
          sum
        ).toFixed(2),
        undefined,
        '',
        '1.2-2',
        'fr-FR'
      )
      : this.currencyPipe.transform(
        element.percent,
        undefined,
        '',
        '1.2-2',
        'fr-FR'
      );
  }

  showPieChart = false;

  pieChartBrowser(datas): void {
    let colors = [];
    let data = [];
    let labels = [];
    let sum = 0;

    // sum = accountingDetails.total;
    let dataToPie;
    if (
      this.accountingDetails &&
      this.accountingDetails?.statistic_accounting_details
    ) {
      let { result } =
        this.accountingDetails?.statistic_accounting_details[
        this.hiddenChartLineList['revenues'] &&
          this.accountingDetails?.id != 5 &&
          this.accountingDetails?.id != 9
          ? 1
          : 0
        ];
      dataToPie = this.formatResponse(result.details);

      this.categoryDetails = dataToPie;
      sum = result?.total;
      this.sumAccounting = result?.total;
    } else if (!this.accountingDetails) {
      dataToPie = datas.filter((val) => val.id != 5);
      dataToPie.map((element) => {
        sum +=
          this.hiddenChartLineList['revenues'] &&
            this.accountingDetails?.id != 5 &&
            this.accountingDetails?.id != 9
            ? element.income
            : element.expenses;
      });
    }

    if (dataToPie && dataToPie.length > 0) {
      this.showPieChart = true;
      if (this.pieChart) this.pieChart.destroy();

      dataToPie?.forEach(async (element, index) => {
        labels.push(
          this.translateService.instant(element?.langKey) +
          ' :  ' +
          (!this.accountingDetails
            ? this.currencyPipe.transform(
              (sum
                ? ((this.hiddenChartLineList['revenues']
                  ? element.income
                  : element.expenses) *
                  100) /
                sum
                : 0
              ).toFixed(2),
              undefined,
              '',
              '1.2-2',
              'fr-FR'
            )
            : this.currencyPipe.transform(
              element.percent,
              undefined,
              '',
              '1.2-2',
              'fr-FR'
            )) +
          '% ' +
          ' ; ' +
          (!this.accountingDetails
            ? this.currencyPipe.transform(
              this.hiddenChartLineList['revenues']
                ? element.income
                : element.expenses,
              undefined,
              '',
              '1.2-2',
              'fr-FR'
            )
            : this.currencyPipe.transform(
              element.amount,
              undefined,
              '',
              '1.2-2',
              'fr-FR'
            )) +
          ' ' +
          this.currency
        );
        data.push(
          parseFloat(
            !this.accountingDetails
              ? this.hiddenChartLineList['revenues']
                ? element.income
                : element.expenses
              : element.amount
          )
        );
        colors.push(this.getRandomColor(index));
      });
      labels.reverse();
      data.reverse();
      const pieLabelsLine = {
        id: 'pieLabelsLine',
        afterDraw(chart) {
          const {
            ctx,
            chartArea: { width, height },
          } = chart;

          // const cx = chart._metasets[0].data[0].x;
          // const cy = chart._metasets[0].data[0].y;

          const sum = chart.data.datasets[0].data.reduce((a, b) => a + b, 0);

          chart.data.datasets.forEach((dataset, i) => {
            chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
              if (chart.data.datasets[0].data[index] > 0) {
                const { x: a, y: b } = datapoint.tooltipPosition();

                // draw line
                const halfwidth = width / 2;
                const halfheight = height / 2;
                const x = a >= halfwidth ? a + 10 : a - 10;
                const y = b >= halfheight ? b + 20 : b - 20;

                const xLine = x >= halfwidth ? x + 30 : x - 30;
                const yLine = y >= halfheight ? y + 30 : y - 30;

                const extraLine = x >= halfwidth ? 20 : -20;

                ctx.beginPath();
                ctx.moveTo(x, y);
                ctx.arc(x, y, 2, 0, 2 * Math.PI, true);
                ctx.fill();
                ctx.moveTo(x, y);
                ctx.lineTo(xLine, yLine);
                ctx.lineTo(xLine + extraLine, yLine);
                ctx.lineWidth = 8;
                ctx.strokeStyle = dataset.backgroundColor[index];
                // ctx.strokeStyle = "black";
                ctx.stroke();

                // text
                const textWidth = ctx.measureText(
                  chart.data.labels[index]
                ).width;
                ctx.font = '12px Arial';
                // control the position
                const textXPosition = x >= halfwidth ? 'left' : 'right';
                const plusFivePx = x >= halfwidth ? 15 : -15;
                ctx.textAlign = textXPosition;
                ctx.textBaseline = 'bottom';
                ctx.fillStyle = dataset.backgroundColor[index];
                // ctx.fillStyle = "black";
                ctx.fillText(
                  ((chart.data.datasets[0].data[index] * 100) / sum).toFixed(
                    2
                  ) + '%',
                  xLine + extraLine + plusFivePx,
                  yLine
                );
              }
            });
          });
        },
      };
      this.pieChart = new Chart(this.pieCanvas.nativeElement, {
        // type: 'pie',
        type: 'doughnut',
        data: {
          labels: labels,
          datasets: [
            {
              backgroundColor: colors,
              data: data,
              hoverBackgroundColor: colors,
              hoverBorderWidth: 4,
              hoverBorderColor: ['black'],
            },
          ],
        },
        options: {
          maintainAspectRatio: true,
          layout: {
            padding: 50,
          },
          scales: {
            y: {
              display: false,
              beginAtZero: true,
              ticks: {
                display: false,
              },
              grid: {
                display: false,
              },
            },
            x: {
              display: false,
              ticks: {
                display: false,
              },
              grid: {
                display: false,
              },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: (tooltipItem) => {
                  if (tooltipItem.dataIndex % 2) return [tooltipItem.label];
                  else return tooltipItem.label;
                },
              },
            },
            legend: {
              display: false,
              // position: 'bottom',
            },
          },

          aspectRatio: 1,
        },
        // plugins: [pieLabelsLine],
      });
      if (this.pieChart.canvas.parentNode) {
        this.pieChart.canvas.parentNode.style.height = '40vh';
        this.pieChart.canvas.parentNode.style.width = '40vh';
      }
      if (this.pieChart.canvas.parentNode.parentNode) {
        this.pieChart.canvas.parentNode.parentNode.style.display = 'flex';
        this.pieChart.canvas.parentNode.parentNode.style['justify-content'] =
          'center';
      }
    }
  }

  async drawLineChart(values) {
    // values=values.reverse();
    this.lineChart?.destroy();
    if (this.lineChart) this.lineChart.destroy();
    let dataIncome = {
      label: await this.translateService.instant('income'),
      data: values?.map((element) => {
        return element.income;
      }),
      lineTension: 0,
      fill: false,
      stack: '1',
      borderColor: '#33b968',
      backgroundColor: '#33b968',
      pointBorderColor: '#33b968',
      pointBackgroundColor: '#33b968',
      order: 2,
      hidden: !this.hiddenChartLineList['revenues'],
    };
    let dataExpenses: ChartDataset = {
      label: await this.translateService.instant('expenses'),
      data: values?.map((element) => element.expenses),
      fill: false,
      stack: '1',
      borderColor: '#f08080',
      backgroundColor: '#f08080',
      pointBorderColor: '#f08080',
      pointBackgroundColor: '#f08080',
      order: 2,
      hidden: !this.hiddenChartLineList['expenses'],
    };
    let dataMargin: ChartDataset = {
      label: await this.translateService.instant('margin'),
      data: values.map((element) => element.margin),
      fill: false,
      borderColor: '#3171e0',
      backgroundColor: '#3171e0',
      pointBorderColor: '#3171e0',
      pointBackgroundColor: '#3171e0',
      order: 2,
      hidden: !this.hiddenChartLineList['margin'],
    };
    let dataCumulated: ChartDataset = {
      type: 'line',
      label: await this.translateService.instant('cumulated'),
      data: values.map((element) => element.accumulate),
      borderColor: '#9FC131',
      backgroundColor: '#9FC131',
      order: 1,
      hidden: !this.hiddenChartLineList['cumulated'],
    };
    let savingCumulated: ChartDataset = {
      data: values.map((element) => element.savings_accumulated),
      type: 'line',
      borderColor: '#a020f0',
      backgroundColor: '#a020f0',
      order: 1,
      hidden: !this.hiddenChartLineList['savings_accumulated'],
    };
    let saving: ChartDataset = {
      data: values.map((element) => element.savings),
      borderColor: '#ffc409',
      backgroundColor: '#ffc409',
      hidden: !this.hiddenChartLineList['savings'],
      type: 'line',
      order: 2,
    };
    const monthes = await this.translateService.instant('monthNames');

    const labels = values.map((element) => {
      let label: string = 'm-Y';
      if (this.groupByMonthYear == 'm-Y')
        label =
          monthes[parseInt(element?.month_names.split('-')[0]) - 1].slice(
            0,
            3
          ) +
          '-' +
          element?.month_names.split('-')[1];
      else label = element.month_names;
      return label;
    });

    let speedData: ChartData = {
      labels: labels,
      datasets: [
        dataIncome,
        dataExpenses,
        dataMargin,
        dataCumulated,
        saving,
        savingCumulated,
      ],
    };

    const zeroLinePlugin = {
      id: 'zeroLinePlugin',
      afterDraw: (chart: Chart<'line'>) => {
        const ctx = chart.ctx;
        const yAxis = chart.scales['y'];

        // Draw line only if y-axis includes 0
        if (yAxis.min <= 0 && yAxis.max >= 0) {
          const zeroLine = yAxis.getPixelForValue(0);

          ctx.save();
          ctx.beginPath();
          ctx.strokeStyle = 'black'; // Set line color to black
          ctx.lineWidth = 1; // Set line width to 2
          ctx.moveTo(chart.chartArea.left, zeroLine);
          ctx.lineTo(chart.chartArea.right, zeroLine);
          ctx.stroke();
          ctx.restore();
        }
      },
    };
    if (this.lineChart) {
      await this.lineChart?.destroy();
    }
    this.lineChart = new Chart(this.lineChartCanvas.nativeElement, {
      type: 'line',
      data: speedData,
      options: {
        scales: {
          y: {
            beginAtZero: true,

            position: 'right',
            title: {
              color: '#bdbdbd',
              text: this.country.currency,
              display: true,
              align: 'end',
              font: {
                size: 14,
                family: "'Poppins', ui-sans-serif, system-ui, '-apple-system'",
              },
            },
            ticks: {
              callback: function (value, index, values) {
                return value === 0 ? '0' : value; // Customize the label for the zero line
              },
            },
          },
          x: {
            beginAtZero: true,

            title: {
              color: '#bdbdbd',
              text: await this.translateService.instant('label.time'),
              display: true,
              align: 'end',
              font: {
                size: 14,
                family: "'Poppins', ui-sans-serif, system-ui, '-apple-system'",
              },
            },
            // grid: {
            //   color: (context: any) =>
            //     context.tick.value === 0 ? 'black' : '#e0e0e0',
            //   borderColor: '#bdbdbd', // Adjust border color if needed
            //   borderWidth: 2, // Increase the thickness of the x-axis grid line
            // },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          // ...zeroLinePlugin,
        },
        // elements: {
        //   point: {
        //     radius: 3,
        //   },
        //   line: {
        //     borderWidth: 5,
        //     borderColor: 'rgba(255, 99, 132, 1)'
        //   }
        // },
      },
      plugins: [zeroLinePlugin],
    });

    // if (this.lineChart.canvas.parentNode) {
    //   this.lineChart.canvas.parentNode.style.height = '40vh';
    //   this.lineChart.canvas.parentNode.style.width = '40vh';
    // }
  }
}
