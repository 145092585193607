import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { IonNav } from '@ionic/angular';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { MenuComponent } from '../profile/menu/menu.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit {
  @ViewChild('tabGroup') matTab: MatTabGroup;
  @ViewChild('nav') nav: IonNav;

  constructor(
    private routingConfig: RoutingConfig,
    public router:Router
  ) { }
  menuConfig
  activeTab
  titleHeader
  async ngOnInit() {
    this.menuConfig = await this.routingConfig.getMenuFormConfig()
  }
  rootPage
  async goBack() {
    this.nav.setRoot(MenuComponent, {
      nav: this.nav,
      selectedTab: this.activeTab?.params?.rootParams?.tab,
      tabs:  Object.entries(this.menuConfig)
    });
    }
  async ngAfterViewInit(): Promise<void> {
    this.menuConfig = await this.routingConfig.getMenuFormConfig();
    this.rootPage = {
      nav: this.nav,
      rootStack: MenuComponent,
      isDashboardView: true,
    };
    this.nav.setRoot(MenuComponent, {
      nav: this.nav,
      selectedTab: 0,
      tabs: Object.entries(this.menuConfig)
    });
    this.nav.ionNavDidChange.subscribe(async (val) => {
      this.activeTab = await this.nav.getActive();
      this.titleHeader = this.activeTab?.params?.title;

    });
  }
}
