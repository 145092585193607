import { TranslateService } from '@ngx-translate/core';
import { orderAlphabaticallyTitles, orderAlphabaticallyUnit } from './../../../../utils/array-filtrer';
import { cloneDeep } from 'lodash';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import Salarie, {
  SALARIES_KIND,
  SALARIES_KIND_ADMINISTRATIVOS,
} from '../../../../models/salarie.model';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../../config/offlines_keys.json';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import { ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY } from 'src/app/utils/consts';
import { UnifiedCashflowInputComponentComponent } from '../../unified-cashflow-input-component/unified-cashflow-input-component.component';
import { Store } from '@ngrx/store';
import { selectUnitsByType } from 'src/app/utils/states-management/reducers/units.reducer';
import { loadUnit, LOAD_UNITS } from 'src/app/utils/states-management/actions/units-action';
import { Unit } from 'src/app/models/Unit';
import { lastValueFrom, take } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { getTotalsAccountingCategoriesResult } from 'src/app/utils/states-management/selector/dashboard.selector';
interface FormConfig {
  is_extra_payment: boolean;
  is_social_charges: number;
  is_show_navigate_to_mensual: boolean;
}
@Component({
  selector: 'app-salary-input-modal',
  templateUrl: './salary-input-modal.component.html',
  styleUrls: ['./salary-input-modal.component.scss'],
})
export class SalaryInputModalComponent implements OnInit, OnChanges {
  @Input() salary: Salarie = new Salarie();
  @Input() cashFlow: CashFlow;
  @Input() employee_id;
  @Input() employeKind: string;
  @Input() model_required_object: string;
  @Output() navigateToMonthLySalary: EventEmitter<void> = new EventEmitter();
  @Output() salaryInserted: EventEmitter<any> = new EventEmitter();
  formConfig: FormConfig = {
    is_extra_payment: false,
    is_social_charges: 0,
    is_show_navigate_to_mensual: false,
  };
  unitSystem: Array<Unit> = [];
  paymentsTypes: any[];
  countryUnit
  selectedUnit
  constructor(private modalController: ModalController, private store: Store,
    public translateService: TranslateService,
  ) { }
  country
  async ngOnInit() {
    // this.store.dispatch({ type: LOAD_UNITS });
    this.store.select(selectUnitsByType(this.model_required_object, this.translateService)).subscribe(value => {
      this.unitSystem = value
    })
    this.country = await lastValueFrom(
      this.store.select(getCountry).pipe(take(1))
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cashFlow.accounting_entries_categories ==
      ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY
      ? (this.paymentsTypes = SALARIES_KIND_ADMINISTRATIVOS)
      : (this.paymentsTypes = SALARIES_KIND);

    if (
      this.salary.quantity_product &&
      this.salary.unit &&
      this.unitSystem.length > 0
    ) {
      this.salary.unit = parseInt(this.salary.unit.toString());
      const unit = this.unitSystem.find(
        (element) => element.id == this.salary.unit
      );
      this.salary.quantity_product =
        this.salary.quantity_product / unit.equivalent_in_metric;
    }

    if (!this.salary.id) {
      if (this.employee_id) this.salary.employee_id = this.employee_id;
      this.salary.salary_amount = 0;
      this.salary.social_charges_amount = 0;
      this.salary.working_hours = 0;
      this.salary.admin_hour = 0;
      this.salary.coffe_hour = 0;
      this.salary.total_paid = 0;
      this.salary.other_hour = 0;
      this.salary.extra = 0;
    } else {
      this.salary.social_charges_amount
        ? (this.formConfig.is_social_charges = 1)
        : (this.formConfig.is_social_charges = 0);
      this.salary.extra
        ? (this.formConfig.is_extra_payment = true)
        : (this.formConfig.is_extra_payment = false);
    }
  }

  onChangeAmount(ev) {
    this.salary.salary_amount = parseFloat(ev);
    this.pagoTotalChange();
  }

  pagoTotalChange() {
    var value = this.salary.salary_amount;
    if (isNaN(value)) {
      this.salary.total_paid = 0;
    } else {
      this.salary.total_paid = value;
    }
    this.salary.payment_type == 'PAYMENT_PER_HARVESTED_QUANTITY'
      ? (this.salary.total_paid = value * this.salary.quantity_product)
      : (this.salary.total_paid = value * this.salary.journals_number);
    this.outPutSalary();
  }

  outPutSalary() {
    this.salaryInserted.emit(this.salary);
  }
  compareWith(e1, e2) {
    return e1 == e2;
  }

  navigateToMonthly() {
    this.modalController.dismiss().then(async (value) => {
      // const accountingEntriesCategories = JSON.parse(
      //   (await Storage.get({ key: dev.CASHFLOW_CATEGORIES })).value
      // );
      const {data} = await lastValueFrom(
        this.store.select(getTotalsAccountingCategoriesResult).pipe(take(1))
      );
      let cashflow = new CashFlow();
      cashflow.date = this.cashFlow.date;
      cashflow.farm_id = this.cashFlow.farm_id;
      cashflow.is_income = false;
      cashflow.cash_flow_categorie_id = 148;
      cashflow.accounting_entries_categories =
        ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY;
      cashflow.cashflow_categories = {
        id: 148,
        is_a_loss: null,
        is_spent: null,
        title: null,
        accounting_entries_categorie_id:
          ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY,
        cash_flow_categorie_id: 142,
        cash_flow_expenses: null,
        cash_flow_sub_category: null,
        formulaire_requirement: null,
        has_food_impact: 0,
        title_en: null,
      };
      console.log(data)

      await this.modalController
        .create({
          component: UnifiedCashflowInputComponentComponent,
          cssClass: ['my-custom-modal-css', 'modal-wrapper'],
          componentProps: {
            is_salary_needed: true,
            accountingEntryCategories: data.find(
              (element) =>
                element.id == ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY
            ),
            modalController: this.modalController,
            cashflow: cashflow,
          },
          id: '1',
        })
        .then((value) => value.present());
    });
  }

  paymentChange() {
    if (!this.cashFlow.id && !this.salary.id) {
      this.cashFlow.amount = 0;
      const payment_type = this.salary.payment_type;
      this.salary = new Salarie();
      if (this.employee_id) this.salary.employee_id = this.employee_id;
      this.salary.salary_amount = 0;
      this.salary.social_charges_amount = 0;
      this.salary.working_hours = 0;
      this.salary.admin_hour = 0;
      this.salary.coffe_hour = 0;
      this.salary.total_paid = 0;
      this.salary.other_hour = 0;
      this.salary.payment_type = payment_type;
      this.salary.journals_number = 0;
      this.outPutSalary();
    }
  }
  setQuantity(event) {
    this.salary.quantity_product = event;
    this.salary.payment_type == 'PAYMENT_PER_HARVESTED_QUANTITY'
      ? (this.salary.total_paid =
        this.salary.salary_amount * this.salary.quantity_product)
      : null;
    this.outPutSalary();
  }

  filterUnit() {
    this.unitSystem = this.countryUnit;
    // if (
    //   Array.isArray(this.countryUnit) &&
    //   this.current_required_form_json &&
    //   this.current_required_form_json.external_model_required &&
    //   this.current_required_form_json.external_model_required.units
    // ) {
    //   this.unitSystem = this.countryUnit.filter((element) =>
    //     this.current_required_form_json.external_model_required.units.includes(
    //       element.type
    //     )
    //   );
    //   if (this.unitSystem.length === 1) {
    //     this.cashFlow.unity = this.unitSystem[0].id;
    //     this.cashFlowChange.emit(this.cashFlow);
    //     this.quantity_by_unit_only = true;
    //   } else this.quantity_by_unit_only = false;
    // }
  }
}
