import { GoodsModule } from '../modules/goods/goods.module';
import { FamilyModule } from '../modules/family/family.module';
import { FarmModule } from '../modules/farm/farm.module';
import { TeamModule } from '../modules/team/team.module';
import { DebtsModule } from '../modules/debts/debts.module';
import { FooterModule } from '../widgets/footer-component/header-footer.module';
import { TermsModalPageModule } from '../components/terms-modal/terms-modal.module';
import { CashflowModule } from '../modules/cashflow/cashflow.module';
import { CommunityModule } from '../modules/community/community.module';
import { ProgrammesInfoModule } from '../modules/programmes-Info/programmes-Info.module';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { NgChartsModule } from 'ng2-charts';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { FormlyModule } from '@ngx-formly/core';
import { EmptyListModule } from '../widgets/empty-list/empty-list.module';

export const FeatureModules = [
  GoodsModule,
  FamilyModule,
  FarmModule,
  TeamModule,
  DebtsModule,
  FooterModule,
  TermsModalPageModule,
  CashflowModule,
  CommunityModule,
  ProgrammesInfoModule,
  EmptyListModule,
  BackButtonDisableModule.forRoot(),
  NgChartsModule,
  AngularCropperjsModule,
  FormlyModule.forRoot()
];