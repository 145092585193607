import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { Network } from '@capacitor/network';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
    updateConnectionStatus,
    syncData
} from '../utils/states-management/actions/offline.action';

interface ConnectionMetrics {
    latency: number;
    retryCount: number;
    offlineDuration: number;
}

@Injectable({
    providedIn: 'root'
})
export class ConnectionService {
    private connectionQualityChecker: any;
    private _reconnectionTimer: any = null;
    private connectionRetryCount = 0;
    private maxRetryAttempts = 3;
    private retryDelay = 5000;
    private goodConnectionCount = 0;
    private requiredGoodConnections = 3;
    private lastConnectionQuality: 'good' | 'poor' | 'offline' = 'good';
    private checkRequested$ = new Subject<void>();
    private consecutiveChecks = 0;
    private requiredConsecutiveChecks = 2;
    private baseCheckInterval = 10000;

    private _isOnline = new BehaviorSubject<boolean>(true);
    private _connectionQuality = new BehaviorSubject<'good' | 'poor' | 'offline'>('good');

    public isOnline$ = this._isOnline.asObservable();
    public connectionQuality$ = this._connectionQuality.asObservable();
    private offlineStartTime: number | null = null;
    private networkListener: any;

    private poorQualityAttempts = 0;
    private maxPoorQualityAttempts = 5;

    private connectionMetrics: ConnectionMetrics = {
        latency: 0,
        retryCount: 0,
        offlineDuration: 0
    };

    constructor(
        private store: Store<any>,
        private http: HttpClient
    ) {
        this.initNetworkListeners();
        this.checkRequested$.subscribe(() => {
            // this.checkConnectionQuality();
            this.startConnectionQualityMonitoring();

        });
    }

    private updateConnectionMetrics(latency?: number): void {
        if (latency !== undefined) {
            this.connectionMetrics.latency = latency;
        }
        this.connectionMetrics.retryCount = this.connectionRetryCount;
        this.connectionMetrics.offlineDuration = this.offlineStartTime
            ? Date.now() - this.offlineStartTime
            : 0;
    }

    private initNetworkListeners(): void {
        const safeNetworkCheck = async () => {
            try {
                const status = await Network.getStatus();
                this.updateConnectionState(status.connected, status.connected ? 'good' : 'offline');
                if (status.connected && !this.connectionQualityChecker) {
                    this.startConnectionQualityMonitoring();
                }
            } catch (error) {
                console.error('Network status check failed:', error);
                this.updateConnectionState(false, 'offline');
            }
        };

        safeNetworkCheck();

        this.networkListener = Network.addListener('networkStatusChange', (status) => {
            try {
                console.log(`Network status changed: connected=${status.connected}`);
                this.updateConnectionState(status.connected, status.connected ? 'good' : 'offline');

                if (status.connected) {
                    this.restartMonitoring();
                } else {
                    this.stopConnectionQualityMonitoring();
                }
            } catch (error) {
                console.error('Network status handler error:', error);
                this.updateConnectionState(false, 'offline');
            }
        });
    }

    public requestConnectionCheck(): void {
        this.checkRequested$.next();
    }

    private updateConnectionState(isOnline: boolean, quality?: 'good' | 'poor' | 'offline'): void {
        const connectionQuality = quality || (isOnline ? 'good' : 'offline');

        if (this._isOnline.value === isOnline && this._connectionQuality.value === connectionQuality) {
            return;
        }

        this._isOnline.next(isOnline);
        this._connectionQuality.next(connectionQuality);
        this.store.dispatch(updateConnectionStatus({ isOnline, connectionQuality }));

        if (isOnline && this.lastConnectionQuality === 'offline') {
            console.log('Connection restored, restarting monitoring...');
            this.restartMonitoring();
        }

        this.lastConnectionQuality = connectionQuality;
    }

    private startConnectionQualityMonitoring(): void {
        this.stopConnectionQualityMonitoring();
        this.goodConnectionCount = 0;
        this.connectionRetryCount = 0;
        this.consecutiveChecks = 0;

        if (this.connectionQualityChecker) {
            console.log('[Connection] Monitoring already active at interval:', this.baseCheckInterval);
            return;
        } else {
            this.checkConnectionQuality();
        }

        console.log('[Connection] Starting quality monitoring with interval:', this.baseCheckInterval);
        this.connectionQualityChecker = setInterval(() => {
            console.log('[Connection] Periodic quality check triggered');
            this.checkConnectionQuality();
        }, this.baseCheckInterval);
    }

    private handlePoorConnection(): void {
        console.log(`[Connection] Poor connection attempt ${this.poorQualityAttempts}/${this.maxPoorQualityAttempts}`);
        this.goodConnectionCount = 0;

        if (this.poorQualityAttempts >= this.maxPoorQualityAttempts) {
            console.log('[Connection] # Max poor quality attempts reached - stopping monitoring ! #');
            this.stopConnectionQualityMonitoring();
            return;
        }
        this.poorQualityAttempts++;
        this.consecutiveChecks = 0;
    }

    private handleGoodConnection(): void {
        console.log(`[Connection] Good connection detected (${this.goodConnectionCount + 1}/${this.requiredGoodConnections})`);

        this.poorQualityAttempts = 0;
        this.connectionRetryCount = 0;

        if (this.goodConnectionCount < this.requiredGoodConnections) {
            this.goodConnectionCount++;
        }

        if (this.goodConnectionCount >= this.requiredGoodConnections) {
            console.log('[Connection] Required good connections reached - checking sync eligibility');
            this.shouldSyncData().subscribe(shouldSync => {
                if (shouldSync) {
                    console.log('[Connection] Starting data sync process');
                    this.store.dispatch(syncData());
                    this.goodConnectionCount = 0;
                }
            });
            this.stopConnectionQualityMonitoring();
        }
    }

    public restartMonitoring(): void {
        this.poorQualityAttempts = 0;
        this.connectionRetryCount = 0;
        this.offlineStartTime = null;
        this.stopConnectionQualityMonitoring();

        if (!this.connectionQualityChecker) {
            this.startConnectionQualityMonitoring();
        }
    }

    checkConnectionQuality(): void {
        console.log('[Connection] Starting quality check', {
            retryCount: this.connectionRetryCount,
            lastLatency: this.connectionMetrics.latency
        });
        Network.getStatus().then((status) => {
            if (!status.connected) {
                console.log('[Connection] Offline detected during quality check');
                this.updateConnectionState(false, 'offline');
                this.stopConnectionQualityMonitoring();
                this.scheduleReconnection();
                return;
            }

            const startTime = Date.now();
            this.http.get('https://speed.cloudflare.com/__down?bytes=100000', {
                observe: 'response',
                responseType: 'text'
            }).pipe(take(1)).subscribe({
                next: (response) => {
                    const latency = Date.now() - startTime;
                    console.log(`[Connection] Test response received (${latency}ms)`);

                    let connectionQuality: 'good' | 'poor' | 'offline' = 'offline';
                    if (response.status === 200) {
                        connectionQuality = latency < 2000 ? 'good' : latency < 5000 ? 'poor' : 'offline';
                    }

                    console.log(`[Connection] Quality assessment: ${connectionQuality.toUpperCase()}`, {
                        status: response.status,
                        latency,
                        consecutiveChecks: this.consecutiveChecks
                    });

                    if (connectionQuality === 'good') {
                        this.consecutiveChecks++;
                        if (this.consecutiveChecks >= this.requiredConsecutiveChecks) {
                            console.log('[Connection] Consecutive good checks reached');
                            this.handleGoodConnection();
                        }
                        this.updateConnectionState(true, connectionQuality);

                    } else if (connectionQuality === 'poor') {
                        this.handlePoorConnection();
                        this.updateConnectionState(false, connectionQuality);

                    } else {
                        console.log('[Connection] Connection test failed');
                        this.handleConnectionFailure();
                        this.updateConnectionState(false, connectionQuality);

                    }

                },
                error: () => {
                    console.log('[Connection] Connection test error');
                    this.handleConnectionFailure();
                }
            });

        }).catch(() => {
            console.log('[Connection] Network status check failed');
            this.updateConnectionState(false, 'offline');
        });
    }

    private handleConnectionFailure(): void {
        console.log('[Connection] Handling connection failure', {
            retryCount: this.connectionRetryCount,
            maxAttempts: this.maxRetryAttempts
        });

        this.connectionRetryCount++;
        this.goodConnectionCount = 0;
        this.retryDelay = this.calculateRetryDelay();

        console.log(`[Connection] New retry delay: ${this.retryDelay}ms`);
        this.updateConnectionMetrics();

        if (this.connectionRetryCount === 1) {
            console.log('[Connection] Starting offline duration timer');
            this.offlineStartTime = Date.now();
        }

        const offlineDuration = this.offlineStartTime ? Date.now() - this.offlineStartTime : 0;
        if (offlineDuration > 120000) {
            console.log('[Connection] Extended offline duration detected (2+ minutes)');
            this.stopConnectionQualityMonitoring();
            return;
        }

        if (this.connectionRetryCount <= this.maxRetryAttempts) {
            console.log(`[Connection] Scheduling retry ${this.connectionRetryCount}/${this.maxRetryAttempts}`);
            this.scheduleReconnection();
        } else {
            console.log('[Connection] Maximum retry attempts reached');
            this.connectionRetryCount = 0;
            this.stopConnectionQualityMonitoring();
        }
    }

    stopConnectionQualityMonitoring(): void {
        if (this.connectionQualityChecker) {
            console.log('[Connection] Stopping quality monitoring');
            clearInterval(this.connectionQualityChecker);
            this.connectionQualityChecker = null;
        } else {
            console.log('[Connection] Stop requested but no monitoring active');
        }
    }

    private scheduleReconnection(): void {
        console.log('[Connection] Scheduling reconnection attempt', {
            delay: this.retryDelay,
            nextAttempt: new Date(Date.now() + this.retryDelay).toISOString()
        });

        if (this._reconnectionTimer) {
            console.log('[Connection] Clearing existing reconnection timer');
            clearTimeout(this._reconnectionTimer);
        }

        this._reconnectionTimer = setTimeout(() => {
            console.log('[Connection] Executing scheduled reconnection check');
            Network.getStatus().then(status => {
                if (status.connected) {
                    console.log('[Connection] Network restored - restarting monitoring');
                    this.startConnectionQualityMonitoring();
                }
            });
            this._reconnectionTimer = null;
        }, this.retryDelay);
    }

    private shouldSyncData(): Observable<boolean> {
        return this.store.select(state =>
            state.connection?.entities ? Object.keys(state.connection.entities).length > 0 : false
        ).pipe(take(1));
    }

    //  stopConnectionQualityMonitoring(): void {
    //     if (this.connectionQualityChecker) {
    //         clearInterval(this.connectionQualityChecker);
    //         this.connectionQualityChecker = null;
    //         console.log('Monitoring stopped');
    //     }
    // }

    private cleanupResources(): void {
        this.stopConnectionQualityMonitoring();
        if (this.networkListener) {
            this.networkListener.remove();
            this.networkListener = null;
        }
        if (this._reconnectionTimer) {
            clearTimeout(this._reconnectionTimer);
            this._reconnectionTimer = null;
        }
    }
    private calculateRetryDelay(): number {
        return Math.min(30000, 5000 * Math.pow(2, this.connectionRetryCount - 1));
    }
    public ngOnDestroy(): void {
        this.cleanupResources();
        this._isOnline.complete();
        this._connectionQuality.complete();
        this.checkRequested$.complete();
    }
}

