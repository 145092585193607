import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IonInput, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { environment } from 'src/environments/environment';
import { PopoverComponent } from '../popover/popover.component';
import { lastValueFrom, take } from 'rxjs';
import {
  connectionStatus,
  isAppSyncData,
} from 'src/app/utils/states-management/reducers/offline.reducer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss'],
})
export class ItemListComponent implements OnInit {
  @Input('props') props: ItemListInput;
  @Output() handelUpdateClicked: EventEmitter<void> = new EventEmitter();
  @Output() handelDeleteClicked: EventEmitter<any> = new EventEmitter();
  @Output() handelDeleteItemClicked: EventEmitter<any> = new EventEmitter();
  @Output() handelElementSelected: EventEmitter<void> = new EventEmitter();
  @Output() handelElementChecked: EventEmitter<void> = new EventEmitter();
  @Output() handelTitleChangeEvent: EventEmitter<string> = new EventEmitter();
  @Output() handelUpdateTeamMemberChecked: EventEmitter<any> =
    new EventEmitter();
  @ViewChild('otherInput') otherInput: any;

  base_url = 'assets';
  itemTitle;
  isItemChecked;
  constructor(
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    public popover: PopoverController,
    public store: Store
  ) {}
  isOnline = false;
  isAppSyncData = false;
  async ngOnInit() {
    if (this.props.isAdmin) this.isAdmin = this.props.isAdmin;
    if (this.props.isReceivingSalary)
      this.isReceivingSalary = this.props.isReceivingSalary;

    this.isOnline = await lastValueFrom(
      this.store.select(connectionStatus).pipe(take(1))
    );

    this.isAppSyncData = await lastValueFrom(
      this.store.select(isAppSyncData).pipe(take(1))
    );
  }
  handelTitleChange() {
    this.handelTitleChangeEvent.emit(this.itemTitle);
  }

  handelDelete(item: any) {
    this.handelDeleteClicked.emit({ item: item });
  }
  handelDeleteItem(item: any) {
    this.handelDeleteItemClicked.emit({ item: item });
  }
  handelItemChecked(item) {
    item.is_checked = item.is_checked;
  }

  handelUpdate() {
    this.handelUpdateClicked.emit();
  }
  handelSubItemChecked(item, sub_list) {

    if (item?.is_checked == true && item?.parent_id && item?.is_cropped == false) {
      sub_list.find((val) => val?.is_cropped).is_checked = true;
    }

    if (item?.is_checked == false && item?.parent_id && item?.is_cropped ==true) {
      sub_list.forEach((element) => {
        element.is_checked = false;
      });
    }
  }

  isAdmin;
  isReceivingSalary;
  handelUpdateTeamMember(key) {
    this[key] = !this[key];
    this.handelUpdateTeamMemberChecked.emit({
      isReceivingSalary: this.isReceivingSalary,
      isAdmin: this.isAdmin,
    });
  }
  handelSelected(props, clicked = false) {
    if (props.canChangeTitle) {
      this.otherInput?.nativeElement?.focus();
    }

    if (
      (props.enableSelection && !props.canChangeTitle) ||
      (props.enableSelection && props.canChangeTitle && !props.is_checked)
    ) {
      this.handelElementSelected.emit();
    }
  }
  closeSelection() {
    this.props.is_checked = false;
    // this.handelElementSelected.emit();
  }
  counter(i: number) {
    return new Array(i);
  }
  async showPopOver() {
    let componentProps = {
      key1: await this.translateService.instant('offline'),
    };
    await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverComponent,
      'popover-custom'
    );
  }
}

export class ItemListInput {
  hasUpdate?: boolean | false;
  hasDelete?: boolean | false;
  hasSubItemDelete?: boolean | false;
  canCheck?: boolean | false;
  canChangeTitle?: boolean | false;
  enableSelection?: boolean | false;
  enableChildCheck?: boolean | false;
  icon?: string | null;
  item_title?: string;
  item_sub_title?: string | null;
  sub_list?: Array<any> | null;
  is_checked?: boolean;
  show_children?: boolean | false;
  calledFrom?: string | null;
  isReceivingSalary?: boolean | false;
  isAdmin?: boolean | false;
  programStars?: any;
  id?: any;
}
