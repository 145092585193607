import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FooterModule } from '../../widgets/footer-component/header-footer.module';
import { TeamRoutingModule } from './team-routing.module';
import { TeamPermanenteComponent } from './team-permanente/team-permanente.component';
import { TeamTemporelComponent } from './team-temprel/team-temporel.component';
import { FooterTeamComponent } from './footer-team/footer-team.component';

import { MatStepperModule } from '@angular/material/stepper';

import { TranslateModule } from '@ngx-translate/core';
import { TeamMainComponent } from './team-main.component';
import { TeamMainViewComponent } from './team-main-view/team-main-view.component';
import { TeamFamilyComponent } from './team-family/team-family.component';
import { QuestionInterfaceTeamComponent } from './team-interface-team/team-interface-team.component';
import { TeamModalPage } from './team-modal/team-modal.page';
import { TeamInterfaceLiteComponent } from './team-interface-lite/team-interface-lite.component';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    FooterModule,
    TeamRoutingModule,
    MatStepperModule,
    MatTabsModule,
    TranslateModule.forChild(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    TeamPermanenteComponent,
    TeamTemporelComponent,
    FooterTeamComponent,
    TeamMainComponent,
    TeamMainViewComponent,
    TeamFamilyComponent,
    QuestionInterfaceTeamComponent,
    TeamModalPage,
    TeamInterfaceLiteComponent
  ],
  exports: [
    TeamRoutingModule,
    TeamPermanenteComponent,
    TeamTemporelComponent,
    FooterTeamComponent,
    TeamMainComponent,
    TeamMainViewComponent,
    TeamFamilyComponent,
    QuestionInterfaceTeamComponent,
    TeamModalPage,
  ],
})
export class TeamModule {}
