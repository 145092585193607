import { RoutingConfig } from 'src/app/utils/routing-config';
import { Network } from '@capacitor/network';
import {
  updateConnectionStatus,
  syncData,
} from './utils/states-management/actions/offline.action';
import { ToastUtils } from './utils/toast_builder';
import { Router, RouterEvent } from '@angular/router';
import {
  Component,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
  OnInit,
} from '@angular/core';
import { handleResponse } from './utils/user_data_storage';
import { TranslateService } from '@ngx-translate/core';
import { Capacitor, PluginListenerHandle, Plugins } from '@capacitor/core';
import { PopoverComponent } from './widgets/popover/popover.component';
import { AnimationUtils } from './utils/animations';
import { lastValueFrom, Observable, take, throwError } from 'rxjs';
import Owner from './models/owner.model';
import { Store } from '@ngrx/store';
import { register } from 'swiper/element/bundle';
import { Location } from '@angular/common';
import { ConnectionService } from './services/connection.service';
import {
  getOwnerById,
} from './utils/states-management/actions/owner.action';

const { App } = Plugins;
register();
import routes from './config/routing.json';
import {
  AlertController,
  IonTabs,
  Platform,
  PopoverController,
} from '@ionic/angular';
import { SwUpdate } from '@angular/service-worker';
import { LoginService } from './apis/login.service';
import { VersionCheckService } from './apis/version-check.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { of, timer } from 'rxjs';
import { catchError, delay, map, retryWhen, switchMap } from 'rxjs/operators';
import { error } from 'console';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  @ViewChild('container', { read: ViewContainerRef })
  container: ViewContainerRef;
  @ViewChild(IonTabs) ionTabs: IonTabs;

  steps: { template: string; title: string }[];
  animation;
  selectedStep: number = 0;
  isSecondBackClicked = false;
  device_info;
  loadingModal;
  isHomeView;
  routing;
  showLandingHeader = false;
  isOnline = true;
  // Update the appSyncState to include connection quality
  appSyncState = {
    isSyncDone: true,
    isSyncOnProgress: false,
    operation: {},
    isOnline: true,
    showOfflineStatus: false,
    connectionQuality: 'good' as 'good' | 'poor' | 'offline',
  };
  isMainAnalysisPage: boolean = true;

  showConnectionStatus = false;
  owner: Owner;
  safeAreaEnabled = false;
  offlinePopOver;
  offlineSyncPopOver;
  unSyncOperationLength = 0;
  updateReqPopOver;
  isAppVersionChecked = false;
  private appStateListener: PluginListenerHandle;
  private visibilityChangeListener: any;

  constructor(
    private popoverController: PopoverController,
    public router: Router,
    private platform: Platform,
    private toastUtils: ToastUtils,
    private animationUtils: AnimationUtils,
    private store: Store<{ owner: Owner; connection }>,
    private translate: TranslateService,
    private routingConfig: RoutingConfig,
    private location: Location,
    private swUpdate: SwUpdate,
    private loginService: LoginService,
    private versionService: VersionCheckService,
    private alertCtrl: AlertController,
    private http: HttpClient,
    private connectionService: ConnectionService
  ) {
    if (Capacitor.getPlatform() === 'ios') {
      this.safeAreaEnabled = true;
    }

    // Subscribe to connection service for status updates
    this.connectionService.isOnline$.subscribe(isOnline => {
      this.appSyncState.isOnline = isOnline;
    });

    this.connectionService.connectionQuality$.subscribe(async quality => {
      this.appSyncState.connectionQuality = quality;
      this.appSyncState.showOfflineStatus = quality !== 'good';

      console.log(this.appSyncState.connectionQuality)
      console.log(this.appSyncState.showOfflineStatus)
      // Handle offline popover based on connection quality
      await this.handleOfflinePopover(quality);
    });

    this.setupVersionChecking();

    this.animationUtils.routerAnimation().then((value) => {
      this.animation = value;
    });

    this.router.events.subscribe(async (val: RouterEvent) => {
      this.isHomeView = this.router.url.includes(routes.dashboard.home);

      let routing = await this.routingConfig.getRoutingFilter();
      this.showLandingHeader =
        routing?.find(
          (val) => val.route.includes(this.router.url) && val.tabIndex > -1
        ) &&
          !this.router.url.includes('home') &&
          !this.router.url.includes('login') &&
          !this.router.url.includes('landing_done') &&
          this.router.url != '/'
          ? true
          : false;
    });

    this.platform.backButton.subscribeWithPriority(9999, async (event: any) => {
      if (!this.isSecondBackClicked) {
        this.isSecondBackClicked = true;
        document.addEventListener(
          'backbutton',
          async function (event) {
            event.preventDefault();
          },
          true
        );
      } else {
        App.exitApp();
      }
    });
  }

  async ngOnInit(): Promise<void> {
    // Initialize version checking at app startup

    let props = {
      key1: 'app_offline_info_sync',
      canBeClosed: false,
      is_confirm_needed: false,
      is_info_popover: false,
      is_loader_needed: true,
    };

    this.store.select('connection').subscribe(async (connection) => {
      if (connection?.isSyncOnProgress && !this.offlineSyncPopOver) {
        this.offlineSyncPopOver = await this.toastUtils.popOverBuilder(
          props,
          PopoverComponent,
          'popover-custom',
          null,
          false,
          true
        );
      }

      console.log(connection?.isSyncOnProgress)
      if (this.offlineSyncPopOver && !connection?.isSyncOnProgress) {
        this.offlineSyncPopOver?.dismiss();
        this.offlineSyncPopOver = null;
      }

      this.appSyncState.isOnline = connection.isOnline;
      this.appSyncState.isSyncDone = connection.isSyncDone;
      this.unSyncOperationLength = Array.from(
        Object.values(connection.entities)
      ).length;

      if (
        this.unSyncOperationLength == 0 &&
        this.appSyncState.isOnline == true
      ) {
        if (!this.isAppVersionChecked) {
          await this.checkAppVersion();
          this.isAppVersionChecked = true;
        }

        // setTimeout(() => {
        //   if (this.appSyncState.connectionQuality == 'poor') {
        //     this.appSyncState.showOfflineStatus = true;
        //   } else {
        //     this.appSyncState.showOfflineStatus = !this.appSyncState.isOnline;
        //   }
        // }, 2000);
      }
      this.appSyncState.showOfflineStatus = !this.appSyncState.isOnline;

      // else {
      //   if (this.appSyncState.connectionQuality == 'poor') {
      //     this.appSyncState.showOfflineStatus = true;
      //   } else {
      //     this.appSyncState.showOfflineStatus = !this.appSyncState.isOnline;
      //   }
      // }
    });

    this.store.dispatch(getOwnerById());
    this.routing = (
      await this.routingConfig.getConfigObject()
    )?.landing?.routingConfig;
    await this.navigate();

    // Subscribe to update notifications
    this.versionService.updateAvailable.subscribe(isAvailable => {
      if (isAvailable) {
        this.showUpdateNotification();
      }
    });
  }

  /**
   * Set up version checking at optimal times
   */
  private setupVersionChecking(): void {
    // Check for updates when app starts
    this.versionService.InstantcheckForUpdates();

    // // Set up app state listeners for mobile platforms
    // if (Capacitor.getPlatform() !== 'web') {
    //   // Listen for app resume events (when app comes back from background)
    //   this.appStateListener = App.addListener('appStateChange', ({ isActive }) => {
    //     if (isActive) {
    //       // App resumed from background - good time to check for updates
    //       this.versionService.InstantcheckForUpdates();
    //     }
    //   });
    // }
    //  else {
    //   // For web platform, check when tab becomes visible again
    //   this.visibilityChangeListener = () => {
    //     if (document.visibilityState === 'visible') {
    //       this.versionService.InstantcheckForUpdates();
    //     }
    //   };
    //   document.addEventListener('visibilitychange', this.visibilityChangeListener);
    // }
  }

  /**
   * Show update notification to user
   */
  private async showUpdateNotification(): Promise<void> {
    const updateMessage = await this.translate.instant('update_available_message');
    const updateButton = await this.translate.instant('update_now');

    const alert = await this.alertCtrl.create({
      header: await this.translate.instant('update_available'),
      message: updateMessage,
      buttons: [
        {
          text: updateButton,
          handler: () => {
            window.location.reload();
          }
        }
      ]
    });

    await alert.present();
  }

  async checkAppVersion() {
    (await this.versionService.checkVersion()).pipe(take(1)).subscribe(
      async (response: any) => {
        if (response?.update_required) {
          let CProps = {
            key1: await this.translate.instant('update_req', {
              latest_version: response.latest_version,
            }),
            canBeClosed: false,
            is_confirm_needed: true,
            confirmLink: response?.update_url,
          };
          this.updateReqPopOver = await this.popoverController.create({
            component: PopoverComponent,
            cssClass: 'popover-custom',
            animated: true,
            componentProps: CProps,
            backdropDismiss: false,
          });
          if (!this.isAppVersionChecked) {
            this?.updateReqPopOver.present();
          }

          //on bloque l'exécution du reste du code
          if (response.force_update) return;
        }
      }
    );
  }

  ngOnDestroy() {
    // Clean up version checking listeners
    if (this.appStateListener) {
      this.appStateListener.remove();
    }

    if (this.visibilityChangeListener) {
      document.removeEventListener('visibilitychange', this.visibilityChangeListener);
    }

    // Connection service will handle its own cleanup
  }

  async navigate() {
    this.platform.ready().then(async () => {
      this.store
        .select('owner')
        .pipe(take(1))
        .subscribe({
          next: async (owner: any) => {
            this.owner = owner;

            if (!owner?.country_id) {
              return;
            } else {
              // this.store.dispatch(setUserCountry({ country: owner.countrie }));
            }
            if (owner && owner?.checkpoint_path) {
              if (owner?.preferred_language) {
                this.translate.use(owner?.preferred_language);
              }
              if (owner?.checkpoint_path.includes('home')) {
                this.router.navigateByUrl('/home');
                await handleResponse(owner);
              } else if (owner?.checkpoint_path) {
                let componentProps = {
                  key1: await this.translate.instant('landing_creation_info'),
                  close_button: await this.translate.instant('submit'),
                };
                const popover = await this.toastUtils.popOverBuilder(
                  componentProps,
                  PopoverComponent,
                  'popover-custom'
                );

                await this.router.navigateByUrl(owner.checkpoint_path);
              }
            }
          },
        });
    });
  }

  async showPopOver() {
    let componentProps = {
      key1: await this.translate.instant('offline_notification'),
    };
    await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverComponent,
      'popover-custom'
    );
  }

  handelTabClicked = (event: MouseEvent) => {
    const { tab } = event
      .composedPath()
      ?.find(
        (element: any) => element.tagName === 'ION-TAB-BUTTON'
      ) as EventTarget & { tab: string };

    if (
      [
        'home/wallet',
        'home/book',
        'home/analysis',
        'home/profile',
        'home/menu',
      ].includes(tab) &&
      this.ionTabs.outlet.canGoBack(1, tab)
    ) {
      event.stopImmediatePropagation();
      return this.ionTabs.outlet.pop(3, tab);
    }
  };

  async initServiceWorkerLoading() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe((event) => {
        console.log(
          '[App] Update available: current version is',
          event.current,
          'available version is',
          event.available
        );

        // Trigger update notification when service worker detects an update
        this.versionService.InstantcheckForUpdates();
      });
    }
  }

  /**
   * Handle offline popover display based on connection quality and app state
   */
  private async handleOfflinePopover(connectionQuality: 'good' | 'poor' | 'offline'): Promise<void> {
    const owner = await lastValueFrom(this.store.select('owner').pipe(take(1)));

    if (
      connectionQuality === 'offline' &&
      ((owner?.id && !owner?.checkpoint_path?.includes('home')) ||
        this.router.url.includes('login')
      )
    ) {
      if (this.offlinePopOver == null) {
        let CProps = {
          key1: this.router.url.includes('login')
            ? await this.translate.instant('app_offline_info_login')
            : await this.translate.instant('app_offline_info'),
          canBeClosed: false,
          is_confirm_needed: false,
        };
        this.offlinePopOver = await this.popoverController.create({
          component: PopoverComponent,
          cssClass: 'popover-custom',
          animated: true,
          componentProps: CProps,
          backdropDismiss: false,
        });
        this.offlinePopOver.present();
      }
    } else if (this.offlinePopOver && connectionQuality === 'good') {
      this.offlinePopOver.dismiss();
      this.offlinePopOver = null;
    }
  }
}


