import { cloneDeep } from 'lodash';
import { ProductionsPerfarm } from 'src/app/models/farm/production_per_farm';
import {
  addAgriProduct,
  addAgriProductSuccess,
  deleteSelectedAgriProduct,
  loadAgriProduct,
  loadAgriProductSuccess,
  loadSelectedAgriProductSuccess,
} from '../actions/agri-product.action';
import {
  on,
  createReducer,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
export interface AgriculturalProductState extends EntityState<any> {
  selectedProducts: any[];
  loading: boolean;
  error: any;
}
export function selectProductById(a): number {
  return a.id ? a.id : 0;
}

export const agriculturalProductAdapter: EntityAdapter<any> =
  createEntityAdapter<any>({
    selectId: selectProductById,
  });

export const initialState: AgriculturalProductState =
  agriculturalProductAdapter.getInitialState({
    // additional entity state properties
    selectedProducts: [],
    loading: false,
    error: null,
  });

export const agriProductSelector =
  createFeatureSelector<AgriculturalProductState>('agriculturalProduct');
export const selectAllAgriProducts = createSelector(
  agriProductSelector,
  (state) => state
);
export const selectedAgriProduct = createSelector(
  agriProductSelector,
  (state) => state.selectedProducts
);

export const agriculturalProductReducer = createReducer(
  initialState,
  on(loadAgriProduct, (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  }),
  on(loadAgriProductSuccess, (state, action) => {
    return agriculturalProductAdapter.setAll(action.agriculturalProducts, {
      ...state,
      loading: false,
    });
  }),

  on(loadSelectedAgriProductSuccess, (state, action: any) => {
    return {
      ...state,
      loading: false,
      selectedProducts: action.selectedAgriculturalProducts,
      error: null,
    };
  }),
  on(deleteSelectedAgriProduct, (state, action) => {
    let newEntities = cloneDeep(state.entities);
    let seletedProduct = cloneDeep(state.selectedProducts);
    action.products.forEach((prod) => {
      let { morphable } = prod;

      // let categoryId = action?.product?.morphable?.category_id
      //   ? action?.product?.morphable?.category_id
      //   : action?.product?.morphable?.parent_product?.category_id;
      // console.log(action.product);
      if (
        newEntities[prod?.morphable?.category_id]?.products.findIndex(
          (val) => val.product_id == morphable.id
        ) == -1
      ) {
        let product = {
          product_id: morphable.id,
          name: morphable.name,
          is_animal: prod.is_animal,
          is_checked: false,
          is_commentable: morphable.is_commentable,
          file: morphable.file,
          percent_in_lot: 0,
          used_surface: 0,
        };
        if (prod?.morphable?.parent_product?.category_id) {
          // newEntities[action?.product?.morphable?.category_id].products
          //   .find(
          //     (val) =>
          //       val.product_id == action?.product?.morphable?.parent_product.id
          //   )
          //   .childrens.push(product);
        } else {
          newEntities[prod?.morphable?.category_id].products.push(product);
        }
      }

      seletedProduct = [
        ...seletedProduct.filter(
          (value: any) =>
            value.morphable_id != prod?.morphable_id ||
            (value?.morphable?.is_commentable &&
              value?.comment != prod?.comment)
        ),
      ];
    });

    return {
      ...state,
      loading: false,
      entities: newEntities,
      selectedProducts: seletedProduct,
      error: null,
    };
  }),

  on(addAgriProduct, (state, action: any) => {
    let newEntities = cloneDeep(state.entities);
    let newProduction = cloneDeep(action.productions);
    newProduction.map((val) => {
      if (val?.morphable?.cash_flow_categories) {
        val['cash_flow_categories'] = val?.morphable?.cash_flow_categories;
      }
      val['id'] = 'offline';
      return val;
    });

    newEntities = newProduction.reduce((entities, element) => {
      const { category_id, morphable_id } = element.morphable;

      return {
        ...entities,
        [category_id]: {
          ...entities[category_id],
          products: entities[category_id]?.products?.filter(
            (value) => value.product_id != morphable_id || value.is_commentable
          ),
        },
      };
    }, newEntities);

    var selectedProducts = state.selectedProducts.concat(newProduction);
    console.log(newEntities);
    return {
      ...state,
      loading: true,
      entities: newEntities,
      selectedProducts: selectedProducts,
      error: null,
    };
  }),
  on(addAgriProductSuccess, (state, action: any) => {
    return {
      ...state,
      loading: false,
      selectedProducts: action.productions,
      error: null,
    };
  })
);
