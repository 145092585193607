import { PopoverComponent } from 'src/app/widgets/popover/popover.component';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ToastController } from '@ionic/angular';
import { LoginService } from 'src/app/apis/login.service';
import { CountriesService } from 'src/app/apis/countries.service';
import { PopoverController } from '@ionic/angular';
import { Storage } from '@capacitor/storage';
import { dev } from '../../config/offlines_keys.json';
import { FormControl, Validators } from '@angular/forms';
import { PopoverImgComponent } from 'src/app/widgets/popover-img/popover-img.component';
import { mapLangeNameObjects } from 'src/app/utils/array-filtrer';
import { ExistingAccountsComponent } from './existing-accounts/existing-accounts.component';
import { TranslateService } from '@ngx-translate/core';
import { IntroductoryvideoComponent } from '../introductoryvideo/introductoryvideo.component';
import AppRoutes from '../../config/routing.json';
import { Store } from '@ngrx/store';
import {
  loadCountries,
  setUserCountry,
} from 'src/app/utils/states-management/actions/countries.action';
import { lastValueFrom, take } from 'rxjs';
import {
  getAllCounties,
  getCountry,
} from 'src/app/utils/states-management/selector/countries.selector';
import { cloneDeep } from 'lodash';
import {
  LOGOUT,
  logOut,
} from 'src/app/utils/states-management/actions/owner.action';
import { UserInfo } from 'src/app/apis/users-info.service';
import { environment } from 'src/environments/environment';
import { loadFarm } from 'src/app/utils/states-management/actions/farm.action';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  countries = <any>[];
  mobile_number = new FormControl('', [
    Validators.required,
    Validators.minLength(5),
    // Validators.maxLength(5),
  ]);
  code: any;
  current_lang;

  cancel;
  login_label;
  prefix;
  countryName;
  cell_phone_number;
  get_into;
  create_an_account;
  oldCode;
  showForm = false;
  base_endpoint = environment.appRoot;
  loadingModal;

  constructor(
    public LoginService: LoginService,
    public CountriesService: CountriesService,
    public router: Router,
    public toastController: ToastController,
    public popoverController: PopoverController,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private store: Store,
    private loadingCtrl: LoadingController
  ) {}

  async presentToast(msg) {
    this.toastUtils.toastBuilder('', '', msg, '');
  }

  async ngOnInit() {
    this.current_lang = this.translateService.currentLang;
    this.loadingModal = await this.loadingCtrl.create({
      spinner: 'bubbles',
      mode: 'ios',
    });
    this.countries = await lastValueFrom(
      this.store.select(getAllCounties).pipe(take(1))
    );
    this.countryName = await lastValueFrom(
      this.store.select(getCountry).pipe(take(1))
    );
    if (this.countryName?.indicatif) {
      this.code = this.countryName.indicatif;
    }
    this.store
      .select(getAllCounties)
      .pipe(take(2))
      .subscribe(async (value) => {
        if (value && value.length > 0)
          this.countries = await mapLangeNameObjects(
            cloneDeep(value),
            this.current_lang
          );
      });

    Storage.set({ key: `offline`, value: '[]' });
  }

  async resetLocalStorage() {
    let lang = this.translateService.currentLang;
    await Storage.clear();
    await Storage.set({
      key: dev.LANGAGE_KEY,
      value: lang,
    });
    localStorage.setItem('lang', lang);
  }

  async handelChangeCountrie(ev) {
    let componentProps = { data: this.countries, component: 'login' };
    const popover = await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverImgComponent,
      'pop-over-style-login-prefix',
      ev
    );

    const { data } = await popover.onDidDismiss();
    if (data && data.data) {
      this.code = data.data.indicatif;
      this.countryName = data.data;
    }
  }
  connectionBtnDisable = false;
  connectionDisableTimer = 60;
  async submitForm() {
    if (!this.showForm) {
      this.showForm = true;
    } else {
      const data = new FormData();

      if (!this.mobile_number.value || !this.code) {
        this.presentToast(
          await this.translateService.instant('errors.login_error')
        );
      } else if (
        isNaN(this.mobile_number.value) ||
        this.mobile_number.value.toString().length < 5
      ) {
        this.presentToast(
          await this.translateService.instant(
            'errors.login_error_mobile_number'
          )
        );
        return;
      } else {
        this.code = this.code.toString();

        data.append('mobile_number', this.mobile_number.value);
        data.append('lang', await this.translateService.instant('sms_message'));
        data.append('code', this.code);

        this.loadingModal.present();
        this.LoginService.login(data).subscribe(async (response) => {
          await this.loadingModal.dismiss();

          if (response.status == 200) {
            // let country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
            if (response?.country_id) {
              var countries = cloneDeep(
                await lastValueFrom(
                  this.store.select(getAllCounties).pipe(take(1))
                )
              );

              this.store.dispatch(
                setUserCountry({
                  country: countries.find(
                    (val) => val.id == response?.country_id
                  ),
                })
              );
            }

            let componentProps = {
              key1: await this.translateService.instant('sendSms'),
              button_label: await this.translateService.instant('confirm'),
            };

            let popover = await this.toastUtils.popOverBuilder(
              componentProps,
              PopoverComponent,
              'popover-custom'
            );
            const { data } = await popover.onDidDismiss();
            if (data?.closed) {
              this.router.navigate([
                '/sms-validation/' +
                  this.code +
                  '/' +
                  this.mobile_number.value +
                  '/' +
                  'null',
              ]);
            }
          } else {
            if (response.error == 'errors.max_login_attempts') {
              this.connectionDisableTimer = 60;
              this.connectionBtnDisable = true;
              let interval = setInterval(() => {
                this.connectionDisableTimer--;
                if (this.connectionDisableTimer == 0) {
                  this.connectionBtnDisable = false;
                  clearInterval(interval);
                }
              }, 1000);
            }
            this.presentToast(
              await this.translateService.instant(response.error)
            );
          }
        });
      }
    }
  }

  async showExistingAccount() {
    await this.toastUtils.modalBuilder(ExistingAccountsComponent);
  }

  goBack() {
    if (this.showForm) {
      this.showForm = false;
    } else {
      this.router.navigateByUrl('/');
    }
  }

  async handelChangeCountrieName(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverImgComponent,
      cssClass: 'pop-over-style-login',
      event: ev,
      translucent: true,
      componentProps: { data: this.countries, component: 'create-account2' },
    });
    popover.present();
    const { data } = await popover.onDidDismiss();
    if (data) {
      this.code = data.data.indicatif;
      this.countryName = data.data;
    } else {
      this.countryName = '';
    }
  }

  async showvideo(ev: any) {
    // this.resetLocalStorage();
    //
    // if (this.translateService.currentLang == 'es') {
    //   const popover = await this.popoverController.create({
    //     component: IntroductoryvideoComponent,
    //     cssClass: 'pop-over-style-video-prefix',
    //     event: ev,
    //     translucent: true,
    //   });
    //   popover.present();
    //   const { data } = await popover.onDidDismiss();
    // }

    this.router.navigateByUrl('/terms');
  }
}
