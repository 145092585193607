import { TranslateService } from '@ngx-translate/core';
import {
  MANO_DE_OBRA_ADMINISTRATIVA_FAMILIAR,
  MORPH_Salarie,
} from './../../../../utils/consts';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { PopoverImgComponent } from './../../../../widgets/popover-img/popover-img.component';
import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';
import { Storage } from '@capacitor/storage';
import { EmployeesAPI } from 'src/app/apis/employees.service';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import { TeamModalPage } from 'src/app/modules/team/team-modal/team-modal.page';
import { dev } from '../../../../config/offlines_keys.json';
import { MORPH_NotApplicable } from 'src/app/utils/consts';
import { TeamFamilyComponent } from 'src/app/modules/team/team-family/team-family.component';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { lastValueFrom, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Farm } from 'src/app/models/farm/farm.model';
import { loadFarm } from 'src/app/utils/states-management/actions/farm.action';
import {
  selectEmployees,
  selectEmployeesFamilyMembers,
} from 'src/app/utils/states-management/selector/family-members.selector';
@Component({
  selector: 'app-mano-de-obra-widget',
  templateUrl: './mano-de-obra-widget.component.html',
  styleUrls: ['./mano-de-obra-widget.component.scss'],
})
export class ManoDeObraWidgetComponent implements OnInit, DoCheck {
  @Input() cashFlow: any;
  @Output() cashflowChangeOutput: EventEmitter<CashFlow> = new EventEmitter();
  @Output() navigagteToMonthlySalariesEventTrigger: EventEmitter<void> =
    new EventEmitter();
  @Input() manoKind;
  @Input() model_required_object;
  @Input() is_mensual = false;

  @Output() navigateToAnotherCashflowCategories: EventEmitter<void> =
    new EventEmitter();
  @Output() paidPerQuantityCoeshadakind: EventEmitter<any> = new EventEmitter();
  selected_employee;
  employee_is;
  temps_employee;
  permanent_employee;
  family_employee;
  farm: Farm;
  currentListEmployee;
  selected_employee_name = '';
  x = 3;
  selected_payment_type: any = 0;
  selectedSalaryList: any;
  selectedSalaryToBePaid;
  money_label_salary;
  qunatity_label_salary;
  currentCategory;
  currentExpenceCategory;
  MANO_DE_OBRA_ADMINISTRATIVA_FAMILIAR = MANO_DE_OBRA_ADMINISTRATIVA_FAMILIAR;
  constructor(
    private employeAPI: EmployeesAPI,
    private toastUtils: ToastUtils,
    public translateService: TranslateService,
    private store: Store
  ) {}

  async ngOnInit() {
    this.selectedSalaryList = this.selected_employee?.salaries;

    if (this.is_mensual) this.selected_payment_type = 3;

    this.store.dispatch(loadFarm());

    this.farm = await lastValueFrom(this.store.select(getFarm).pipe(take(1)));

    this.store.select(selectEmployees).subscribe((val) => {
      this.family_employee = cloneDeep(val);
      this.currentListEmployee = this.family_employee;
      this.employee_is = 'familiar';
      // this.setUpUi();
    });
    // console.log(this.family_employee)
    // this.family_employee.
    // this.store.dispatch(loadFarm())
    // this.store.select(selectEmployeesFamilyMembers).subscribe((value)=>{
    //   this.cashFlow.cash_flow_categorie_id ==
    //   MANO_DE_OBRA_ADMINISTRATIVA_FAMILIAR &&
    // Array.isArray(value) &&
    // this.farm.employees.length > 0
    //   ? (this.family_employee = value.employees.filter((element) => element.is_admin))
    //   : (this.family_employee = this.farm.employees);

    //   this.family_employee

    //       if (Array.isArray(this.farm.employees)) {
    //     this.permanent_employee =  this.farm.employees.filter(
    //       (element) =>
    //         element.is_permanent == 1 && element.morphable_type == null
    //     );

    //     this.temps_employee =  this.farm.employees.filter(
    //       (element) => element.is_permanent == 0
    //     );
    //   }
    // this.setUpUi();

    // })
    if (this.cashFlow.cash_flow_categorie_id)
      this.currentCategory = this.cashFlow.cash_flow_categorie_id;
    if (this.cashFlow.cash_flow_expense_id)
      this.currentExpenceCategory = this.cashFlow.cash_flow_expense_id;
    const lang = await Storage.get({ key: dev.LANGAGE_KEY }).then(
      (value) => value.value
    );
    // await this.fetchEmployee();
    // this.setUpUi();
    if (this.cashFlow.id) {
      console.log(this.cashFlow.morphable);

      if (this.cashFlow?.morphable && this.cashFlow?.morphable?.employe) {
        this.employeeSelectedChanged(this.cashFlow.morphable.employe);
        this.selected_employee_name = this.cashFlow.morphable.employe?.name;
      } else if (
        this.cashFlow?.morphable &&
        this.cashFlow?.morphable_type.includes('Salarie') &&
        this.cashFlow.cashflow_expenses_categorie.title_en.includes('Family')
      ) {
        this.employeeSelectedChanged(this.cashFlow.morphable);
        this.selected_employee_name = this.cashFlow.morphable?.name;
      } else if (
        this.cashFlow?.morphable &&
        this.cashFlow?.morphable_type.includes('Owner')
      ) {
        this.employeeSelectedChanged(this.cashFlow.morphable);
        this.selected_employee_name = this.cashFlow.morphable.first_name;
      }
    }
  }

  ngOnChanges(changes: SimpleChange): void {
    this.selectedSalaryList = this.selected_employee?.salaries;
    if (this.is_mensual) this.selected_payment_type = 3;
  }

  async salaryInsertedChanged(value) {
    this.selectedSalaryToBePaid = value;
    this.calculateSalary();
    this.cashFlow.morphable_type = 'App\\Models\\Salarie';
    this.cashFlow.morphable_id = value.id;
    this.cashFlow.salary = value;
    this.cashflowChangeOutput.emit(this.cashFlow);
  }

  calculateSalary() {
    this.cashFlow.amount = this.selectedSalaryToBePaid.total_paid;
    this.cashFlow.morphable_type = MORPH_Salarie;
    this.cashFlow.morphable_id = this.selectedSalaryToBePaid?.id;
    if (this.selected_payment_type === 1 || this.selected_payment_type === 2) {
      this.cashFlow.payment_methods = MORPH_NotApplicable;
    }
    this.cashFlowChange(this.cashFlow);
  }

  cashFlowChange(event) {
    this.cashFlow = event;
    this.cashflowChangeOutput.emit(event);
  }

  selectedSalaryChanged(value) {
    this.selectedSalaryToBePaid = value;
    this.calculateSalary();
  }

  navigateToMonthlySalaries() {
    this.navigateToAnotherCashflowCategories.emit();
  }

  ngDoCheck(): void {
    if (
      (this.currentCategory &&
        this.currentCategory != this.cashFlow.cash_flow_categorie_id) ||
      (this.currentExpenceCategory &&
        this.currentExpenceCategory != this.cashFlow.cash_flow_expense_id)
    ) {
      this.cashFlow.morphable_id = null;
      this.cashFlow.morphable_type = null;
      this.selected_employee = null;
      this.selected_employee_name = null;
      if (this.cashFlow.salary) {
        this.cashFlow.salary.salary_amount = 0;
        this.cashFlow.salary.payment_type = null;
      }
      this.currentCategory = this.cashFlow.cash_flow_categorie_id;
      this.currentExpenceCategory = this.cashFlow.cash_flow_expense_id;
    }
    this.setUpUi();
  }

  // async fetchEmployee() {

  //   this.employeAPI.getFamiliarLarbor().subscribe((value) => {
  //     console.log(value)
  //     this.cashFlow.cash_flow_categorie_id ==
  //       MANO_DE_OBRA_ADMINISTRATIVA_FAMILIAR &&
  //     Array.isArray(value) &&
  //     value.length > 0
  //       ? (this.family_employee = value.filter((element) => element.is_admin))
  //       : (this.family_employee = value);
  //     this.setUpUi();
  //   });

  //   this.employeAPI.getPermanentOrTomporalData().subscribe((value) => {
  //     console.log(value)

  //     if (Array.isArray(value)) {
  //       this.permanent_employee = value.filter(
  //         (element) =>
  //           element.is_permanent == 1 && element.morphable_type == null
  //       );

  //       this.temps_employee = value.filter(
  //         (element) => element.is_permanent == 0
  //       );
  //     }
  //     this.setUpUi();
  //   });
  // }

  setUpUi() {
    if (this.manoKind.includes('familiar')) {
      this.currentListEmployee = this.family_employee;
      this.employee_is = 'familiar';
    } else if (this.manoKind.includes('permanent')) {
      this.currentListEmployee = this.permanent_employee;
      this.employee_is = 'permanent';
    } else if (this.manoKind.includes('temporal')) {
      this.currentListEmployee = this.temps_employee;
      this.employee_is = 'temporal';
    }
  }

  employeeSelectedChanged(value) {
    this.selected_employee = value;
  }

  async EmloyeeChanged(ev) {
    // await this.fetchEmployee();
    this.setUpUi();
    if (
      Array.isArray(this.currentListEmployee) &&
      this.currentListEmployee.length > 0
    ) {
      let componentProps = {
        data: this.currentListEmployee,
        component: 'mano-de-obra',
        employee_is: this.employee_is,
      };
      const popover = await this.toastUtils.popOverBuilder(
        componentProps,
        PopoverImgComponent,
        'pop-over-style'
      );
      const { data } = await popover.onDidDismiss();
      if (data) {
        this.cashFlow.employee_id = data.data.morphable_id;
        this.cashFlow.morphable_id = data.data.morphable_id;
        this.cashFlow.morphable_type = data.data.morphable_type;
        this.selected_employee = data.data;
        this.selected_employee_name = data.data.name;
      }
    }
  }

  async addTeamMember() {
    var modalType = 'temporal';
    var comp: any = TeamModalPage;
    if (this.manoKind.includes('familiar')) {
      comp = TeamFamilyComponent;
      modalType = 'permanent';
    } else if (this.manoKind.includes('permanent')) {
      modalType = 'permanent';
    } else if (this.manoKind.includes('temporal')) {
      modalType = 'temporal';
    }
    let componentProps = {
      componentSource: modalType,
      action: 'add',
      paramID: null, //null if user is employee
      paramTitle: await this.translateService.instant(
        'screens.equipe_family.new_member'
      ),
      should_add_employee: true,
    };
    console.log('addTeamMember');
    let modal = await this.toastUtils.modalBuilder(comp, componentProps, [
      'my-custom-modal-css',
      'modal-wrapper',
    ]);

    const { data } = await modal.onWillDismiss();
    // this.fetchEmployee();
  }

  cashFlowChangeEvent(value) {
    this.cashFlow = value;
    this.cashflowChangeOutput.emit(this.cashFlow);
  }

  navigagteToMonthlySalariesEvent() {
    this.selected_employee = null;
    this.selected_employee_name = null;
    this.navigagteToMonthlySalariesEventTrigger.emit();
  }
  paymentKind(value) {
    this.paidPerQuantityCoeshadakind.emit(value);
  }
}
