import { HttpClient } from "@angular/common/http";
import { TranslateLoader } from "@ngx-translate/core";
import { catchError, from, map, Observable, of, switchMap, take } from "rxjs";

export class CustomTranslateLoader implements TranslateLoader {
    private cacheName = 'fynka-i18n-cache-v1';
    private languages = ['en', 'fr', 'es'];
    private cacheVersion = '1.0'; // Version du cache pour faciliter les mises à jour

    constructor(private http: HttpClient) {
        if (!('serviceWorker' in navigator)) {
            console.error('Service Worker not supported. Using local storage for caching.');
            this.preloadLanguagesFromHttp();
        } else {
            this.preloadLanguages();
        }
    }

    getTranslation(lang: string): Observable<any> {
        const langFileUrl = `/assets/i18n/${lang}.json`;
    console.log(lang)
        if (!('serviceWorker' in navigator)) {
            console.info(`Service Worker not supported. Fetching from local storage.`);
            const cachedData = localStorage.getItem(`${this.cacheName}-${lang}`);
            if (cachedData) {
                return of(JSON.parse(cachedData));
            } else {
                return this.fetchAndCacheLanguageFile(lang);
            }
        }

        return from(caches.open(this.cacheName)).pipe(
            switchMap((cache) =>
                from(cache.match(langFileUrl)).pipe(
                    switchMap((response) => {
                        if (response) {
                            const cacheVersion = response.headers.get('X-Cache-Version');
                            if (cacheVersion === this.cacheVersion) {
                                return from(response.json());
                            } else {
                                return this.fetchAndCacheLanguageFile(lang);
                            }
                        } else {
                            return this.fetchAndCacheLanguageFile(lang);
                        }
                    }),
                    catchError((error) => {
                        console.error(`Error loading language ${lang}`, error);
                        return lang === 'en' ? of({}) : this.getTranslation('en');
                    })
                )
            )
        );
    }

    private preloadLanguages(): Promise<void> {
        return caches.open(this.cacheName).then((cache) => {
            return Promise.all(
                this.languages.map((lang) => {
                    const langFileUrl = `/assets/i18n/${lang}.json`;

                    return cache.match(langFileUrl).then((response) => {
                        const shouldRefresh = !response || response.headers.get('X-Cache-Version') !== this.cacheVersion;

                        if (shouldRefresh) {
                            return this.http.get(langFileUrl).pipe(take(1)).subscribe((data) => {
                                const headers = new Headers({
                                    'Content-Type': 'application/json',
                                    'X-Cache-Version': this.cacheVersion
                                });

                                const response = new Response(JSON.stringify(data), { headers: headers });
                                cache.put(langFileUrl, response);
                            });
                        }
                    });
                })
            ).then(() => undefined);
        });
    }

    private preloadLanguagesFromHttp(): void {
        this.languages.forEach((lang) => {
            const langFileUrl = `/assets/i18n/${lang}.json`;
            this.http.get(langFileUrl).subscribe(
                (data) => {
                    localStorage.setItem(`${this.cacheName}-${lang}`, JSON.stringify(data));
                    console.log(`Loaded language file for ${lang} from HTTP.`);
                },
                (error) => {
                    console.error(`Failed to load language file for ${lang} from HTTP.`, error);
                }
            );
        });
    }

    private fetchAndCacheLanguageFile(lang: string): Observable<any> {
        const langFileUrl = `/assets/i18n/${lang}.json`;

        return this.http.get<any>(langFileUrl).pipe(
            switchMap((data) => {
                if (!('serviceWorker' in navigator)) {
                    localStorage.setItem(`${this.cacheName}-${lang}`, JSON.stringify(data));
                    return of(data);
                }

                return from(caches.open(this.cacheName)).pipe(
                    switchMap((cache) => {
                        const headers = new Headers({
                            'Content-Type': 'application/json',
                            'X-Cache-Version': this.cacheVersion
                        });

                        const response = new Response(JSON.stringify(data), { headers: headers });
                        return from(cache.put(langFileUrl, response)).pipe(map(() => data));
                    })
                );
            }),
            catchError((error) => {
                console.error(`Error downloading ${langFileUrl}`, error);
                return of({});
            })
        );
    }
}