
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@capacitor/storage';
import routes from '../../../config/routing.json';
import {  Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import Owner from 'src/app/models/owner.model';
@Component({
  selector: 'app-partner-main-view',
  templateUrl: './partner-main-view.component.html',
  styleUrls: ['./partner-main-view.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: false, displayDefaultIndicatorType: false },
    },
  ],
})
export class PartnerMainViewComponent implements OnInit, OnDestroy {
  isDashboardView;
  url;
  owner;
  cropConfig = [];
  step;
  nextUrl;
  backUrl;
  cropProducts;
  selectedCrop;
  stepper;
  ownerSubscription: Subscription
  constructor(
    private router: Router,
    private routingConfig: RoutingConfig,
    private store: Store<{ owner: Owner }>
  ) {
    this.isDashboardView = this.router.url.includes(routes.dashboard.home);
  }
  async ngOnInit() {
    try {
      // Fetch URLs and crop configuration
      [this.nextUrl, this.backUrl] = await Promise.all([
        this.routingConfig.getNextUrl(),
        this.routingConfig.getBackUrl(),
      ]);

      const cropConfig = await this.routingConfig.getCropFormConfig();
      this.ownerSubscription = this.store.select('owner').subscribe((owner) => {
        this.owner = owner;
        if (this.owner) {
          // Parse the owner's profile information
          const profileInfo = JSON.parse(this.owner.profile_info);
          this.cropProducts = this.pluck(profileInfo?.cropConfig, 'id') || [];
          this.cropConfig = cropConfig.filter((element) => {
            return element.products.some((product) =>
              this.cropProducts.includes(product.crop_id)
            );
          });

          if (this.cropConfig.length > 0) {
            this.selectedCrop = this.cropConfig[0];
          }
        } else {
          this.cropProducts = [];
        }
      });
    } catch (error) {
      console.error('Error during ngOnInit:', error);
    }
  }
  pluck = (arr, key) => arr?.map((i) => i[key]);

  currentStepIndex;
  modelChange(event) {
    const index = this.cropConfig.findIndex(
      (step) => event.detail.value.accounting_id === step.accounting_id
    );
    if (index !== -1) {
      this.currentStepIndex = index;
    }
  }

  async mnext() {
    this.router.navigateByUrl(await this.routingConfig.getNextUrl());
  }
  async mreturn() {
    this.router.navigateByUrl(await this.routingConfig.getBackUrl());
  }
  onStepChange(ev) {
    this.step = ev.selectedIndex;
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.ownerSubscription.unsubscribe();
  }
}
