import { cloneDeep } from 'lodash';
import { FormatNumber } from './../../../../utils/number_formatter';
import { QuestionsUtils } from 'src/app/utils/dynamic-forms/question_utils';
import { MORPH_Product } from '../../../../utils/consts';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IonGrid, LoadingController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LotAPI } from 'src/app/apis/lot.service';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { CoffeGenSelectorComponent } from '../../widgets/coffe-gen-selector/coffe-gen-selector.component';
import { FormGroup } from '@angular/forms';
import { QuestionBase } from 'src/app/utils/dynamic-forms/QuestionBase';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import Owner from 'src/app/models/owner.model';
import { loadPlots } from 'src/app/utils/states-management/actions/plots.action';
import { PickerOptionFormater } from 'src/app/utils/pipes-utils/date_formatter';

interface FormConfig {
  is_form_rendered: boolean;
}

@Component({
  selector: 'app-production-editor',
  templateUrl: './porduction-editor.component.html',
  styleUrls: ['./porduction-editor.component.scss'],
})
export class PorductionEditorComponent implements OnInit, OnChanges {
  @ViewChild(IonGrid, { static: true }) content;
  @Input() isDashboardView;
  @Input() production;
  @Input() title = '';
  @Input() modalController;
  @Input() production_superficie = 0;
  total_percent = 0;
  progressModal;
  @Input() form: FormGroup;
  @Input() questions;
  formNode;
  fromObsevable$: Subscription;
  formConfig: FormConfig = {
    is_form_rendered: false,
  };
  @Input() genetics;
  @Output() closeClickEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private progressDialog: LoadingController,
    private secondaryModalController: ModalController,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private lotAPI: LotAPI,
    private questionUtils: QuestionsUtils,
    private currencyPipe: FormatNumber,
    private store: Store<{ owner: Owner }>,
    private customPickerOption: PickerOptionFormater
  ) {}
  showGenetics = false;
  async ngOnInit() {
    this.formNode = document.getElementById(
      'form_' + this.production.morphable_id
    );

    this.form
      .get('distance_between_plants')
      ?.valueChanges.subscribe(async (value) => {
        if (value != null) {
          this.form
            .get('coffee_trees_number')
            .setValue(
              this.currencyPipe.transform(
                this.production_superficie.toFixed(2)
              ) +
                ' ha * ' +
                value +
                ' ' +
                this.translateService.instant('tree_ha') +
                ' = ' +
                Math.round(value * this.production_superficie) +
                ' ' +
                this.translateService.instant('trees')
            );
        }
      });

    this.form.get('is_productive').valueChanges.subscribe((value) => {
      this.questions.forEach((element: QuestionBase<string>) => {
        if (element.key != 'is_productive')
          this.form.get(element.key).setValue(null);
      });
    });

    this.setTitle();
    this.translateService.onLangChange.subscribe((lang) => {
      this.setTitle();
    });
    if (
      !this.genetics[this.production.id] &&
      this.production?.plant_genetics &&
      typeof this.production?.plant_genetics == 'string'
    ) {
      this.genetics[this.production.id] = JSON.parse(
        this.production.plant_genetics
      );
    }

    if (Array.isArray(this.genetics))
      this.genetics[this.production?.id]?.forEach((element: any) => {
        this.total_percent += !isNaN(element?.percent_in_lot)
          ? element?.percent_in_lot
          : 0;
      });
    if (this.genetics && Array.isArray(this.genetics[this.production.id])) {
      this.showGenetics = true;
    }

    this.progressModal = await this.progressDialog.create({});
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.production_superficie && this.form) {
      this.form
        .get('coffee_trees_number')
        .setValue(
          this.currencyPipe.transform(this.production_superficie.toFixed(2)) +
            ' ha * ' +
            changes.production_superficie.currentValue +
            ' ' +
            this.translateService.instant('tree_ha') +
            ' = ' +
            Math.round(
              changes.production_superficie.currentValue *
                this.production_superficie
            ) +
            ' ' +
            this.translateService.instant('trees')
        );
    }
  }
  setTitle() {
    if (this.production.morphable_type == MORPH_Product)
      this.title = this.translateService.instant(
        'product.' + this.production.morphable_id
      );
    else
      this.title = this.translateService.instant(
        'material_category.' + this.production.morphable_id
      );
  }
  CanSubmit = false;

  checkCanSubmit() {
    this.production.plant_genetics = this.genetics;

    let prod = { ...this.production };
    prod.form_answer = this.form.getRawValue();
    console.log(prod);

    this.CanSubmit = false;
    
    // Validate plant_genetics exists and is not empty
    if (!this.genetics[this.production?.id] || 
        !Array.isArray(this.genetics[this.production?.id]) || 
        this.genetics[this.production?.id].length === 0) {
      this.progressDialog.dismiss();
      this.showError('errors.missing_coffe_production');
      return false;
    }
    
    // Validate genetics percentage equals 100% if genetics are present
    if (
      this.total_percent != 100 &&
      Array.isArray(this.genetics[this.production?.id]) &&
      this.genetics[this.production?.id].length > 0
    ) {
      this.progressDialog.dismiss();
      this.showError('label.required_input_no_html');
      return false;
    }

    // Validate sowing_and_pruning percentages equal 100%
    if (
      (prod.form_answer.is_productive == false &&
        prod.form_answer.type_of_area == 'sowing_and_pruning' &&
        prod.form_answer.pruning_percent + prod.form_answer.siembra_percent !=
          100) ||
      (prod.form_answer.is_productive == true &&
        prod.form_answer.last_renew_reason == 'sowing_and_pruning' &&
        prod.form_answer.siembra_percent_productive +
          prod.form_answer.pruning_percent_productive !=
          100)
    ) {
      this.progressDialog.dismiss();
      this.showError('errors.not_100_sowing_and_pruning');
      return false;
    }

    // Validate form is filled properly
    const validateRequiredFields = () => {
      // Check if is_productive is selected (required field)
      if (prod.form_answer.is_productive === null || prod.form_answer.is_productive === undefined) {
        this.showError('errors.missing_field');
        return false;
      }
      
      // Validation for non-productive areas
      if (prod.form_answer.is_productive === false) {
        // Validate type_of_area is selected
        if (!prod.form_answer.type_of_area) {
          this.showError('errors.type');
          return false;
        }
        
        // Specific validations based on type_of_area
        switch (prod.form_answer.type_of_area) {
          case 'sowing_and_pruning':
            // Check if both percentages are provided
            if (
              prod.form_answer.pruning_percent === null || 
              prod.form_answer.pruning_percent === undefined ||
              prod.form_answer.siembra_percent === null || 
              prod.form_answer.siembra_percent === undefined
            ) {
              this.showError('errors.missing_field');
              return false;
            }
            
            // Check if percentages add up to 100%
            if (prod.form_answer.pruning_percent + prod.form_answer.siembra_percent != 100) {
              this.showError('errors.not_100_sowing_and_pruning');
              return false;
            }
            break;
            
          case 'sowing':
            // Validate sowing-specific fields if they exist in the form
            if (
              this.form.get('sowing_date') && 
              !prod.form_answer.sowing_date
            ) {
              this.showError('errors.missing_field');
              return false;
            }
            break;
            
          case 'pruning':
            // Validate pruning-specific fields if they exist in the form
            if (
              this.form.get('pruning_date') && 
              !prod.form_answer.pruning_date
            ) {
              this.showError('errors.missing_field');
              return false;
            }
            break;
        }
        
        // Validate distance_between_plants if it exists
        if (
          this.form.get('distance_between_plants') && 
          (prod.form_answer.distance_between_plants === null || 
          prod.form_answer.distance_between_plants === undefined)
        ) {
          this.showError('errors.plants_distance');
          return false;
        }
      }
      
      // Validation for productive areas
      if (prod.form_answer.is_productive === true) {
        // Validate last_renew_reason if it exists in the form
        if (
          this.form.get('last_renew_reason') && 
          !prod.form_answer.last_renew_reason
        ) {
          this.showError('errors.last_renew');
          return false;
        }
        
        // Specific validations based on last_renew_reason
        if (prod.form_answer.last_renew_reason === 'sowing_and_pruning') {
          // Check if both percentages are provided
          if (
            prod.form_answer.siembra_percent_productive === null || 
            prod.form_answer.siembra_percent_productive === undefined ||
            prod.form_answer.pruning_percent_productive === null || 
            prod.form_answer.pruning_percent_productive === undefined
          ) {
            this.showError('errors.missing_field');
            return false;
          }
          
          // Check if percentages add up to 100%
          if (
            prod.form_answer.siembra_percent_productive + 
            prod.form_answer.pruning_percent_productive != 100
          ) {
            this.showError('errors.not_100_sowing_and_pruning');
            return false;
          }
        }
        
        // Validate trees_number if it exists
        if (
          this.form.get('trees_number') && 
          (prod.form_answer.trees_number === null || 
          prod.form_answer.trees_number === undefined)
        ) {
          this.showError('errors.trees_number');
          return false;
        }
        
        // Validate distance_between_plants if it exists
        if (
          this.form.get('distance_between_plants') && 
          (prod.form_answer.distance_between_plants === null || 
          prod.form_answer.distance_between_plants === undefined)
        ) {
          this.showError('errors.plants_distance');
          return false;
        }
        
        // Validate number_of_stems_per_site if it exists
        if (
          this.form.get('number_of_stems_per_site') && 
          (prod.form_answer.number_of_stems_per_site === null || 
          prod.form_answer.number_of_stems_per_site === undefined)
        ) {
          this.showError('errors.number_of_stems_per_site');
          return false;
        }
      }
      
      // Validate any other required fields that might be in the form
      for (const question of this.questions) {
        if (question.required && 
            !question.depend_on_key?.length && 
            (prod.form_answer[question.key] === null || 
             prod.form_answer[question.key] === undefined || 
             prod.form_answer[question.key] === '')
        ) {
          this.showError('errors.missing_field');
          return false;
        }
      }
      
      return true;
    };
    
    // Run the validation for required fields
    if (!validateRequiredFields()) {
      this.progressDialog.dismiss();
      return false;
    }

    // Validate numeric fields have appropriate values
    const validateNumericFields = () => {
      // Check distance_between_plants is a positive number
      if (
        prod.form_answer.distance_between_plants !== null && 
        prod.form_answer.distance_between_plants !== undefined &&
        (isNaN(prod.form_answer.distance_between_plants) || 
         prod.form_answer.distance_between_plants <= 0)
      ) {
        this.showError('errors.plants_distance');
        return false;
      }
      
      // Check percentage fields are between 0 and 100
      const percentageFields = [
        'pruning_percent', 
        'siembra_percent', 
        'pruning_percent_productive', 
        'siembra_percent_productive',
        'shadow_per_cent'
      ];
      
      for (const field of percentageFields) {
        if (
          prod.form_answer[field] !== null && 
          prod.form_answer[field] !== undefined &&
          (isNaN(prod.form_answer[field]) || 
           prod.form_answer[field] < 0 || 
           prod.form_answer[field] > 100)
        ) {
          if (field === 'shadow_per_cent') {
            this.showError('errors.shadow_per_cent');
          } else {
            this.showError('errors.not_100');
          }
          return false;
        }
      }
      
      return true;
    };
    
    // Run validation for numeric fields
    if (!validateNumericFields()) {
      this.progressDialog.dismiss();
      return false;
    }

    this.CanSubmit = true;
    return true;
  }
  public async form_answer(prodProps = { ...this.production }) {
    this.production.plant_genetics = this.genetics[this.production?.id];
    let prod = { ...this.production };
    prod.id = prodProps.id;
    prod.form_answer = this.form.getRawValue();
    return prod;

    // if (this.checkCanSubmit()) {
    //   return prod;

    //   // this.lotAPI.updateProduction(prod).subscribe(
    //   //   (val) => {
    //   //     this.progressModal.present();
    //   //     this.questionUtils.resetForm();
    //   //     this.store.dispatch(loadPlots());
    //   //     this.progressModal.dismiss();
    //   //     // this.modalController.dismiss({ prod: val });
    //   //     modalMain.dismiss();
    //   //   },
    //   //   (e) => {
    //   //     this.progressModal.dismiss();
    //   //     // this.modalController.dismiss();
    //   //   }
    //   // );
    // }
  }

  async showError(message: string) {
    await this.toastUtils.toastBuilder(
      await this.translateService.instant(message),
      'top',
      '',
      'danger',
      2000
    );
  }

  async addCoffeProd() {
    const modal = await this.secondaryModalController.create({
      component: CoffeGenSelectorComponent,
      cssClass: ['my-custom-modal-css', 'modal-wrapper'],
      componentProps: {
        production: this.production,
        products: this.production.morphable.products,
        isDashboardView: this.isDashboardView,
        modalController: this.secondaryModalController,
        previouslySelected: this.production.plant_genetics,
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (!data.canceled) {
      this.genetics[this.production.id] = data.products;
      if (
        this.genetics &&
        this.genetics[this.production.id].length > 0 &&
        this.genetics[this.production.id].length != 1
      ) {
        this.genetics[this.production.id].forEach((element: any) => {
          element['percent_in_lot'] = 0;
        });
        this.total_percent = 0;
        this.showGenetics = true;
      } else if (this.genetics[this.production.id].length == 1) {
        this.genetics[this.production.id].forEach((element: any) => {
          element['percent_in_lot'] = 100;
        });
        this.total_percent = 100;
        this.showGenetics = true;
      }
      this.scrollBottom();
    }
  }

  cancel() {
    this.closeClickEvent.emit();
  }

  changed() {}

  coffeTreeEventChange(value) {
    this.production.coffee_trees_number = value;
  }
  async handelPickerClicked(selected_gens) {
    let { data } = await this.customPickerOption.openPicker();
    // selected_gens.percent_in_lot = data[0].value;
    this.total_percent = 0;
    this.genetics[this.production?.id].forEach((element) => {
      if (element.id == selected_gens.id) {
        element.percent_in_lot = data[0].value;
      }
      this.total_percent += parseInt(element.percent_in_lot);
    });
    // this.percentage = data[0].value;
    // this.question.value = data[0].value;
    // this.form.get(this.question.key).setValue(data[0].value)
  }
  distanceBetweenPlantsEventChange(value) {
    this.production.distance_between_plants = value;
  }

  scrollBottom() {
    try {
      setTimeout(
        () => (this.content.el.scrollTop += this.content.el.scrollHeight)
      );
    } catch (err) {}
  }
}
