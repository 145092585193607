import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Route, Router } from '@angular/router';
import { Platform, PopoverController } from '@ionic/angular';
// import { Browser } from '@capacitor/browser';
import { Browser } from '@capacitor/browser';
import { StoreRedirectService } from '../../services/store-redirect.service';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  @Input() key1: string = '';
  @Input() alertMsg: string = '';
  @Input() canBeClosed: boolean = true;
  @Input() button_label: string = 'submit';
  @Input() is_confirm_needed: boolean = true;
  @Input() is_info_popover: boolean = true;
  @Input() is_loader_needed: boolean = false;
  @Input() is_html_inner: boolean = false;
  @Input() confirmLink: string = '';
  @Input() delimitationPermitted: boolean = false;
  @Input() geolocationPermitted: boolean = false;
  @ViewChild('option-1') option1: ElementRef<HTMLButtonElement>;
  @ViewChild('option-2') option2: ElementRef<HTMLButtonElement>;
  @ViewChild('option-3') option3: ElementRef<HTMLButtonElement>;
  close;
  @Input() onConfirm: () => void;

  constructor(
    public popover: PopoverController,
    public router: Router,
    private platform: Platform,
    private storeRedirectService: StoreRedirectService
  ) { }

  ngOnInit() { }
  ngAfterViewInit(): void {
    if (this.is_html_inner) {
      if (this.delimitationPermitted) {
        document.getElementById('option-1')?.addEventListener('click', () => {
          this.ClosePopover('1');
        });
        document.getElementById('option-2')?.addEventListener('click', () => {
          this.ClosePopover('2');
        });
      } else {
        document.getElementById('option-1').style.opacity = '0.4';
        document.getElementById('option-1').style.cursor = 'default';

        document.getElementById('option-2').style.opacity = '0.4';
        document.getElementById('option-2').style.cursor = 'default';
      }

      document.getElementById('option-3')?.addEventListener('click', () => {
        this.ClosePopover('3');
      });
    }
  }
  async ClosePopover(option) {
    if (this.confirmLink) {
      if (this.platform.is('ios') || this.platform.is('android')) {
        await this.storeRedirectService.redirectToStore(
          '6450292879',
          'com.burrusdevelopmentgroup.fynka.app',
          this.confirmLink
        );
      }
      // else {
      //   // window.location.reload();
      // }
      this.popover.dismiss({ closed: true, option: option });

      this.onConfirm();


    } else {
      this.popover.dismiss({ closed: true, option: option });
    }
  }
}
// function ClosePopover(option) {
//   popoverView.dismiss({ closed : true,option:option });
// }
