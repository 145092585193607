import { loadFamilyMember } from './../../../utils/states-management/actions/family-members.action';
import { EmptyListModule } from 'src/app/widgets/empty-list/empty-list.module';
import { Family_member } from 'src/app/models/family_member.model';
import { environment } from './../../../../environments/environment';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TeamModalPage } from '../team-modal/team-modal.page';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../config/offlines_keys.json';
import { ModalController, NavController } from '@ionic/angular';
import { ToastUtils } from '../../../utils/toast_builder';
import { PriorDashboardComponent } from '../../prior-dashboard/prior-dashboard.component';
import { EmployeesAPI } from '../../../apis/employees.service';
import Employee from 'src/app/models/employee.model';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import routes from '../../../config/routing.json';
import { OwnerAPI } from 'src/app/apis/owners.service';
import { Store } from '@ngrx/store';
import {
  addEmployee,
  deleteEmployee,
  updateEmployee,
} from 'src/app/utils/states-management/actions/employee.action';
import { cloneDeep } from 'lodash';
import {
  addOwnerEmployee,
  deleteOwnerEmployee,
} from 'src/app/utils/states-management/actions/owner.action';
import Owner from 'src/app/models/owner.model';
import { ModalPageComponent } from '../../family/modal-page/modal-page.component';
import { FamilyViewTwoComponent } from '../../family/family-view-two/family-view-two.component';
import { SummaryComponent } from '../../dashboard/summary/summary.component';
import { LotManagerComponent } from '../../farm/lots-components/lot-manager/lot-manager.component';
import { loadFarm } from 'src/app/utils/states-management/actions/farm.action';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-team-family',
  templateUrl: './team-family.component.html',
  styleUrls: ['./team-family.component.scss'],
})
export class TeamFamilyComponent implements OnInit {
  isChecked = [];
  @Input() owner: any = {};
  @Input() is_init: boolean = false;
  @Input() listFamily: any;
  @Input() action = null;
  @Input() paramTitle = null;
  @Input() componentSource = null;
    @ViewChild('tabGroup') matTab: MatTabGroup;
  
  langage_package;
  is_recivieng_salary;
  is_admin;
  submit_label = 'Confirmar';
  isDashboardView;
  base_url = environment.appRoot;
  image = '/src/assets/empty_avatar.png';
  @Input() rootStack;
  @Input() nav;
  formConfig;
  @ViewChild('content') navCtrl: NavController;

  constructor(
    public modalController: ModalController,
    private employeAPI: EmployeesAPI,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private router: Router,
    private ownerAPI: OwnerAPI,
    private routingConfig: RoutingConfig,
    private store: Store<{ familyMembers: Family_member; owner: Owner }>
  ) {}

  async ngOnInit() {
    await this.langageChanger(
      await Storage.get({
        key: dev.LANGAGE_KEY,
      })
    );
    this.store.dispatch(loadFamilyMember());
    let ClassName = 'TeamFamilyComponent';
    this.formConfig = await this.routingConfig.getPageFormConfig(ClassName);
    this.isDashboardView = this.router.url.includes(routes.dashboard.home);

    this.fetch();
  }
  async presentModal() {
    let modal = await this.toastUtils.modalBuilder(
      ModalPageComponent,
      {
        id: 'adz',
      },
      ['my-custom-modal-css', 'modal-wrapper']
    );
    await modal.onWillDismiss();
    this.fetch();
  }
  fetch() {
    this.store.select('familyMembers').subscribe((value: any) => {
      this.listFamily = Array.from(Object.values(cloneDeep(value).entities));
    });
    this.store.select('owner').subscribe((value: Owner) => {
      this.owner = cloneDeep(value);
    });
  }

  async langageChanger(lang) {
    if (lang.value == 'es') {
      this.submit_label = 'Confirmar';
      this.is_recivieng_salary = 'Remunerado(a)';
      this.is_admin = 'Administrador(a)';
      this.langage_package = await this.translateService
        .get('screens.equipe_family')
        .toPromise();
    } else {
      this.langage_package = await this.translateService
        .get('screens.equipe_family')
        .toPromise();
      this.submit_label = 'Submit';

      this.is_recivieng_salary = 'Remunerated';
      this.is_admin = 'Administrator';
    }
  }

  async presentToast(message) {
    await this.toastUtils.toastBuilder('', '', message, '');
  }

  async openModal(val, action, check) {
    if (this.isChecked[check]) {
      let componentProps = {
        componentSource: 'family',
        action: action,
        paramID: val.id ? val.id : null,
        paramTitle: await this.translateService
          .get('screens.equipe_family.header_modal_family')
          .toPromise(),
      };
      await this.toastUtils.modalBuilder(TeamModalPage, componentProps, [
        'my-custom-modal-css',
        'modal-wrapper',
      ]);
    } else {
      this.presentToast(
        await this.translateService
          .get('screens.equipe_family.check_the_box_error')
          .toPromise()
      );
    }
  }

  goBackToDashBoard() {
    this.nav.setRoot(this.rootStack, {
      nav: this.nav,
      rootStack: PriorDashboardComponent,
    });
  }

  updateEmployee(ev, data) {
    let employee = cloneDeep(data);

    employee.is_admin = ev.isAdmin ? 1 : 0;
    employee.is_receiving_salary = ev.isReceivingSalary ? 1 : 0;

    this.store.dispatch(updateEmployee({ data: employee }));
    // this.employeAPI.updateEmployee(employee).subscribe((value: any) => {
    //   if (this.owner.employee && this.owner.employee.id == value.id)
    //     this.owner.employee.updated_at = value.updated_at;
    //   else if (Array.isArray(this.listFamily)) {
    //     this.listFamily.forEach((element) => {
    //       if (element.employee && element.employee.id == value.id)
    //         element.employee.updated_at = value.updated_at;
    //     });
    //   }
    // });
  }

  instantiateEmployeeFamily(family): Employee {
    const employee = new Employee();
    employee.is_permanent = 1;
    employee.is_receiving_salary = false;
    employee.is_admin = false;
    employee.morphable_type = 'App\\Models\\FamilyMember';
    employee.morphable_id = family.id;
    employee.name = family.name;
    employee.sex = family.sex;
    return employee;
  }

  instantiateEmployeeOwner(): Employee {
    const employee = new Employee();
    employee.is_permanent = 1;
    employee.is_receiving_salary = false;
    employee.is_admin = false;

    employee.morphable_type = 'App\\Models\\Owner';
    employee.morphable_id = this.owner.id;
    employee.name = this.owner.first_name;
    employee.sex = this.owner.sex;
    return employee;
  }

  async addOwnerEmployee() {
    let dataToUpdate = cloneDeep(this.owner);
    dataToUpdate.employee = this.instantiateEmployeeOwner();
    if (!this.owner.employee) {
      this.store.dispatch(
        addOwnerEmployee({ data: dataToUpdate, props: null })
      );
    } else if (this.owner.employee) {
      delete dataToUpdate.employee;
      this.store.dispatch(
        deleteOwnerEmployee({ data: this.owner, props: null })
      );
    }
  }
  handelTeamMemberOption(props, entity) {}
  async addFamilyEmployee(member) {
    let dataToUpdate = cloneDeep(member);
    dataToUpdate.employee = this.instantiateEmployeeFamily(member);

    if (!member.employee) {
      this.store.dispatch(
        addEmployee({
          data: dataToUpdate,
          props: null,
        })
      );
    } else if (member.employee) {
      this.store.dispatch(deleteEmployee({ data: dataToUpdate }));
    }
    this.store.dispatch(loadFarm());
  }
  closeModal() {
    this.modalController.dismiss();
  }
}
