import { AHOROS_ACCOUNTING_ENTRY_CATEGORY } from './../../../utils/consts';
import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { $ } from 'protractor';
import { lastValueFrom, Subscription, take } from 'rxjs';
import { Countrie } from 'src/app/models/countrie.model';
import Owner from 'src/app/models/owner.model';
import { getCurrentMonthAndYear } from 'src/app/utils/pipes-utils/date_formatter';
import {
  getDashboardData,
  loadHarvestSummary,
  loadInsumosBought,
  loadTotalAccountingCategories,
  loadWorkForceStats,
} from 'src/app/utils/states-management/actions/dashboard.action';
import { getTotalsAccountingCategoriesResult } from 'src/app/utils/states-management/selector/dashboard.selector';
import { cloneDeep } from 'lodash';
import {
  getOwnerById,
  setCheckPointRoute,
} from 'src/app/utils/states-management/actions/owner.action';
import { loadFarm } from 'src/app/utils/states-management/actions/farm.action';
import { loadUnit } from 'src/app/utils/states-management/actions/units-action';
import { loadFamilyMember } from 'src/app/utils/states-management/actions/family-members.action';
import {
  loadPartner,
  loadPartnerType,
} from 'src/app/utils/states-management/actions/partner.action';
import { paginateSales } from 'src/app/utils/states-management/actions/product-sale.action';
import { Farm } from 'src/app/models/farm/farm.model';
import { loadAppData } from 'src/app/utils/states-management/actions/offline.action';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.page.html',
  styleUrls: ['./wallet.page.scss'],
})
export class WalletPage implements OnInit {
  constructor(private store: Store<{ owner: Owner; farm: Farm }>) {}
  accountingCategories;
  farmSummaries;
  profile_info;
  balance = 0;
  country: Countrie;
  monthYear = getCurrentMonthAndYear(new Date());
  owner: Owner;
  isDataLoading = false;
  ownerSubscription: Subscription;

  async ngOnInit() {
    const startDate = new Date(
      this.monthYear.year,
      this.monthYear.month - 1,
      1
    ).toISOString();
    const endDate = new Date(
      this.monthYear.year,
      this.monthYear.month,
      0 + 1
    ).toISOString();
    const date = { startDate: startDate, endDate: endDate };

    this.store.dispatch(loadTotalAccountingCategories({ data: date }));

    this.ownerSubscription = this.store
      .select('owner')
      .subscribe(async (owner: Owner) => {
        if (!this.country?.id) {
          this.owner = cloneDeep(owner);

          if (
            this.owner &&
            this.owner?.checkpoint_path &&
            !this.owner?.checkpoint_path.includes('home')
          ) {
            this.store.dispatch(setCheckPointRoute({ url: '/home/wallet' }));
          }

          this.country = this.owner.countrie;
          if (this.owner.profile_info)
            this.profile_info = JSON.parse(this.owner?.profile_info);

          let farm = await lastValueFrom(
            this.store.select('farm').pipe(take(1))
          );
          if (!farm?.id) {
            this.store.dispatch(loadAppData());
          }
        } else {
          this.ownerSubscription.unsubscribe();
        }
      });

    // TODO: to data  load from connection effect

    // this.store.dispatch(getOwnerById());
    // this.store.dispatch(loadFarm());
    // this.store.dispatch(loadUnit());

    // this.store.dispatch(loadFamilyMember());
    // this.store.dispatch(loadPartnerType());
    // this.store.dispatch(loadPartner());
    // this.store.dispatch(loadTotalAccountingCategories({ data: data }));
    // this.store.dispatch(getDashboardData({ data: data }));
    // this.store.dispatch(loadWorkForceStats({data:data}))
    // this.store.dispatch(loadInsumosBought({ data :data }))
    // this.store.dispatch(loadHarvestSummary({ data :data }));
    let saleFilter = {
      end_date: '2050-1-1',
      ids: [16, 17, 18],
      is_animal: 0,
      page: 1,
      page_size: 20,
      start_date: '2016-1-1',
    };
    this.store.dispatch(paginateSales(saleFilter));

    this.store
      .select(getTotalsAccountingCategoriesResult)
      .subscribe(async ({ data, isLoading }) => {
        var totalsAccountingCategories = [];
        if (!isLoading) {
          data.forEach((element) => {
            if (
              this.profile_info &&
              element?.morphable_type != null &&
              element?.id != null
            ) {
              let cropExist = this.profile_info?.cropConfig.find(
                (item) =>
                  item.id == element.morphable_id &&
                  element.morphable_type.toLowerCase().includes(item.entity)
              );
              if (cropExist) {
                totalsAccountingCategories.push(element);
              }
            } else {
              totalsAccountingCategories.push(element);
            }
          });
          this.accountingCategories = totalsAccountingCategories;

          this.balance = this.accountingCategories
            .filter((val) => val.id != AHOROS_ACCOUNTING_ENTRY_CATEGORY)
            .reduce((a, b) => a + b.net, 0);
        }

        this.isDataLoading = isLoading;
        // this.orderAccountings();
      });
  }
  handelDate(data) {
    this.monthYear = getCurrentMonthAndYear(new Date(data));
    const startDate = new Date(
      this.monthYear.year,
      this.monthYear.month - 1,
      1
    ).toISOString();
    const endDate = new Date(
      this.monthYear.year,
      this.monthYear.month,
      0 + 1
    ).toISOString();
    let date = { startDate: startDate, endDate: endDate };
    //this.store.dispatch(loadTotalAccountingCategories({ data: date }));
  }
  getAccountingCategoriesList(startDate, endDate) {}
}
