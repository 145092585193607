import { PriorDashboardComponent } from '../modules/prior-dashboard/prior-dashboard.component';
import { LoginComponent } from '../modules/login/login.component';
import { PageCountriesComponent } from '../modules/page-countries/page-countries.component';
import { PersonalDataComponent } from '../components/personal-data/personal-data.component';
import { PeronsDataScreensComponent } from '../modules/perons-data-screens/perons-data-screens.component';
import { ProfilePhotoComponent } from '../components/personal-data/profile-photo/profile-photo.component';
import { TermesConditionsComponent } from '../modules/termes-conditions/termes-conditions.component';
import { CreateAccountComponent } from '../modules/create-account/create-account.component';
import { SmsValidationComponent } from '../modules/sms-validation/sms-validation.component';
import { PopoverComponent } from '../widgets/popover/popover.component';
import { PopoverImgComponent } from '../widgets/popover-img/popover-img.component';
import { ModaSelectComponent } from '../moda-select/moda-select.component';
import { LangPopoverComponent } from '../widgets/lang-popover/lang-popover.component';
import { LicenseAgreementComponent } from '../widgets/license-agreement/license-agreement.component';
import { PhotoEditorComponent } from '../components/personal-data/profile-photo/photo-editor/photo-editor.component';
import { ProfileInfoComponent } from '../components/personal-data/profile-info/profile-info.component';
import { PopoverChecklistComponent } from '../widgets/popover-checklist/popover-checklist.component';
import { ExistingAccountsComponent } from '../modules/login/existing-accounts/existing-accounts.component';
import { SplashComponent } from '../modules/splash/splash.component';
import { IntroductoryvideoComponent } from '../modules/introductoryvideo/introductoryvideo.component';
import { DarkModeToggleComponent } from '../widgets/dark-mode-toggle/dark-mode-toggle.component';
import { PopoverInfoComponent } from '../widgets/popover-info/popover-info.component';
import { LandingSwipeStepComponent } from '../widgets/landing-swipe-step/landing-swipe-step.component';
import { LandingHeaderComponent } from '../widgets/landing-header/landing-header.component';
import { UpdateAccessWidgetComponent } from '../widgets/update-access-widget/update-access-widget.component';
import { landingDoneComponent } from '../widgets/landing_done/landing_done.component';

export const AppDeclarations = [
  PriorDashboardComponent,
  LoginComponent,
  PageCountriesComponent,
  PersonalDataComponent,
  PeronsDataScreensComponent,
  ProfilePhotoComponent,
  TermesConditionsComponent,
  CreateAccountComponent,
  SmsValidationComponent,
  PopoverComponent,
  PopoverImgComponent,
  ModaSelectComponent,
  LangPopoverComponent,
  LicenseAgreementComponent,
  PhotoEditorComponent,
  ProfileInfoComponent,
  PopoverChecklistComponent,
  ExistingAccountsComponent,
  SplashComponent,
  IntroductoryvideoComponent,
  DarkModeToggleComponent,
  PopoverInfoComponent,
  LandingSwipeStepComponent,
  LandingHeaderComponent,
  UpdateAccessWidgetComponent,
  landingDoneComponent
];