import {
  addAgriProduct,
  addAgriProductSuccess,
  deleteSelectedAgriProduct,
  deleteSelectedAgriProductSuccess,
  loadAgriProduct,
  loadAgriProductSuccess,
  loadSelectedAgriProduct,
  loadSelectedAgriProductSuccess,
} from './../actions/agri-product.action';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { ProductAPI } from 'src/app/apis/product.service';
import { dataSyncWithServerError } from '../actions/owner.action';
import { forkJoin, from, of } from 'rxjs';
import { ProductionAPI } from 'src/app/apis/FarmAPI/productions.service';

@Injectable()
export class AgriProductEffect {
  constructor(
    private actions$: Actions,
    private productAPI: ProductAPI,
    private productionAPI: ProductionAPI //   public translateService: TranslateService,
  ) {}
  loadAgriProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAgriProduct),
      switchMap((action) => {
        return this.productAPI.filteredProduct(0);
      }),
      map((data: any) => {
        return loadAgriProductSuccess({ agriculturalProducts: data });
      }),
      catchError((err) => of(dataSyncWithServerError(err)))
    )
  );
  loadSelectedAgriProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSelectedAgriProduct),
      switchMap((action) => {
        return this.productionAPI.getProductionByFarmAndType(0);
      }),
      map((data: any) => {
        return loadSelectedAgriProductSuccess({
          selectedAgriculturalProducts: data,
        });
      })
      //   catchError((err) => of(dataSyncWithServerError(err)))
    )
  );
  // deleteSelectedAgriProduct$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(deleteSelectedAgriProduct),
  //     switchMap((action) => {
  //       return this.productionAPI.deleteFarmProduction(action?.product?.production_per_farm_id);
  //     }),
  //     map((data: any) => {
  //       return deleteSelectedAgriProductSuccess();
  //     }),
  //   //   catchError((err) => of(dataSyncWithServerError(err)))
  //   )
  // );

  // deleteSelectedAgriProduct$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(deleteSelectedAgriProduct), // Listens for the deleteSelectedAgriProduct action
  //     concatMap((action) =>
  //       this.productionAPI.deleteFarmProduction(action?.products?.production_per_farm_id).pipe(
  //         map(() => deleteSelectedAgriProductSuccess()), // Dispatch success action after deletion
  //         catchError((error) => of(dataSyncWithServerError(error))) // Handle errors gracefully
  //       )
  //     )
  //   )
  // );
  deleteSelectedAgriProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSelectedAgriProduct),
      concatMap((action) =>
        forkJoin(
          action.products.map((product) =>
            this.productionAPI
              .deleteFarmProduction(product?.production_per_farm_id)
              .pipe(
                map(() => ({ success: true, product })), // Wrap result for context
                catchError((error) => of({ success: false, product, error })) // Catch individual errors
              )
          )
        ).pipe(
          map((results) => {
            const errors = results.filter((r) => !r.success);
            if (errors.length) {
              // return dataSyncWithServerError({ err }); // Send aggregated errors
            }
            return deleteSelectedAgriProductSuccess(); // All successful
          }),
          // catchError((error) =>
          //   // of(dataSyncWithServerError({ err })) // Handle forkJoin-level errors
          // )
        )
      )
    )
  );
  
  // deleteSelectedAgriProduct$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(deleteSelectedAgriProduct),
  //     concatMap((action) =>
  //       from(action.products).pipe(
  //         concatMap((products) =>
  //           forkJoin(
  //             products.map((product) =>
  //               this.productionAPI.deleteFarmProduction(
  //                 product?.production_per_farm_id
  //               )
  //             )
  //           ).pipe(
  //             map(() =>
  //               products.map((product) => deleteSelectedAgriProductSuccess())
  //             ),
  //             concatMap((actions) => from(actions)),
  //             catchError((error) => of(dataSyncWithServerError(error)))
  //           )
  //         )
  //       )
  //     )
  //   )
  // );

  addAgriProductSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addAgriProduct),
      switchMap((action) => {
        return this.productionAPI.insertMultipleProduction(action.productions);
      }),
      map((data: any) => {
        return addAgriProductSuccess({
          productions: data.filter((value) => value.is_animal == 0),
        });
      })
      // catchError((err) => of(dataSyncWithServerError(err)))
    )
  );
}
