import { dev } from '../config/endpoints.json';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CashFlow } from '../models/cash-flow/cashFlow';
import { Observable } from 'rxjs';
import { SecondCellCertif } from '../models/seconde_cell_certif';
import { PaginationResult } from '../models/pagination-result.model';

@Injectable({ providedIn: 'root' })
export class CashflowAPI {
  base_url = environment.appRoot + '/api/';

  constructor(private httpClient: HttpClient) {}

  //category
  getCashflowByCountry(countryId) {
    const url =
      this.base_url +
      dev.endpoint.cashflow.getCashflowByCountry +
      '/' +
      countryId;

    return this.httpClient.get(url);
  }

  //save cashflow
  saveCashFlow(cashFlow: CashFlow): Observable<any> {
    const url = this.base_url + dev.endpoint.cashflow.saveCashFlow;
    const formData = new FormData();
    let cashJson = {
      amount: cashFlow.amount,
      currency: cashFlow.currency,
      date: cashFlow.date,
      payment_methods: cashFlow.payment_methods,
      salary: JSON.stringify(cashFlow.salary),
      inputName: cashFlow.inputName,
      quantity: cashFlow.quantity,
      unity: cashFlow.unity,
      donor_organization_name: cashFlow.donor_organization_name,
      comment: cashFlow.comment,
      is_income:
        cashFlow.is_income !== null ? (cashFlow.is_income ? 1 : 0) : null,
      morphable_id: cashFlow.morphable_id,
      morphable_type: cashFlow.morphable_type,
      farm_id: cashFlow.farm_id,
      cash_flow_categorie_id: cashFlow.cash_flow_categorie_id,
      money_reporting_id: cashFlow.money_reporting_id,
      cash_flow_expense_id: cashFlow.cash_flow_expense_id,
      accounting_entries_categories: cashFlow?.accounting_entries_categories,
      product_id: cashFlow?.product_id,
      material_product_id: cashFlow?.material_product_id,
      partners_id: cashFlow?.partners_id,
      is_first_sale:
        cashFlow?.is_first_sale === true
          ? 1
          : cashFlow?.is_first_sale === false
          ? 0
          : undefined,
      material_categorie_id: cashFlow?.material_categorie_id,
      operation_id: cashFlow.operation_id ? cashFlow.operation_id : null,
      activity: cashFlow.activity_id,
      employee_id: cashFlow.employee_id,
      production_per_farm_id: cashFlow.production_per_farm_id,
    };
    Object.keys(cashJson).forEach(function (key) {
      if (cashJson[key] !== undefined && cashJson[key] !== null) {
        formData.append(key, cashJson[key]);
      }
    });
    if (
      cashFlow.certifications &&
      cashFlow.certifications != null &&
      cashFlow.certifications != undefined
    ) {
      formData.append(
        'certifications',
        JSON.stringify(cashFlow.certifications)
      );
    }
    return this.httpClient.post(url, formData);
  }

  updateCashFlow(cashFlow: CashFlow): Observable<any> {
    const url = this.base_url + dev.endpoint.cashflow.updateCashFlow;
    const formData = new FormData();
    let cashJson = {
      id: cashFlow.id,
      amount: cashFlow.amount,
      currency: cashFlow.currency,
      date: cashFlow.date,
      payment_methods: cashFlow.payment_methods,
      inputName: cashFlow.inputName,
      quantity: cashFlow.quantity,
      unity: cashFlow.unity,
      donor_organization_name: cashFlow.donor_organization_name,
      comment: cashFlow.comment,
      is_income:
        cashFlow.is_income !== null ? (cashFlow.is_income ? 1 : 0) : null,
      morphable_id: cashFlow.morphable_id,
      morphable_type: cashFlow.morphable_type,
      morphable: cashFlow?.morphable
        ? JSON.stringify(cashFlow?.morphable)
        : null,
      farm_id: cashFlow.farm_id,
      cash_flow_categorie_id: cashFlow.cash_flow_categorie_id,
      money_reporting_id: cashFlow.money_reporting_id,
      cash_flow_expense_id: cashFlow.cash_flow_expense_id,
      accounting_entries_categories: cashFlow?.accounting_entries_categories,
      product_id: cashFlow?.product_id,
      material_product_id: cashFlow?.material_product_id,
      partners_id: cashFlow?.partners_id,
      is_first_sale: cashFlow?.is_first_sale,
      material_categorie_id: cashFlow?.material_categorie_id,
      operation_id: cashFlow.operation_id ? cashFlow.operation_id : null,
      activity: cashFlow.activity_id,
      production_per_farm_id: cashFlow.production_per_farm_id,
    };
    Object.keys(cashJson).forEach(function (key) {
      if (cashJson[key] !== undefined && cashJson[key] !== null) {
        formData.append(key, cashJson[key]);
      }
    });

    return this.httpClient.post(url, formData);
  }
  //category
  getPropertiesCashflowCategories(data) {
    const { country_id } = data;
    const url =
      this.base_url +
      dev.endpoint.cashflow.get_propriedades_cashflow_categories +
      '/' +
      country_id;
    return this.httpClient.get(url);
  }

  //category
  getAgriculturalProductWithCashflowCategories() {
    const url =
      this.base_url +
      dev.endpoint.cashflow.get_agricultural_product_with_cashflow_categories;
    return this.httpClient.get(url);
  }
  //cashflow
  getFirstSalesByOwnerId() {
    const url =
      this.base_url + dev.endpoint.cashflow.get_first_sales_by_owner_id;
    return this.httpClient.get(url);
  }
  //store certif
  saveCertification(secondSellCeritifcation: SecondCellCertif) {
    const url = this.base_url + dev.endpoint.cashflow.save_certification;
    const formData = new FormData();
    formData.append('cash_flow_id', secondSellCeritifcation.cash_flow_id);
    // formData.append('certifs2', secondSellCeritifcation.certifs2);
    // formData.append('certifs1', secondSellCeritifcation.certifs1);
    return this.httpClient.post(url, formData);
  }
  //cashflow
  getCashflowByDate(
    startDate: string | null,
    endDate: string | null,
    page: number,
    page_size: number,
    accountings_entries: Array<number>,
    natures: Array<number>
  ): Observable<PaginationResult<CashFlow>> {
    let url =
      this.base_url +
      dev.endpoint.cashflow.get_cashflow_by_date +
      '/' +
      100 +
      '?page=' +
      page +
      '&filter[is_income]=' +
      natures +
      '&filter[accounting_entries_categories]=' +
      accountings_entries;
    if (endDate && startDate)
      url +=
        '&filter[date]=' + [startDate.split('T')[0], endDate.split('T')[0]];

    return this.httpClient.get<PaginationResult<CashFlow>>(url);
  }
  //cashflow
  getProductSells(
    is_animal: number = 0,
    ids: Array<number> = [16, 17, 18],
    page_size: number = 5,
    start_date: string,
    end_date: string,
    page: number = 1
  ) {
    const url =
      this.base_url +
      dev.endpoint.cashflow.get_product_sells +
      '/' +
      is_animal +
      '/' +
      JSON.stringify(ids) +
      '/' +
      page_size +
      '/' +
      start_date.split('T')[0] +
      '/' +
      end_date.split('T')[0] +
      '?page=' +
      page;
    return this.httpClient.get(url);
  }

  deleteCashflow(id): Observable<any> {
    const url =
      this.base_url + dev.endpoint.cashflow.delete_cashflow + '/' + id;
    return this.httpClient.delete(url);
  }

  //Get coffe expenses
  getCashflowExpenses() {
    const url = this.base_url + dev.endpoint.cashflow.get_cashflow_expenses;
    return this.httpClient.get(url);
  }

  deleteMultipleCashflows(ids) {
    const url = this.base_url + dev.endpoint.cashflow.delete_multiple_cashflows;
    let formData = new FormData();
    formData.append('ids', JSON.stringify(ids));
    return this.httpClient.post(url, formData);
  }

  getCashflowByMorphableTypeAndMorphableIdAndFarmId(
    morphable_type,
    morphable_id
  ) {
    const url =
      this.base_url +
      dev.endpoint.cashflow.get_cashflow_by_morphable_type_and_morphable_id +
      '/' +
      morphable_type +
      '/' +
      morphable_id;

    return this.httpClient.get(url);
  }

  getActivities(accountingEntryID: number) {
    const url =
      this.base_url +
      dev.endpoint.cashflow.get_activities +
      '/' +
      accountingEntryID;
    return this.httpClient.get(url);
  }

  getCashflowExpensesParent(cashflowExpenses) {
    const url =
      this.base_url +
      dev.endpoint.cashflow.get_cashflow_expenses_parent +
      '/' +
      JSON.stringify(cashflowExpenses);
    return this.httpClient.get(url);
  }
  getCashflowById(id: number): Observable<CashFlow> {
    const url =
      this.base_url + dev.endpoint.cashflow.get_cashflow_by_id + '/' + id;
    return this.httpClient.get<CashFlow>(url);
  }
}
