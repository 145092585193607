import { DateFormatPipe } from './../../../../utils/pipes-utils/date_formatter';
import {
  ANIMALES_ACCOUNTING_ENTRY_CATEGORY,
  OTROS_ACCOUNTING_ENTRY_CATEGORY,
} from './../../../../utils/consts';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Chart } from 'chart.js';
import { StatisticsAPI } from 'src/app/apis/statistics.service';
import {
  ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY,
  AHOROS_ACCOUNTING_ENTRY_CATEGORY,
  CAFE_ACCOUNTING_ENTRY_CATEGORY,
  GRAPH_COLORS,
  PERSONAL_ACCOUNTING_ENTRY_CATEGORY,
} from 'src/app/utils/consts';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../../config/offlines_keys.json';
import { UserInfo } from 'src/app/apis/users-info.service';
import { TranslateService } from '@ngx-translate/core';
import {
  AnalysesFilterComponent,
  FilterChoice,
} from '../../cashflow/filters/analyses-filter/analyses-filter.component';
import { CurrencyPipe } from '@angular/common';
import { lastValueFrom, take } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { Store } from '@ngrx/store';

interface FormConfig {
  filter: FilterChoice;
  show_common_expenses: boolean;
  require_filtring_modal: boolean;
}

@Component({
  selector: 'app-accounting-entry-categorie-stats',
  templateUrl: './accounting-entry-categorie-stats.component.html',
  styleUrls: ['./accounting-entry-categorie-stats.component.scss'],
})
export class AccountingEntryCategorieStatsComponent
  implements OnInit, AfterViewInit
{
  sums = {
    income: 0,
    expenses: 0,
  };
  @Input() money_section;
  @Input() id;
  displayableEntries;
  @ViewChild('pieCanvas') pieCanvas: ElementRef;
  @Input() is_prod;
  @Input() currency;
  @Input() is_income;
  @Input() from;
  @Input() to;
  @Input() farm;
  formConfig: FormConfig = {
    filter: {
      start_date: new Date().toString(),
      end_date: new Date().toString(),
      sort_by: 'cashflowcategories',
      monthly_yearly: 'm-Y',
    },
    show_common_expenses: true,
    require_filtring_modal: true,
  };
  showBtnGraphicOrTable = false;
  byMorphable;
  title;
  income_label = 'Ingresos';
  spent_label = 'Egresos';
  barChart;
  pieChart;
  isTableView = 1;
  laodingModal;
  lang;
  selected_year: string;
  activity_ids = [];
  constructor(
    private modalController: ModalController,
    private statisticsController: StatisticsAPI,
    private userInfo: UserInfo,
    public translateService: TranslateService,
    private datePipe: DateFormatPipe,
    private currencyPipe: CurrencyPipe,
    private store:Store
  ) {}

  async ngOnInit() {
    await this.translate();
    this.translateService.onLangChange.subscribe((val) => {
      this.initDate();
    });
    this.switchCaseTitle();
    if (this.id === 1 && this.is_income) this.showBtnGraphicOrTable = true;

    this.fetchSummaryByCashflowCategories(
      this.is_income ? 1 : 0,
      this.from,
      this.to
    );

    this.formConfig = {
      filter: {
        start_date: this.from,
        end_date: this.to,
        sort_by: 'cashflowcategories',
        monthly_yearly: 'm-Y',
      },
      show_common_expenses: true,
      require_filtring_modal: [
        CAFE_ACCOUNTING_ENTRY_CATEGORY,
        OTROS_ACCOUNTING_ENTRY_CATEGORY,
        ANIMALES_ACCOUNTING_ENTRY_CATEGORY,
        PERSONAL_ACCOUNTING_ENTRY_CATEGORY,
      ].includes(this.id),
    };
  }

  async translate() {
    const country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)));
    this.currency = country && 'currency' in country ? country.currency : null;

    this.lang = await this.userInfo.getSelectedLangage();
    if (this.lang == 'es') {
      this.income_label = 'Ingresos';
      this.spent_label = 'Egresos';
    } else {
      this.income_label = 'Revenues';
      this.spent_label = 'Expenses';
    }
  }
  ngAfterViewInit(): void {
    this.money_section ? this.pieChartBrowser(this.displayableEntries) : null;
  }

  async pieChartBrowser(datas): Promise<void> {
    let colors = [];
    let data = [];
    let labels = [];
    if (!Array.isArray(datas)) return;
    if (this.pieChart) this.pieChart.destroy();

    datas.forEach((element, index) => {
      labels.push(
        element.title +
          ' :  ' +
          this.currencyPipe.transform(
            parseFloat(element.percent).toFixed(2),
            undefined,
            '',
            '1.2-2',
            'fr-FR'
          ) +
          '  % ' +
          ' ; ' +
          this.currencyPipe.transform(
            element.amount,
            undefined,
            '',
            '1.2-2',
            'fr-FR'
          ) +
          ' ' +
          this.currency
      );
      data.push(parseFloat(element.amount));
      colors.push(this.getRandomColor(index));
    });
    labels.reverse();
    data.reverse();
    if (this.pieChart) {
      await this.pieChart?.destroy();
    }
    this.pieChart = new Chart(this.pieCanvas.nativeElement, {
      type: 'pie',
      data: {
        labels: labels,
        datasets: [
          {
            backgroundColor: colors,
            data: data,
            hoverBackgroundColor: colors,
            hoverBorderWidth: 4,
            hoverBorderColor: ['black'],
          },
        ],
      },
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                if (tooltipItem.dataIndex % 2) return [tooltipItem.label];
                else return tooltipItem.label;
              },
            },
          },
          legend: {
            display: false,
            position: 'bottom',
          },
        },

        aspectRatio: 1,
      },
    });
    this.pieChart.canvas.parentNode.style.height = '100%';
    this.pieChart.canvas.parentNode.style.width = '100%';
  }

  getRandomColor(i) {
    const color = GRAPH_COLORS[i];
    return color;
  }

  dismiss() {
    this.modalController.dismiss();
  }

  async switchCaseTitle() {
    switch (this.id) {
      case CAFE_ACCOUNTING_ENTRY_CATEGORY:
        this.is_income
          ? (this.title =
              this.income_label +
              ' ' +
              (await this.translateService.get('cafe').toPromise()))
          : (this.title =
              this.spent_label +
              ' ' +
              (await this.translateService.get('cafe').toPromise()));
        break;
      case ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY:
        this.is_income
          ? (this.title =
              this.income_label +
              ' ' +
              (await this.translateService.get('admin').toPromise()))
          : (this.title =
              this.spent_label +
              ' ' +
              (await this.translateService.get('admin').toPromise()));
        break;
      case ANIMALES_ACCOUNTING_ENTRY_CATEGORY:
        this.is_income
          ? (this.title =
              this.income_label +
              ' ' +
              (await this.translateService.get('animal').toPromise()))
          : (this.title =
              this.spent_label +
              ' ' +
              (await this.translateService.get('animal').toPromise()));
        break;
      case PERSONAL_ACCOUNTING_ENTRY_CATEGORY:
        this.is_income
          ? (this.title =
              this.income_label +
              ' ' +
              (await this.translateService.get('personal').toPromise()))
          : (this.title =
              this.spent_label +
              ' ' +
              (await this.translateService.get('personal').toPromise()));
        break;
      case AHOROS_ACCOUNTING_ENTRY_CATEGORY:
        this.title =
          this.spent_label +
          ' ' +
          (await this.translateService.get('savings').toPromise());
        break;
      case PERSONAL_ACCOUNTING_ENTRY_CATEGORY:
        this.title =
          this.spent_label +
          ' ' +
          (await this.translateService.get('hogar').toPromise());
        break;
      case 6:
        this.is_income
          ? (this.title =
              this.income_label +
              ' ' +
              (await this.translateService.get('propiedades').toPromise()))
          : (this.title =
              this.spent_label +
              ' ' +
              (await this.translateService.get('propiedades').toPromise()));
        break;
      case 2:
        this.is_income
          ? (this.title =
              this.income_label +
              ' ' +
              (await this.translateService.get('Otros').toPromise()))
          : (this.title =
              this.spent_label +
              ' ' +
              (await this.translateService.get('Otros').toPromise()));
        break;
    }
  }

  initDate() {
    this.selected_year =
      this.datePipe.transform(this.from, this.translateService.currentLang) +
      ' - ' +
      this.datePipe.transform(this.to, this.translateService.currentLang);
  }

  fetchSummaryByFamilyMembers(from, to) {
    const incom = this.is_income ? 1 : 0;
    this.laodingModal.present();
    this.statisticsController
      .fetchStatsByMorphable(from, to, this.id, incom)
      .subscribe(
        (values: any) => {
          if (this.is_income) {
            this.displayableEntries = this.formatResponse(values.details);
            this.sums.income = values.total;
          } else {
            this.sums.expenses = values.total;
            this.displayableEntries = this.formatResponse(values.details);
          }
          this.pieChartBrowser(this.displayableEntries);
          this.laodingModal.dismiss();
        },
        (e) => {
          this.laodingModal.dismiss();
        }
      );
  }

  async fetchOtrosCashflowSummary(from, to) {
    // this.laodingModal.present();
    // this.statisticsController
    //   .getMaterialProductStats(
    //     from,
    //     to,
    //     this.id,
    //     this.translateService.currentLang,
    //     true
    //   )
    //   .subscribe(
    //     (value: any) => {
    //       this.laodingModal.dismiss();

    //       if (Array.isArray(value)) {
    //         if (this.is_income)
    //           this.displayableEntries = value.map((element) => {
    //             let calculatedPercent =
    //               (element.income / this.sums.expenses) * 100;

    //             return {
    //               title: element.name,
    //               amount: element.income ? element.income : 0,
    //               percent: isNaN(calculatedPercent) ? 0 : calculatedPercent,
    //             };
    //           });
    //         else
    //           this.displayableEntries = value.map((element) => {
    //             let calculatedPercent =
    //               (element.outcome / this.sums.expenses) * 100;

    //             return {
    //               title: element.name,
    //               amount: element.outcome ? element.outcome : 0,
    //               percent: isNaN(calculatedPercent) ? 0 : calculatedPercent,
    //             };
    //           });
    //         this.pieChartBrowser(this.displayableEntries);
    //       }
    //     },
    //     (e) => {
    //       this.laodingModal.dismiss();
    //     }
    //   );
  }

  fetchSummaryByCashflowCategories(income, from, to) {
    this.initDate();
    this.laodingModal.present();
    this.statisticsController
      .fetchStatsByCashflowCategories(from, to, this.id)
      .subscribe({
        next: (values: any) => {
          let {result} = values[income]
          this.displayableEntries = this.formatResponse(result.details);

          this.sums.income = result?.total;
          this.sums.expenses = result?.total;
          this.laodingModal.dismiss();
          this.pieChartBrowser(this.displayableEntries);
        },
        error: (e) => {
          this.laodingModal.dismiss();
        },
      });
  }

  formatResponse(valueResponse: any) {
    const formatedValue = new Array();
    for (const [key, value] of Object.entries(valueResponse)) {
      formatedValue.push({
        title: key,
        amount: value['amount'],
        percent: parseFloat(value['percent_value']).toFixed(2),
      });
    }
    return formatedValue;
  }

  prepareGraph() {
    this.pieChartBrowser(this.displayableEntries);
  }

  setTable(val) {
    this.isTableView = val;
    if (val === 2) this.prepareGraph();
  }

  async filterModal() {
    const accountingEntryCategory = JSON.parse(
      (await Storage.get({ key: dev.CASHFLOW_CATEGORIES })).value
    ).find((element) => element.id == this.id);

    const modal = await this.modalController.create({
      component: AnalysesFilterComponent,
      componentProps: {
        accountingEntryCategory: accountingEntryCategory,
        title: this.title,
        is_income: this.is_income,
      },
      id: 'AnalysesFilterComponent',
    });
    modal.present();
    let { data } = await modal.onWillDismiss();
    let { filter, activity_ids } = data;

    if (filter) {
      if (activity_ids) {
        this.activity_ids = activity_ids;
      }
      this.formConfig.filter = filter;
      this.from = this.formConfig.filter.start_date;
      this.to = this.formConfig.filter.end_date;
      this.initDate();
      this.fetchByFilter();
    }
  }

  fetchByCashflowExpenses(from, to) {
    this.statisticsController
      .getCashflowByExpenses(
        1,
        from,
        to,
        this.translateService.currentLang,
        this.activity_ids
      )
      .subscribe((value: any) => {
        this.displayableEntries = value.details;
        this.pieChartBrowser(this.displayableEntries);
      });
  }

  fetchByFilter() {
    if (this.formConfig.filter.sort_by == 'activity') {
      this.formConfig.show_common_expenses = false;
      this.fetchByCashflowExpenses(
        this.formConfig.filter.start_date,
        this.formConfig.filter.end_date
      );
    } else if (this.formConfig.filter.sort_by == 'persons') {
      this.byMorphable = 1;
      this.formConfig.show_common_expenses = true;
      this.fetchSummaryByFamilyMembers(
        this.formConfig.filter.start_date,
        this.formConfig.filter.end_date
      );
    } else if (
      this.formConfig.filter.sort_by == 'product' ||
      this.formConfig.filter.sort_by == 'animal'
    ) {
      this.byMorphable = 1;
      this.formConfig.show_common_expenses = true;
      this.fetchOtrosCashflowSummary(
        this.formConfig.filter.start_date,
        this.formConfig.filter.end_date
      );
    } else {
      this.formConfig.show_common_expenses = true;
      this.fetchSummaryByCashflowCategories(
        this.is_income ? 1 : 0,
        this.formConfig.filter.start_date,
        this.formConfig.filter.end_date
      );
    }
  }

  dateChanged(event) {
    this.formConfig.filter.end_date = event.end_date;
    this.formConfig.filter.start_date = event.start_date;
    this.from = this.formConfig.filter.start_date;
    this.to = this.formConfig.filter.end_date;
    this.initDate();
    this.fetchByFilter();
  }
}
