import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Browser } from '@capacitor/browser';

@Injectable({
  providedIn: 'root'
})
export class StoreRedirectService {
  constructor(private platform: Platform) {}

  /**
   * Redirects the user to the appropriate app store based on platform
   * @param iosAppId The iOS App Store ID
   * @param androidPackageName The Android package name
   * @param fallbackUrl Optional fallback URL if redirection fails
   * @returns Promise that resolves when redirection is complete
   */
  async redirectToStore(
    iosAppId: string = '6450292879',
    androidPackageName: string = 'com.burrusdevelopmentgroup.fynka.app',
    fallbackUrl?: string
  ): Promise<void> {
    try {
      if (this.platform.is('ios')) {
        // await Browser.open({ url: `https://apps.apple.com/app/id${iosAppId}` });
        // await Browser.open({ url: `itms-apps://itunes.apple.com/app/id${iosAppId}` });
        const appStoreUrl = 'https://apps.apple.com/app/id'+iosAppId;

        window.open(appStoreUrl, '_system'); 
      } else if (this.platform.is('android')) {
        await Browser.open({
        //   url: `https://play.google.com/store/apps/details?id=${androidPackageName}`
        url: `market://details?id=${androidPackageName}`
        });
      } else if (fallbackUrl) {
        // Fallback for web/desktop
        window.open(fallbackUrl, '_system');
      }
    } catch (error) {
      console.error('Failed to open store:', error);
      if (fallbackUrl) {
        window.open(fallbackUrl, '_system');
      }
    }
  }
}