import { Farm } from 'src/app/models/farm/farm.model';
import { dev } from 'src/app/config/offlines_keys.json';
import { FarmAPI } from './../../apis/farm.service';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { Component, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { PopoverComponent } from 'src/app/widgets/popover/popover.component';
import { TranslateService } from '@ngx-translate/core';
import { EmployeesAPI } from 'src/app/apis/employees.service';
import Employee from 'src/app/models/employee.model';
import { OwnerAPI } from 'src/app/apis/owners.service';
import { Family_member } from 'src/app/models/family_member.model';
import Owner from 'src/app/models/owner.model';
import { Store } from '@ngrx/store';
import { updateFarm } from 'src/app/utils/states-management/actions/farm.action';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { take } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-team-main',
  templateUrl: './team-main.component.html',
  styleUrls: ['./team-main.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: false, displayDefaultIndicatorType: false },
    },
  ],
})
export class TeamMainComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  step;
  permanentEmployee: Array<Employee>;
  temporalEmployee: Array<Employee>;
  familyMember: Array<Family_member>;
  owner: Owner;
  formConfig;
  has_permanent;
  has_temporal;
  hasPermanet = false;
  hasTemp = false;
  number_permanent_employees: number = 0;
  number_temporal_employees: number = 0;
  farm;
  constructor(
    private _formBuilder: FormBuilder,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private ownerAPI: OwnerAPI,
    private employeeAPI: EmployeesAPI,
    private routingConfig: RoutingConfig,
    private store: Store<{
      farm: Farm;
    }>
  ) {}

  async ngOnInit() {
    let f = await lastValueFrom(this.store.select(getFarm).pipe(take(1)));

    this.farm = new Farm(cloneDeep(f));

    if (!this.farm?.number_permanent_employee) {
      this.farm.number_permanent_employee = this.number_permanent_employees;
    }
    if (!this.farm?.number_temporal_employee) {
      this.farm.number_temporal_employee = this.number_temporal_employees;
    }

    this.refetch(null);

    this.formConfig = await this.routingConfig.getPageFormConfig();
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required],
    });
  }

  public onStepChange(event: any): void {
    this.step = event.selectedIndex;
    this.showPopOver(this.step);
  }

  async showPopOver(step) {
    if (step == 2) {
      this.popOver(
        await this.translateService
          .get('screens.equipe_permanent.info_bulle')
          .toPromise()
      );
    } else if (step == 4) {
      this.popOver(
        await this.translateService
          .get('screens.equipe_temporal.info_bulle')
          .toPromise()
      );
    }
  }
  async HandelNext(event) {
    if (this.number_permanent_employees != undefined) {
      this.farm.number_permanent_employee = this.number_permanent_employees;
    }
    if (this.number_temporal_employees != undefined) {
      this.farm.number_temporal_employee = this.number_temporal_employees;
    }
    this.store.dispatch(updateFarm({ data: this.farm, id: this.farm.id }));

    //  await  this.farmAPI.upDateFarm(this.farm).subscribe(async (value) => {
    //     await Storage.set({
    //       key: dev.FARM_KEY,
    //       value: JSON.stringify(this.farm),
    //     });

    //   });
  }
  HandelChangePermanentValue(event) {
    this.number_permanent_employees = event;
  }
  HandelChangeTemporalValue(event) {
    this.number_temporal_employees = event;
  }
  async popOver(msg) {
    let componentProps = { key1: msg };
    await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverComponent,
      'popover-custom'
    );
  }

  answerTemporalClick(event) {
    this.has_temporal = event;
    this.hasTemp = event;
  }
  answerPermanentClick(event) {
    this.has_permanent = event;
    this.hasPermanet = event;
  }

  hasPermanentChange(value) {
    this.hasPermanet = value;
  }
  hasTemptEvent(value) {
    this.hasTemp = value;
  }

  refetch(event) {
    this.ownerAPI.getOwnerById().subscribe((value: any) => {
      this.owner = value;
      if (value.family_members) this.familyMember = value.family_members;
      if (
        value.farm &&
        value.farm.employees &&
        value.farm.employees.length > 0
      ) {
        this.permanentEmployee = value.farm.employees.filter(
          (element) => !element.morphable_type && element.is_permanent
        );

        this.temporalEmployee = value.farm.employees.filter(
          (element) => !element.morphable_type && !element.is_permanent
        );
      }
    });
  }

  async deleteEmployee(event) {
    await this.employeeAPI.deleteEmployee(event).toPromise();
    this.refetch(null);
  }
}
