export const environment = {
  production: true,
  // appRoot: "https://backend.fynka.terrakodo.com"
  appRoot: "https://new-test.backend.fynka.terrakodo.com",
  langUrl: "https://management-tool-backend.terrakodo.com/api/get_language_content/",
  sentryDsn: "https://2e1a749c2f95189ba4bc21d00fa77e6c@sentry.terrakodo.com/3",
  hostUrl:"https://test.fynka.terrakodo.com",

  // langUrl:"./assets/i18n/"
  // appRoot: "https://g334jatgxo.preview.infomaniak.website"
  // appRoot: "https://backend.fynka.com"
  // appRoot: 'http://6h1c7atcsq.preview.infomaniak.website',
};
